/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/prefer-default-export */
import React, { lazy } from 'react';
import Loadable from 'components/Loadable';
import DashboardLayout from 'layouts/DashboardLayout';
import SecuredRoute from './secured-routes';
import { AppProvider } from 'app-context';
import TrackingPageLayout from 'layouts/TrackingPageLayout';
import Integration from './integration-routes';
import SettingsLayout from 'layouts/SettingsLayout';
import StoreLayout from 'layouts/StoreLayout';
import PlainDashboardLayout from 'layouts/PlainDashboardLayout';
import IntegrationCheck from './integration-check-routes';
import { Navigate } from 'react-router-dom';
import BusinessListing from 'pages/Settings/Business/Listing';
import ClientsLayout from 'layouts/ClientsLayout';
import TrackDelivery from 'pages/Orders/TrackDelivery';
import Page400 from 'components/BadRequestPage';
import Event from 'pages/Settings/Event';

// ===========================|| DASHBOARD ROUTING ||=========================== //
// Order
const CreateDelivery = Loadable(lazy(() => import('pages/Orders/SelectOrderType/SelectOrder')));
const CreateOrder = Loadable(lazy(() => import('pages/Orders/CreateOrder')));
const OrderBulkUpload = Loadable(lazy(() => import('pages/Orders/CreateOrder/BulkUpload')));
const ReviewBulkUploadData = Loadable(lazy(() => import('pages/Orders/CreateOrder/BulkUpload/ReviewBulkUploadData')));
const OrderListing = Loadable(lazy(() => import('pages/Orders/OrderListing')));
const ViewOrder = Loadable(lazy(() => import('pages/Orders/ViewOrder')));
const TrackOrder = Loadable(lazy(() => import('pages/Orders/TrackOrder')));
const RateOrder = Loadable(lazy(() => import('pages/Orders/RateOrderExperience')));
const ProcessImportedOrder = Loadable(lazy(() => import('pages/Orders/OrderListing/ImportedOrders/ProcessImportedOrder')));
// Payment
const PaymentListing = Loadable(lazy(() => import('pages/Payments/Listing')));
// Settings
const SettingsMenu = Loadable(lazy(() => import('pages/Settings/SettingsMenu')));
const UpdateStoreInfo = Loadable(lazy(() => import('pages/Settings/StoreInformation/UpdateStoreInformation')));
const OnboardStoreInfoV2 = Loadable(lazy(() => import('pages/Settings/StoreInformation/OnboardStoreInformation')));
const TeamListing = Loadable(lazy(() => import('pages/Settings/Team/Listing')));
const AddressesListing = Loadable(lazy(() => import('pages/Settings/Addresses/Listing')));
const Notification = Loadable(lazy(() => import('pages/Settings/Notifications')));
const Developer = Loadable(lazy(() => import('pages/Settings/Developer')));
const TrackingPages = Loadable(lazy(() => import('pages/Settings/TrackingPages')));
const CreatetrackingPage = Loadable(lazy(() => import('pages/Settings/TrackingPages/CreateTrackingPage')));
const EditTrackingPage = Loadable(lazy(() => import('pages/Settings/TrackingPages/EditTrackingPage')));
const ViewTrackingPage = Loadable(lazy(() => import('pages/Settings/TrackingPages/ViewTrackingPage')));
const AllContacts = Loadable(lazy(() => import('pages/Settings/Contacts/index')));
//   Products
const ProductListing = Loadable(lazy(() => import('pages/Settings/Products/Listing')));
// Apps
const AppListing = Loadable(lazy(() => import('pages/Settings/ConnectApps/AppListing')));
// Shop
const ShopIntegration = Loadable(lazy(() => import('pages/Settings/Shop/ShopSettings')));
const UpdateShopInfo = Loadable(lazy(() => import('pages/Settings/Shop/UpdateShopLocations')));
// Dashboard
const DashboardAnalytics = Loadable(lazy(() => import('pages/Dashboard/Analytics')));
const DashboardActivities = Loadable(lazy(() => import('pages/Dashboard/Activities')));
const DashboardRatings = Loadable(lazy(() => import('pages/Dashboard/Ratings')));
// SMS
const AllCampaigns = Loadable(lazy(() => import('pages/Sms/AllCampaigns')));
const NewCampaign = Loadable(lazy(() => import('pages/Sms/NewCampaign')));
const ViewCampaign = Loadable(lazy(() => import('pages/Sms/ViewCampaign')));
// Adverts
const AllAdverts = Loadable(lazy(() => import('pages/Marketing/Advertising/Listing')));
const CreateAdvert = Loadable(lazy(() => import('pages/Marketing/Advertising/CreateAdvert')));
// Clients
const ClientDashboard = Loadable(lazy(() => import('pages/ClientsPages/Dashboard')));

export const DashboardRoutes = {
    path: '/',
    element: (
        <SecuredRoute>
            <AppProvider>
                <DashboardLayout />
            </AppProvider>
        </SecuredRoute>
    ),
    children: [
        {
            path: '/',
            element: <Navigate to="/orders/listing" replace />
        },
        {
            path: '/dashboard/activities',
            element: <DashboardActivities />
        },
        {
            path: '/dashboard/analytics',
            element: <DashboardAnalytics />
        },
        {
            path: '/dashboard/ratings',
            element: <DashboardRatings />
        },
        {
            path: '/orders/create',
            element: <CreateDelivery />
        },
        {
            path: '/orders/create/:type',
            element: <CreateOrder />
        },
        {
            path: '/orders/create/bulkupload',
            element: <OrderBulkUpload />
        },
        {
            path: '/orders/importedorders/process',
            element: <ProcessImportedOrder />
        },
        {
            path: '/orders/:id',
            element: <ViewOrder />
        },
        {
            path: '/orders/listing',
            element: <OrderListing />
        },
        {
            path: '/products',
            element: <ProductListing />
        },
        //  Marketing
        {
            path: '/marketing/campaigns',
            element: <AllCampaigns />
        },
        {
            path: '/marketing/campaigns/:id',
            element: <ViewCampaign />
        },
        {
            path: '/marketing/campaigns/newcampaign',
            element: <NewCampaign />
        },
        {
            path: '/marketing/campaigns/updatecampaign/:id',
            element: <NewCampaign />
        },
        {
            path: '/marketing/campaigns/duplicatecampaign/:duplicateId',
            element: <NewCampaign />
        },
        {
            path: '/marketing/contacts',
            element: <AllContacts />
        },
        {
            path: '/marketing/adverts',
            element: <AllAdverts />
        },
        {
            path: '/settings/teams',
            element: (
                <SettingsLayout>
                    <TeamListing />
                </SettingsLayout>
            )
        },
        {
            path: '/settings/events',
            element: (
                <SettingsLayout>
                    <Event />
                </SettingsLayout>
            )
        },
        {
            path: '/settings/payments',
            element: (
                <SettingsLayout>
                    <PaymentListing />
                </SettingsLayout>
            )
        },
        {
            path: '/settings/addresses',
            element: (
                <SettingsLayout>
                    <AddressesListing />
                </SettingsLayout>
            )
        },
        {
            path: '/settings/tracking-pages',
            element: (
                <SettingsLayout>
                    <TrackingPages />
                </SettingsLayout>
            )
        },
        {
            path: '/settings/notifications',
            element: (
                <SettingsLayout>
                    <Notification />
                </SettingsLayout>
            )
        },
        {
            path: '/settings/developer',
            element: (
                <SettingsLayout>
                    <Developer />
                </SettingsLayout>
            )
        },
        {
            path: '/settings/businesses',
            element: (
                <SettingsLayout>
                    <BusinessListing />
                </SettingsLayout>
            )
        },
        {
            path: '/settings/my-shops',
            element: (
                <SettingsLayout>
                    <AppListing />
                </SettingsLayout>
            )
        },
        {
            path: '/settings/shop-ingeration',
            element: (
                <SettingsLayout>
                    <ShopIntegration />
                </SettingsLayout>
            )
        },
        {
            path: '/settings/shop/edit/:id',
            element: (
                <SettingsLayout>
                    <UpdateShopInfo />
                </SettingsLayout>
            )
        }
    ]
};

export const IntegrationRoutes = {
    path: '/',
    children: [
        {
            path: '/integration',
            element: <Integration />
        },
        {
            path: '/integration/check',
            element: <IntegrationCheck />
        },
        {
            path: '/integration/failed',
            element: <Page400 />
        }
    ]
};

export const PublicRoutes = {
    path: '/',
    element: <TrackingPageLayout />,
    children: [
        {
            path: '/orders/track/:id',
            element: <TrackDelivery />
        },
        {
            path: '/orders/rate/:orderId',
            element: <RateOrder />
        }
    ]
};

export const TrackingPageRoutes = {
    path: '/',
    element: (
        <SecuredRoute>
            <AppProvider>
                <TrackingPageLayout />
            </AppProvider>
        </SecuredRoute>
    ),
    children: [
        {
            path: '/settings/custom-tracking-page',
            element: <CreatetrackingPage />
        },
        {
            path: '/settings/custom-tracking-page/edit/:id',
            element: <EditTrackingPage />
        },
        {
            path: '/settings/custom-tracking-page/view/:id',
            element: <ViewTrackingPage />
        }
    ]
};

export const StoreRoutes = {
    path: '/',
    element: (
        <SecuredRoute>
            <AppProvider>
                <StoreLayout />
            </AppProvider>
        </SecuredRoute>
    ),
    children: [
        {
            path: '/settings/store-settings/createstore/:store_id',
            element: <OnboardStoreInfoV2 />
        },
        {
            path: '/settings/store-settings/editstore/:store_id',
            element: <UpdateStoreInfo />
        }
        // {
        //     path: '/settings/store-settings/createstore/v2/:store_id',
        //     element: <CreateStoreInfoV2 />
        // },
        // {
        //     path: '/settings/store-settings/editstore/v2/:store_id',
        //     element: <CreateStoreInfoV2 />
        // }
    ]
};

export const DashboardPlainLayOut = {
    path: '/',
    element: (
        <SecuredRoute>
            <AppProvider>
                <PlainDashboardLayout />
            </AppProvider>
        </SecuredRoute>
    ),
    children: [
        {
            path: '/review-uploadedorders',
            element: <ReviewBulkUploadData />
        },
        // Adverts
        {
            path: '/marketing/adverts/create',
            element: <CreateAdvert />
        },
        {
            path: '/marketing/adverts/update/:id',
            element: <CreateAdvert />
        }
        // {
        //     path: '/review-uploadedorders',
        //     element: <ReviewBulkUploadData />
        // },
        // {
        //     path: '/review-uploadedorders',
        //     element: <ReviewBulkUploadData />
        // }
    ]
};
export const PublicRoutePlainLayOut = {
    path: '/',
    element: <PlainDashboardLayout />,
    children: [
        {
            path: '/track-delivery/:id',
            element: <TrackDelivery />
        }
    ]
};

// Clients
export const ClientLayOut = {
    path: '/client',
    element: (
        <SecuredRoute>
            <AppProvider>
                <ClientsLayout />
            </AppProvider>
        </SecuredRoute>
    ),
    children: [
        {
            path: '/client/dashboard',
            element: <ClientDashboard />
        }
    ]
};
