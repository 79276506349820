/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cancelIcon from 'assets/icons/x_circle.svg';
import AnimateElement from 'ui-components/AnimateElement';
import Button from 'components/Button';

const SlideOver = (props: any) => {
    const { open, toggle, title, children } = props;

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={toggle}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 w-full md:w-auto md:pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto relative w-full md:w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-4 shadow-xl">
                                        <div className={`flex justify-${title ? 'between' : 'end'} items-center mt-[6px] px-4 sm:px-6`}>
                                            {title && <div className="text-lg font-bold">{title}</div>}
                                            <AnimateElement className="pointer">
                                                <div onClick={() => toggle()} className="flex justify-end">
                                                    <img src={cancelIcon} alt="Cancel button" width={80} className="pointer" />
                                                </div>
                                            </AnimateElement>
                                        </div>
                                        <div className="px-4 sm:px-6" />
                                        <div className="relative  flex-1 px-4 sm:px-6 mb-12">{children}</div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
export default SlideOver;
