// eslint-disable-next-line prettier/prettier, import/prefer-default-export
export const ApiEndpoints = {
    RATING: '/order/rating/rates',
    INTEGRATION: '/integrations',
    ORDER: '/order',
    RECURRING: '/reoccuring',
    CARD: '/payment/cards',
    STORE: '/stores',
    ESTIMATION: '/drivers/estimate',
    BATCH_ESTIMATION: '/drivers/batch/estimate',
    DELIVERY_PROVIDERS: '/drivers/deliveries/providers',
    CREATE_BATCH_ORDERS: '/drivers/deliveries/batch',
    ADDRESS: '/stores/addresses',
    USERS: '/businesses/users',
    PRODUCT: '/products',
    BUSINESS: '/businesses/',
    DISCOUNT: '/businesses/discount',
    NOTIFICATION: '/notifications',
    INVITATION: '/businesses/invitations',
    DELIVERY: '/drivers/deliveries',
    UPLOAD_FILE: '/businesses/storage',
    TRACKING: '/businesses/tracking',
    DRIVER: '/drivers/deliveries',
    DASHERS: '/drivers',
    TOKENS: '/businesses/tokens',
    WEBHOOKS: '/notifications/webhooks/config',
    MARKETING: '/marketing',
    CONTACTS: '/marketing/contacts',
    MARKETING_CAMPAIGNS: '/marketing/campaigns',
    ADVERTS: '/businesses/advertising',
    LOGS: '/logs'
};

export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL;

export const HUB_URL = process.env.REACT_APP_HUB_URL;

export const WEB_URL = process.env.REACT_APP_WEB_URL;

export const API_URL = process.env.REACT_APP_API_URL;

export const API_DOC_URL = `https://docs.xpressrun.com`;

export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;
