import Button from 'components/Button';
import FormInput from 'components/FormInput';
import SlideOver from 'components/SlideOver';
import React, { useEffect, useReducer } from 'react';
import { extractErrorMessage, isObjectEmpty, mutateArray, notEmptyString } from 'libs';
import { validateData } from 'helpers';
import { useApp } from 'app-context';
import { BusinessHttpService } from 'http-service/Business-http-service';
import { MutateOperation } from 'constants/mutateOperation';
import HttpService from 'http-service/Http-service';
import { ApiEndpoints } from 'constants/apiRoutes';
import moment from 'moment';
import { isSuperAdmin } from 'keycloak';

const BusinessForm = (props: any) => {
    const { show, toggle, business } = props;
    const { freeOrderCount, discount } = business;

    const { update, businesses } = useApp() as AppContextType;

    const initialState = {
        errors: {} as any,
        formData: { freeOrderCount, startDate: discount?.startDate, endDate: discount?.endDate, discount: discount?.discount },
        loading: { discount: false, freeOrder: false }
    };

    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { formData, errors, loading } = state;

    useEffect(() => {
        setState({
            ...state,
            formData: initialState.formData
        });
    }, [business]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        setState({
            ...state,
            formData: {
                ...formData,
                [name]: value
            },
            errors: {
                ...errors,
                [name]: ''
            }
        });
    };

    const handleApplyFreeOrder = async () => {
        setState({ ...state, loading: { ...loading, freeOrder: true } });
        const rules = {
            freeOrderCount: 'required'
        };

        const messages = {
            required: (field: string) => `${field} is required`
        };

        let validate = await validateData(formData, rules, messages);

        if (isObjectEmpty(validate)) {
            try {
                const _business = { ...business, freeOrderCount: formData?.freeOrderCount };
                const { users, discount, ...rest } = _business;
                const {
                    data: { data }
                } = await new BusinessHttpService().persist(rest);
                update({ businesses: mutateArray(MutateOperation.UPDATE, businesses, _business) });
                toggle();
            } catch (e) {
                const { errors } = extractErrorMessage(e);
                validate = { ...errors };
            }
        }

        setState({ ...state, loading: { ...loading, freeOrder: false }, errors: validate });
    };

    const handleApplyDiscount = async () => {
        setState({ ...state, loading: { ...loading, discount: true } });
        const rules = {
            startDate: 'required',
            endDate: 'required',
            discount: 'required|under:100|above:0'
        };

        const messages = {
            required: (field: string) => `${field} is required`,
            under: (field: string) => `${field} must be less than 100`,
            above: (field: string) => `${field} must be greater than 0`
        };

        let validate = await validateData(formData, rules, messages);

        if (isObjectEmpty(validate)) {
            try {
                const { startDate, endDate, discount } = formData;
                const _business = {
                    ...business,
                    discount: {
                        business: { id: business.id },
                        startDate: moment(startDate).format('YYYY-MM-DD HH:mm'),
                        endDate: moment(endDate).format('YYYY-MM-DD HH:mm'),
                        discount
                    }
                };
                const {
                    data: { data }
                } = await new HttpService(ApiEndpoints.DISCOUNT).persist(_business?.discount);
                update({ businesses: mutateArray(MutateOperation.UPDATE, businesses, _business) });
                toggle();
            } catch (e) {
                const { errors } = extractErrorMessage(e);
                validate = { ...errors };
            }
        }

        setState({ ...state, loading: { ...loading, discount: false }, errors: validate });
    };

    return (
        <div>
            <SlideOver open={show} toggle={toggle}>
                <>
                    <div className="mb-5">
                        <div className="slide-header text-base font-semibold">{business?.name}</div>
                    </div>
                    <div className="grid sm:grid-cols-1 md:grid-cols-1">
                        <div>
                            <FormInput
                                autoComplete="off"
                                errorMessage={errors?.freeOrderCount}
                                disabled={!isSuperAdmin()}
                                value={formData?.freeOrderCount}
                                extraClass=""
                                inputClass="w-full"
                                inputName="freeOrderCount"
                                inputType="number"
                                onChange={handleInputChange}
                                placeholder=""
                                label="Free order"
                                showErrorMessage={errors?.freeOrderCount}
                                labelClass="font-normal text-base text-slate-600"
                            />
                        </div>
                        {isSuperAdmin() && (
                            <div className="mt-5">
                                <Button
                                    disabled={loading?.freeOrder}
                                    isLoading={loading?.freeOrder}
                                    text="Apply"
                                    action={handleApplyFreeOrder}
                                    className="create-btn w-full"
                                    animateClass="w-full"
                                />
                            </div>
                        )}
                    </div>
                    <div className="mt-5">
                        <div className="slide-header text-base font-semibold">Discount</div>
                    </div>
                    <div className="grid sm:grid-cols-1 md:grid-cols-1">
                        <div>
                            <FormInput
                                autoComplete="off"
                                errorMessage={errors?.startDate}
                                disabled={!isSuperAdmin()}
                                value={formData?.startDate}
                                extraClass=""
                                inputClass="w-full"
                                inputName="startDate"
                                inputType="datetime-local"
                                onChange={handleInputChange}
                                placeholder=""
                                label="from"
                                showErrorMessage={errors?.startDate}
                                labelClass="font-normal text-base text-slate-600"
                            />
                        </div>
                        <div>
                            <FormInput
                                autoComplete="off"
                                errorMessage={errors?.endDate}
                                disabled={!isSuperAdmin()}
                                value={formData?.endDate}
                                extraClass=""
                                inputClass="w-full"
                                inputName="endDate"
                                inputType="datetime-local"
                                onChange={handleInputChange}
                                placeholder=""
                                label="to"
                                showErrorMessage={errors?.endDate}
                                labelClass="font-normal text-base text-slate-600"
                            />
                        </div>
                        <div>
                            <FormInput
                                autoComplete="off"
                                errorMessage={errors?.discount}
                                value={formData?.discount}
                                disabled={!isSuperAdmin()}
                                extraClass=""
                                inputClass="w-full"
                                inputName="discount"
                                inputType="number"
                                onChange={handleInputChange}
                                placeholder="Discount in %"
                                showErrorMessage={errors?.discount}
                                labelClass="font-normal text-base text-slate-600"
                            />
                        </div>
                        {isSuperAdmin() && (
                            <div className="mt-5">
                                <Button
                                    disabled={loading?.discount}
                                    isLoading={loading?.discount}
                                    text="Apply"
                                    action={handleApplyDiscount}
                                    className="create-btn w-full"
                                    animateClass="w-full"
                                />
                            </div>
                        )}
                    </div>
                </>
            </SlideOver>
        </div>
    );
};

export default BusinessForm;
