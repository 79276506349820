import { isObjectEmpty } from 'libs';
import http from './Http-common';
import { AxiosRequestConfig } from 'axios';

// eslint-disable-next-line import/prefer-default-export
export abstract class AbstractHttpService<T extends { id: string | null }> {
    protected abstract url: string;

    async getAll(params?: any, config?: AxiosRequestConfig): Promise<T> {
        if (!isObjectEmpty(params)) return http.get(`${this.url}?${new URLSearchParams(params).toString()}`, config);
        return http.get(`${this.url}`, config);
    }

    async postData(url: string, params?: any, config?: AxiosRequestConfig): Promise<T> {
        if (!isObjectEmpty(params)) return http.post(`${this.url}${url}?${new URLSearchParams(params).toString()}`, config);
        return http.post(`${this.url}${url}`, config);
    }

    async getWithUrl(url: string, params?: any, config?: AxiosRequestConfig): Promise<T> {
        if (!isObjectEmpty(params)) return http.get(`${this.url}${url}?${new URLSearchParams(params).toString()}`, config);
        return http.get(`${this.url}${url}`, config);
    }

    async get(id: string | number, config?: AxiosRequestConfig): Promise<T> {
        return http.get(`${this.url}/${id}`, config);
    }

    async persist(data: T, config?: AxiosRequestConfig): Promise<T> {
        if (data.id) {
            return http.put(`${this.url}`, data, config);
        }
        return http.post(`${this.url}`, data, config);
    }

    async persistWithUrl(url: string, data: T, config?: AxiosRequestConfig): Promise<T> {
        if (data.id) {
            return http.put(`${this.url}${url}`, data, config);
        }
        return http.post(`${this.url}${url}`, data, config);
    }

    async persistWidthId(data: T, config?: AxiosRequestConfig): Promise<T> {
        if (data.id) {
            return http.put(`${this.url}/${data.id}`, data, config);
        }
        return http.post(`${this.url}/${data.id}`, data, config);
    }

    async delete(id: string, config?: AxiosRequestConfig): Promise<void> {
        return http.delete(`${this.url}/${id}`, config);
    }

    async deleteWithUrl(url: string, id: string, config?: AxiosRequestConfig): Promise<void> {
        return http.delete(`${this.url}${url}/${id}`, config);
    }

    async deleteAll(): Promise<void> {
        return http.delete(`${this.url}`);
    }

    async uploadFile(data: T): Promise<T> {
        return http.post(`${this.url}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}
