/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useReducer, useState } from 'react';
import Button from 'components/Button';
import { Modal } from 'flowbite-react';
import 'react-datepicker/dist/react-datepicker.css';
import { useApp } from 'app-context';
import { validateData } from 'helpers';
import { changeNumberToArrayList, formatCurrency, isObjectEmpty } from 'libs';
import '../track_delivery.scss';
import darkLocationIcon from 'assets/icons/new_tracking_page_icons/dark_location.svg';
import radioIcon from 'assets/icons/new_tracking_page_icons/radio_indicator.svg';
import blankPurpleIcon from 'assets/icons/new_tracking_page_icons/blank_purple_star.svg';
import purpleStarIcon from 'assets/icons/new_tracking_page_icons/purple_star.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { RatingHttpService } from 'http-service/Rating-http-service';
import { OrderHttpService } from 'http-service/Order-http-service';
import { BusinessHttpService } from 'http-service/Business-http-service';
import AnimateElement from 'ui-components/AnimateElement';
import FormTextarea from 'components/FormTextarea';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { errorToast, successToast } from 'components/Toast';
import { XpressrunPageLoader } from 'ui-components/XpressrunLoader';

const tabs = [
    {
        title: 'Product feedback',
        key: 'product_feedback'
    },
    {
        title: 'Delivery feedback',
        key: 'delivery_feedback'
    }
];
const RatingsModal = (props: any) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [page, setPage] = useState(0);
    const { show, toggleModal, title = '' } = props;

    const initialState = {
        pageLoading: true,
        providerData: {
            stars: 0,
            text: ''
        },
        productData: {
            stars: 0,
            text: ''
        },
        orderDetails: {},
        brandImgUrl: '',
        brandName: '',
        providerName: '',
        errors: {},
        starRatings: 0,
        themeColor: '',
        textColor: '',
        isLoading: false
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { formData, isLoading, providerName, orderDetails, productData, providerData, pageLoading } = state;
    const starClicked = (starIndex: number, stateKey: string) => {
        setState({
            ...state,
            [stateKey]: {
                ...[stateKey],
                stars: starIndex
            }
        });
    };
    const handleProductInputChange = (e: any, stateKey: string): void => {
        const { value } = e.target;
        setState({
            ...state,
            productData: {
                ...productData,
                text: value
            }
        });
    };
    const handleProviderInputChange = (e: any, stateKey: string): void => {
        const { value } = e.target;
        setState({
            ...state,
            providerData: {
                ...providerData,
                text: value
            }
        });
    };

    const renderProductFeedBack = () => (
        <div className="mt-[18px]  pt-2 px-3 md:px-8">
            <div className="font-semi-bold md:font-bold text-[#667489] text-[17.28px]">Rate the product </div>
            <div className="gap-x-[5.21px] flex items-center mt-[15px]">
                {changeNumberToArrayList(5).map((item: any, index: any) => {
                    const newIndex = index + 1;
                    return (
                        <div key={index} className="pointer" onClick={() => starClicked(index + 1, 'productData')}>
                            <AnimateElement>
                                <img src={newIndex <= productData?.stars ? purpleStarIcon : blankPurpleIcon} alt="Star" />
                            </AnimateElement>
                        </div>
                    );
                })}
            </div>
            <div className="mt-[12px]">Your comments</div>
            <div className="text-sm">
                <FormTextarea
                    placeholder="Tell us about your experience with this delivery. Try to be specific,
                authentic and respectful so that other users can benefit from your review!
                (optional)"
                    rows={5}
                    inputName="text"
                    inputClass="form-textarea-font-12"
                    onChange={(e: any) => handleProductInputChange(e, 'productData')}
                    value={productData?.text}
                />
            </div>
        </div>
    );

    const renderDeliveryFeedBack = () => (
        <div className="mt-[18px]  pt-2 px-3 md:px-8">
            <div className="font-semi-bold md:font-bold text-[#667489] text-[17.28px]">Rate the delivery </div>
            <div className="gap-x-[5.21px] flex items-center mt-[15px]">
                {changeNumberToArrayList(5).map((item: any, index: any) => {
                    const newIndex = index + 1;
                    return (
                        <div key={index} className="pointer" onClick={() => starClicked(index + 1, 'providerData')}>
                            <AnimateElement>
                                <img src={newIndex <= providerData?.stars ? purpleStarIcon : blankPurpleIcon} alt="Star" />
                            </AnimateElement>
                        </div>
                    );
                })}
            </div>
            <div className="mt-[12px]">Your comments</div>
            <div className="text-sm">
                <FormTextarea
                    placeholder="Tell us about your experience with this delivery. Try to be specific,
                authentic and respectful so that other users can benefit from your review!
                (optional)"
                    rows={5}
                    inputName="text"
                    inputClass="form-textarea-font-12"
                    onChange={(e: any) => handleProviderInputChange(e, 'providerData')}
                    value={providerData?.text}
                />
            </div>
        </div>
    );

    const renderUI = () => {
        switch (page) {
            case 0:
                return renderProductFeedBack();

            default:
                return renderDeliveryFeedBack();
        }
    };

    const fetchData = async () => {
        try {
            // @ts-ignore
            const apiRequest = await new OrderHttpService().getWithUrl(`/public/${id}`);
            const businessId = apiRequest?.data?.data?.business_id;
            const businessDetails = await new BusinessHttpService().getWithUrl(`public/${businessId}/tracking`);
            setState({
                orderDetails: apiRequest?.data?.data,
                brandName: businessDetails?.data?.data?.brandName,
                brandImgUrl: businessDetails?.data?.data?.imgUrl,
                pageLoading: false,
                providerName: apiRequest?.data?.data?.provider,
                themeColor: businessDetails?.data?.data?.colorPrim || '#C39735',
                textColor: businessDetails?.data?.data?.colorTitle || 'black'
            });
        } catch (error) {
            setState({
                pageLoading: false
            });
        }
    };
    const submitRatingsData = async () => {
        try {
            setState({
                isLoading: true
            });
            const payload = {
                starNumber: productData?.stars,
                content: productData?.text,
                deliveryStarNumber: providerData?.stars,
                deliveryContent: providerData?.text,
                order_id: id
            };

            await new RatingHttpService().persist(payload);

            setState({
                isLoading: false,
                step: 2
            });
            successToast('Ratings sent successfully');
        } catch (error) {
            // Empty
            setState({
                isLoading: false
            });
        }
    };
    const handleCheckRatingsPayload = () => {
        // if (page === 1) {
        if (!productData?.stars && page === 0) {
            errorToast('Ratings required for product');
        } else {
            setPage(1);
        }

        if (!providerData?.stars && page === 1) {
            errorToast('Provider rating required');
        } else if (providerData?.stars && productData?.stars) {
            submitRatingsData();
        }
        // }
    };
    const getCourierImage = () => `https://xpressrun-upload.s3.eu-west-3.amazonaws.com/icons/${providerName.toLowerCase()}.svg`;
    useEffect(() => {
        fetchData();
        // Cleanup method
        return () => {
            setState({
                ...initialState
            });
        };
    }, []);

    return (
        <Modal show={show} size="2xl">
            <Modal.Body className="px-2.5 md:px-6 ratings-modal-container">
                {pageLoading ? (
                    <XpressrunPageLoader />
                ) : (
                    <>
                        <div className="pt-4 px-3 md:px-8 flex justify-between items-center ">
                            <div className="flex gap-x-2 items-center text-[15.12px] text-[#667489] font-semi-bold">
                                Provider : <img src={getCourierImage()} width={25} height={25} alt="Logo" />
                            </div>
                            <div className="font-semi-bold text-[#19094C]">
                                Total : {formatCurrency(orderDetails?.total_delivery_amount || 0, true)}
                            </div>
                        </div>
                        <div className="border-b mt-[15px]" />
                        <div className="pt-[11px] px-3 md:px-8 flex gap-x-[29px] items-center ">
                            {/* <div>Product image</div> */}
                            <div>
                                <ol className="relative border-l border-gray-200 dark:border-gray-700">
                                    <li className="mb-10 ml-6">
                                        <span className="absolute flex items-center justify-center   -left-[9px] ring-8 ring-white ">
                                            <img src={radioIcon} alt="" />
                                        </span>
                                        <h3 className="mb-1 text-[15.12px] font-normal md:font-semi-bold text-[#667489]">
                                            {orderDetails?.pickup_information?.address?.address_name}
                                        </h3>
                                    </li>
                                    <li className="mb-[12px] ml-6">
                                        <span className="absolute flex items-center justify-center   -left-3  ring-2 ring-white">
                                            <img src={darkLocationIcon} alt="" />
                                        </span>
                                        <h3 className="mb-1 text-[15.12px] font-normal md:font-semi-bold text-[#667489]">
                                            {orderDetails?.dropoff_information?.address?.address_name}
                                        </h3>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="border-b mb-2" />
                        <div className="font-normal md:font-semi-bold text-[#667489] text-[15.12px] pt-2 px-3 md:px-8">
                            We’d love to hear more about your experience! It only takes one minute!
                        </div>
                        <div className="px-3 md:px-8 mt-[20px]">
                            <AnimateSharedLayout>
                                <div className="tabs-header ">
                                    {tabs.map(({ title, number }: any, i: number) => {
                                        const isActive = i === page;
                                        return (
                                            <div
                                                key={i}
                                                className={isActive ? 'active-header' : 'non-active-header'}
                                                onClick={() => {
                                                    setPage(i);
                                                }}
                                            >
                                                <div className="flex gap-x-2 font-semi-bold">
                                                    <div>{title}</div>
                                                </div>
                                                {isActive && <motion.div className="underline" layoutId="underline" />}
                                            </div>
                                        );
                                    })}
                                    <div className="underline-bg " />
                                </div>
                            </AnimateSharedLayout>
                        </div>
                        <div>{renderUI()}</div>

                        <div className="border-b mb-2 mt-[15px]" />
                        <div className="flex justify-end gap-x-[9px] m-t[10px] px-3 md:px-8">
                            <Button
                                text="No Thanks"
                                action={() => {
                                    toggleModal();
                                }}
                                disabled={isLoading}
                                className="px-[24px] py-2.5 font-semi-bold text-[#667489]"
                            />
                            <Button
                                text="Continue"
                                action={() => {
                                    handleCheckRatingsPayload();
                                }}
                                isLoading={isLoading}
                                disabled={isLoading}
                                className="px-[24px] py-2.5 app-btn-border-0 rounded font-semi-bold"
                            />
                        </div>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default RatingsModal;
