/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useReducer, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import './maps.scss';
import GM_API_KEY from 'constants/googleMapApi';
import { isObjectEmpty } from 'libs';

type Point = {
    lat: number;
    lng: number;
};

let currentPolyline: { setMap: (arg0: null) => void };

// Return map bounds based on list of places
const getMapBounds = (map: any, maps: any, places: any) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place: any) => {
        bounds.extend(new maps.LatLng(place.lat(), place.lng()));
    });
    return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map: any, maps: any, bounds: any) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
        maps.event.addDomListener(window, 'resize', () => {
            map.fitBounds(bounds);
        });
    });
};

const renderPolylines = (map: any, maps: any, places: any, isTracking = false) => {
    // If a polyline exists, remove it from the map before creating a new one
    if (currentPolyline) {
        currentPolyline.setMap(null);
    }

    const nonGeodesicPolyline = new maps.Polyline({
        path: places,
        geodesic: true,
        strokeColor: isTracking ? '#219653' : '#00a1e1',
        strokeOpacity: 1.0,
        strokeWeight: 4
    });

    nonGeodesicPolyline.setMap(map);

    // Update the reference to the new polyline
    currentPolyline = nonGeodesicPolyline;
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map: any, maps: any, places: any, isTracking = false) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);

    renderPolylines(map, maps, places, isTracking);
};

const Marker = (props: any) => {
    const { text, isTracking } = props;
    return <div className={`${isTracking ? 'tracking-origin-marker' : 'origin-marker'} `} />;
};

const DestinationMarker = (props: any) => {
    const { text, isTracking } = props;
    return <div className={`${isTracking ? 'tracking-destination-marker' : 'destination-marker'} `} />;
};

const DriverMarker = (props: any) => {
    const { text } = props;
    return <div className="tracking-driver-marker" />;
};

const CustomMap = ({ originPoint, destPoint, height, driverPoint, distance, status, showDistance = false, isTracking = false }: any) => {
    const initialState = {
        defaultProps: {
            center: {
                lat: 34.0522, // Latitude for Los Angeles, California
                lng: -118.2437 // Longitude for Los Angeles, California
            },
            zoom: 11
        },
        map: '',
        maps: '',
        distance: 0
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { defaultProps, map, maps } = state;

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    // Update the default center with the user's location
                    setState({
                        defaultProps: {
                            center: {
                                lat: latitude,
                                lng: longitude
                            },
                            zoom: 11
                        }
                    });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleInitMapWithPoints = (map: any, maps: any, origin: Point, destination: Point, driver: Point, status: string) => {
        let params = {
            origin,
            destination,
            travelMode: 'DRIVING'
        };

        if (!isObjectEmpty(driver) && origin && ['PENDING', 'CREATED', 'CONFIRMED'].includes(status)) {
            params = {
                origin: driver,
                destination: origin,
                travelMode: 'DRIVING'
            };
        }

        if (!isObjectEmpty(driver) && destination && ['PICKED_UP'].includes(status)) {
            params = {
                origin: driver,
                destination,
                travelMode: 'DRIVING'
            };
        }

        const directionsService = new maps.DirectionsService();

        directionsService.route(params, (result: any, status: any) => {
            if (status === 'OK') {
                const places = result.routes[0].overview_path;
                apiIsLoaded(map, maps, places, isTracking);
            }
        });
    };

    const handleApiLoaded = (map: any, maps: any) => {
        setState({ map, maps });
    };

    useEffect(() => {
        if (map && maps) {
            const trafficLayer = new maps.TrafficLayer();
            trafficLayer.setMap(map);
            if (!isObjectEmpty(originPoint) && !isObjectEmpty(destPoint)) {
                handleInitMapWithPoints(map, maps, originPoint, destPoint, driverPoint, status);
            }
        }
    }, [originPoint, destPoint, map, maps, status, driverPoint]);

    useEffect(() => {
        if (isObjectEmpty(originPoint) && isObjectEmpty(destPoint)) getUserLocation();
    }, []);

    return (
        // Important! Always set the container height explicitly
        <div style={{ height: height ?? '100vh', width: '100%' }} className="rounded">
            {showDistance && (
                <div style={{ position: 'absolute', zIndex: 100000, top: '20px', left: '50px' }}>
                    <span className="miles-bg text-large font-large mr-2 px-4 py-2 rounded">{distance?.toFixed(2)} mi</span>
                </div>
            )}
            <GoogleMapReact
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{ key: GM_API_KEY }}
                center={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                {!isObjectEmpty(originPoint) && (
                    <Marker lat={originPoint.lat} lng={originPoint.lng} text="My Marker" isTracking={isTracking} />
                )}
                {!isObjectEmpty(driverPoint) && <DriverMarker lat={driverPoint.lat} lng={driverPoint.lng} text="Second marker" />}
                {!isObjectEmpty(destPoint) && (
                    <DestinationMarker lat={destPoint.lat} lng={destPoint.lng} text="Second marker" isTracking={isTracking} />
                )}
            </GoogleMapReact>
        </div>
    );
};

export default CustomMap;
