/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useEffect, useReducer } from 'react';
import Sidebar from './Sidebar';
import './dashboardlayout.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import AnimateElement from 'ui-components/AnimateElement';
import { Menu, Transition } from '@headlessui/react';
import { isSuperAdmin } from 'keycloak';
import { useApp } from 'app-context';
import { ChevronDown } from 'tabler-icons-react';
import ScheduleDelivery from 'pages/Orders/SelectOrderType/SelectOrder/ScheduleDelivery';
import { appSideBarType } from 'data';
import { useKeycloak } from '@react-keycloak/web';
import DashboardSearchBar from './DashboardSearchBar';
import { FormSelectSearch } from 'components/FormSelect';
import ImportOrderModal from 'pages/Orders/ImportOrderModal';
import NewSidebar from './NewSidebar';

const DashboardLayout = (props: any) => {
    const { update, selectedBusiness, businesses, sideBarType, getBusinessInfo } = useApp() as AppContextType;
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();
    const initialState = {
        openMobileSideBar: false,
        showScheduleModal: false,
        showOnboardingProcess: false,
        showImportOrderModal: false,
        onboardingData: {
            setPayment: true,
            setOrder: true,
            setAddress: false,
            setTracking: true,
            setStore: false
        },
        onboardingDataLoading: true
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { openMobileSideBar, showScheduleModal, showOnboardingProcess, showImportOrderModal, onboardingData, onboardingDataLoading } =
        state;

    const toogleMobileMenu = () => {
        setState({
            openMobileSideBar: !openMobileSideBar
        });
    };
    const toogleOnboardingProcess = () => {
        setState({
            showOnboardingProcess: !showOnboardingProcess
        });
    };
    const getSidebarMatch = () => {
        const match = /^\/settings/;
        if (window.location.pathname.match(match)) {
            return (
                <div className="">
                    <Outlet />
                </div>
            );
        }
        return (
            <div className="main-dash-body">
                <div className=" ">
                    {/* <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <span className="block sm:inline">
                        We apologize for the inconvenience. Our platform is currently experiencing technical difficulties which may impact
                        your ability to create orders. Rest assured, we&apos;re working diligently to resolve the issue as soon as possible.
                        Thank you for your understanding.
                    </span>
                </div> */}
                    <Outlet />
                </div>
            </div>
        );
    };

    const setDashboardState = () => {
        setState({
            ...state
        });
    };
    const closeMobileMenu = () => {
        setState({
            openMobileSideBar: false
        });
    };

    const toggleScheduleModal = () => {
        setState({
            showScheduleModal: !showScheduleModal
        });
    };

    const toggleImportOrderModal = () => {
        setState({
            showImportOrderModal: !showImportOrderModal
        });
    };

    useEffect(() => {
        setState({
            openMobileSideBar: false
        });
        // Cleanup method
        return () => {
            setState({
                ...initialState
            });
        };
    }, []);

    return (
        <div className="dashboard-layout">
            <div
                className={`sidebar-container fixed duration-100  bg-white  z-[30] ${
                    sideBarType === appSideBarType?.FULL ? 'w-[255px]' : 'w-[87px] hover:w-[255px]'
                }`}
            >
                <Sidebar setDashboardState={setDashboardState} />
            </div>

            {openMobileSideBar && (
                <>
                    {' '}
                    <div className={`flex  fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-30 `} onClick={() => closeMobileMenu()} />
                    <aside className="z-50 fixed max-h-[100vh] overflow-y-scroll bg-white ">
                        <Sidebar setDashboardState={setDashboardState} />
                    </aside>
                </>
            )}
            {/* <div className={`mobile-sidebar-container ${openMobileSideBar ? 'open-mobile-sidebar' : ''}`}>
                <div>
                    <Sidebar setDashboardState={setDashboardState} />
                </div>
                <div onClick={() => closeMobileMenu()} className="w-screen h-screen relative" />
            </div> */}
            <main
                className={`w-full main relative duration-100 ${openMobileSideBar ? 'max-height-100' : ''} ${
                    sideBarType === appSideBarType?.FULL ? '' : 'slim-main'
                }`}
            >
                <div
                    className={`nav-container fixed duration-100 pt-2 md:pt-0 ${
                        sideBarType === appSideBarType?.FULL ? '' : 'slim-nav-container'
                    }`}
                >
                    <nav className="flex justify-between w-full main-nav items-center lg:py-2 ">
                        <div className="nav-search-bar w-[500px]">
                            <DashboardSearchBar inputClassName="" placeholder="Search for orders............" />
                        </div>
                        <AnimateElement>
                            <div className="mobile-hamburger-menu pointer" onClick={toogleMobileMenu} />
                        </AnimateElement>
                        <div className="flex items-center gap-x-[44px]">
                            {/* <div>
                                <Button
                                    text="Create Order"
                                    action={() => {
                                        setDashboardState();
                                        // navigate('/orders/create');
                                    }}
                                    icon={<ChevronDown size={18} strokeWidth={2} color="white" className="mt-1" />}
                                    className="py-[10.8px] app-btn-border-0 px-[37.95px] flex-row-reverse flex gap-x-2 text-[15px] items-center font-semi-bold rounded-[6px]"
                                    animateClass="w-full"
                                />
                            </div> */}
                            {isSuperAdmin() && (
                                <div className="hidden  md:block  md:w-[250px]">
                                    <FormSelectSearch
                                        search
                                        value={selectedBusiness}
                                        onChange={({ value }: any) => update({ selectedBusiness: value })}
                                        options={businesses.map(({ name, id }: any) => ({ label: name, value: id }))}
                                        placeholder="Choose a business"
                                        selectClass="w-full"
                                    />
                                </div>
                            )}
                            <div>
                                <Menu as="div" className="flex justify-end  md:mt-0 relative">
                                    <div className="">
                                        <AnimateElement>
                                            <Menu.Button className="flex gap-x-2 items-center w-full app-btn-border-0 font-semi-bold  text-normal flex gap-2 items-center justify-center py-2.5 px-[27.6px] sm:w-full rounded-[4px]">
                                                Create Order <ChevronDown size={18} strokeWidth={2} color="white" className="mt-1" />
                                            </Menu.Button>
                                        </AnimateElement>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            static
                                            className="absolute left-[-0px] md:right-0 bottom-[-160px] z-50 mt-2  origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        >
                                            <AnimateElement>
                                                <div
                                                    className="font-normal py-2 px-3.5 pointer analytics-opt-menu flex gap-x-2 items-center"
                                                    onClick={() => {
                                                        update({ recurringOrderData: {} });
                                                        navigate(`/orders/create/immediate`);
                                                    }}
                                                >
                                                    Send&nbsp;Order&nbsp;Now&nbsp;
                                                </div>
                                            </AnimateElement>
                                            <AnimateElement>
                                                <div
                                                    className="font-normal py-2 px-3.5 pointer analytics-opt-menu flex gap-x-2 items-center"
                                                    onClick={() => toggleScheduleModal()}
                                                >
                                                    Schedule&nbsp;Order
                                                </div>
                                            </AnimateElement>
                                            <AnimateElement>
                                                <div
                                                    className="font-normal py-2 px-3.5 pointer analytics-opt-menu flex gap-x-2 items-center"
                                                    onClick={() => {
                                                        update({ recurringOrderData: {} });
                                                        navigate('/orders/create/bulkUpload');
                                                    }}
                                                >
                                                    Send&nbsp;Bulk&nbsp;Order
                                                </div>
                                            </AnimateElement>
                                            <AnimateElement>
                                                <div
                                                    className="font-normal py-2 px-3.5 pointer analytics-opt-menu flex gap-x-2 items-center"
                                                    onClick={() => toggleImportOrderModal()}
                                                >
                                                    Import&nbsp;Order
                                                </div>
                                            </AnimateElement>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </nav>
                </div>
                {getSidebarMatch()}
                <div
                    className={`fixed bg-[#FEFEFE] border right-[20px] bottom-[100px]  rounded-[8px] w-[452px] z-[100]  pointer transition ease-in  duration-100 ${
                        showOnboardingProcess ? '' : 'hidden'
                    }`}
                />
            </main>
            {showScheduleModal && <ScheduleDelivery open={showScheduleModal} toggleModal={toggleScheduleModal} />}
            {showImportOrderModal && <ImportOrderModal show={showImportOrderModal} toggleModal={toggleImportOrderModal} />}
        </div>
    );
};

export default DashboardLayout;
