import { isBetaUser, isSuperAdmin } from 'keycloak';
import React from 'react';
import { Link } from 'react-router-dom';
import './settingslayout.scss';
import { AddressBook, ArrowsJoin, BellRinging, CreditCard, MapPin, User, Users, Notes } from 'tabler-icons-react';
import AnimateElement from 'ui-components/AnimateElement';

const SettingsLayout = (props: any) => {
    const settingsNav = [
        {
            name: 'User management',
            children: [
                {
                    name: 'My team',
                    path: '/settings/teams',
                    match: '/settings/teams'
                }
            ]
        },
        {
            name: 'Business Management',
            disabled: !isSuperAdmin(),
            children: [
                {
                    name: 'My business',
                    path: '/settings/businesses',
                    match: '/settings/businesses'
                }
            ]
        },
        {
            name: 'Integration',
            children: [
                {
                    name: 'Setup integrations',
                    path: '/settings/my-shops',
                    match: '/settings/my-shops'
                },
                {
                    name: 'Active integrations',
                    path: '/settings/shop-ingeration',
                    match: '/settings/shop-ingeration'
                }
            ]
        },
        {
            name: 'Tracking',
            children: [
                {
                    name: 'Customize tracking page',
                    path: '/settings/tracking-pages',
                    match: '/settings/tracking-pages'
                }
            ]
        },
        // {
        //     name: 'Contacts',
        //     disabled: !isBetaUser(),
        //     children: [
        //         {
        //             name: 'My contacts',
        //             path: '/marketing/contacts',
        //             match: '/marketing/contacts'
        //         }
        //     ]
        // },
        {
            name: 'Payment',
            children: [
                {
                    name: 'Payment methods',
                    path: '/settings/payments',
                    match: '/settings/payments'
                }
            ]
        },
        {
            name: 'Manage addresses',
            children: [
                {
                    name: 'Saved addresses',
                    path: '/settings/addresses',
                    match: '/settings/addresses'
                }
            ]
        },
        {
            name: 'Notifications',
            children: [
                {
                    name: 'Setup notifications',
                    path: '/settings/notifications',
                    match: '/settings/notifications'
                }
            ]
        },
        {
            name: 'Developer portal',
            children: [
                {
                    name: 'API keys and Webhook',
                    path: '/settings/developer',
                    match: '/settings/developer'
                }
            ]
        },
        {
            name: 'Logs',
            disabled: !isSuperAdmin(),
            children: [
                {
                    name: 'My Events logs',
                    path: '/settings/events',
                    match: '/settings/events'
                }
            ]
        }
    ];
    const mobileSettingsNav = [
        {
            name: 'Users',
            path: '/settings/teams',
            match: '/settings/teams',
            icon: <Users size={28} strokeWidth={1.2} />
        },
        {
            name: 'Businesses',
            path: '/settings/businesses',
            match: '/settings/businesses',
            disabled: !isSuperAdmin(),
            icon: <Users size={28} strokeWidth={1.2} />
        },
        {
            name: 'Integrations',
            path: '/settings/my-shops',
            match: '/settings/my-shops',
            icon: <ArrowsJoin size={28} strokeWidth={1.2} />
        },
        {
            name: 'Tracking',
            path: '/settings/tracking-pages',
            match: '/settings/tracking-pages',
            icon: <MapPin size={28} strokeWidth={1.2} />
        },
        {
            name: 'Contacts',
            path: '/marketing/contacts',
            match: '/marketing/contacts',
            disabled: !isBetaUser(),
            icon: <Notes size={28} strokeWidth={1.2} />
        },
        {
            name: 'Payment',
            path: '/settings/payments',
            match: '/settings/payments',
            icon: <CreditCard size={28} strokeWidth={1.2} />
        },
        {
            name: 'Address',
            path: '/settings/addresses',
            match: '/settings/addresses',
            icon: <AddressBook size={28} strokeWidth={1.2} />
        },
        {
            name: 'Notifications',
            path: '/settings/notifications',
            match: '/settings/notifications',
            icon: <BellRinging size={28} strokeWidth={1.2} />
        },
        {
            name: 'Developer',
            path: '/settings/developer',
            match: '/settings/developer',
            icon: <User size={28} strokeWidth={1.2} />
        },
        {
            name: 'Logs',
            path: '/settings/events',
            match: '/settings/events',
            icon: <User size={28} strokeWidth={1.2} />
        }
    ];
    const { children } = props;
    const getSidebarMatch = (item: any) => {
        if (window.location.pathname.match(item)) {
            return true;
        }
        return false;
    };
    return (
        <div className="flex settings-layout">
            <div className="settings-sidebar px-6 sticky mr-6 hidden md:block">
                <div className="header mt-[10px] font-bold mb-10">Settings</div>
                {settingsNav
                    .filter((nav: any) => !nav.disabled)
                    .map((nav: any, index: number) => (
                        <div key={index}>
                            <div className="nav-title mt-6">{nav.name} </div>
                            {nav.children.map((child: any, _i: number) => (
                                <Link to={child?.path} className="w-full">
                                    <div
                                        className={`${
                                            getSidebarMatch(child.match) ? 'active-settings-menu' : ''
                                        } nav-item font-semi-bold text-base`}
                                    >
                                        {child.name}
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ))}
            </div>
            <section id="bottom-navigation" className="block md:hidden fixed inset-x-0 bottom-0 z-10 bg-white shadow  overflow-x-scroll">
                <div id="tabs" className="px-3 flex gap-x-8 md:justify-between">
                    {mobileSettingsNav
                        .filter((nav: any) => !nav.disabled)
                        .map((tab: any, i: number) => (
                            <AnimateElement key={i}>
                                <Link to={tab?.path} className="w-full">
                                    <div
                                        className={`w-full ${
                                            getSidebarMatch(tab.match) ? 'app-color' : ''
                                        }  justify-center inline-block text-center pt-2 pb-1`}
                                    >
                                        <div className={`${getSidebarMatch(tab.match) ? 'app-color' : ''} text-center flex justify-center`}>
                                            {tab.icon}
                                        </div>
                                        <span className="tab tab-whishlist block text-xs">{tab.name}</span>
                                    </div>
                                </Link>
                            </AnimateElement>
                        ))}
                </div>
            </section>

            <div className="settings-children mt-[35px] md:mt-[10px] mb-40 grow pl-5 md:pl-0">
                <div className="lg:px-4 2xl:px-20">{children}</div>
                <div className="h-24 md:h-8" />
            </div>
        </div>
    );
};
export default SettingsLayout;
