/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
import React, { Fragment, useEffect, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from 'assets/icons/clients/sidebar/dashboard.svg';
import { ReactComponent as OrderIcon } from 'assets/icons/clients/sidebar/orders.svg';
import { ReactComponent as RatingIcon } from 'assets/icons/clients/sidebar/ranking.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/icons/clients/sidebar/analytics.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/clients/sidebar/settings.svg';
import AnimateElement from 'ui-components/AnimateElement';
import { useKeycloak } from '@react-keycloak/web';
import clientLogo from 'assets/icons/clients/goodwill_logo.svg';

const sidebarlinks: any = [
    {
        name: 'Dashboard',
        path: '/client/dashboard',
        match: '/client/dashboard',
        icon: 'dashboard'
    },
    {
        name: 'Orders',
        path: '/client//orders/listing',
        match: /^\/orders/,
        icon: 'orders'
    },
    {
        name: 'Ratings',
        path: '/client//ratings',
        match: /^\/ratings/,
        icon: 'ratings'
    },
    {
        name: 'Analytics',
        path: '/client//analytics',
        match: /^\/analytics/,
        icon: 'analytics'
    },
    {
        name: 'Settings',
        path: '/client//settings',
        match: /^\/settings/,
        icon: 'settings',
        opened: false
    }
];

const Sidebar = (props: any): JSX.Element => {
    const { setDashboardState } = props;
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();
    const initialState = {
        sidebarMenu: sidebarlinks
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { sidebarMenu } = state;
    const getMatch = (item: string) => {
        if (window.location.pathname.match(item)) {
            return true;
        }
        return false;
    };

    const determineActiveMenu = () => {
        const sidebarMenuArray = sidebarMenu;
        for (let index = 0; index < sidebarMenuArray.length; index++) {
            const element = sidebarMenuArray[index];
            if (element.hasChildren && window.location.pathname.match(element?.match)) {
                element.opened = true;
                break;
            }
        }
        setState({
            sidebarMenu: [...sidebarMenuArray]
        });
    };

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        // Cleanup method
        determineActiveMenu();
        return () => {
            setState({
                ...initialState
            });
        };
    }, []);

    const mapIconsToMenus = (iconName: string) => {
        switch (iconName) {
            case 'dashboard':
                return <DashboardIcon className="sidebar-icon" />;
            case 'orders':
                return <OrderIcon className="sidebar-icon" />;
            case 'ratings':
                return <RatingIcon className="sidebar-icon" />;
            case 'analytics':
                return <AnalyticsIcon className="sidebar-icon" />;
            case 'settings':
                return <SettingsIcon className="sidebar-icon" />;

            default:
                return <> </>;
        }
    };
    return (
        <div className="sidebar relative">
            <div className="w-full">
                <div className="sidebar-business-container text-center  pt-6 mb-12">
                    <div className="mt-12">
                        <img src={clientLogo} alt="Logo" />
                        {/* <SidebarMenuDropDown /> */}
                    </div>
                </div>

                <div className="mt-36">
                    {sidebarMenu.map((item: any, index: any) => (
                        <div key={index}>
                            <div
                                className={`text-center side-bar-links text-white gap-x-3.5 mb-8 font-normal text-lg  ${
                                    getMatch(item.match) ? 'active-sidebar-link py-1 ' : ''
                                }`}
                                key={index}
                            >
                                <Link to={item.path} className="flex items-center gap-x-2.5 w-full" onClick={() => setDashboardState()}>
                                    {mapIconsToMenus(item?.icon)}
                                    <div>{item?.name}</div>
                                </Link>
                            </div>
                        </div>
                    ))}
                    <div style={{ height: '100px' }} />
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
