/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
import { MutateOperation } from 'constants/mutateOperation';
import moment from 'moment';
import { Buffer } from 'buffer';
import totalOderIcon from 'assets/icons/dashboard_icons/total_orders.svg';
import pendingOderIcon from 'assets/icons/dashboard_icons/pending_orders.svg';
import completedOderIcon from 'assets/icons/dashboard_icons/completed_orders.svg';
import moneyIcon from 'assets/icons/dashboard_icons/money_spent.svg';
import { tz } from 'moment-timezone';
import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';

const humanizeDuration = require('humanize-duration');

export const DATE_FORMAT = 'YYYY-MM-DD';

export const getTimeZone = (timezone?: string): string => tz(timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');

/**
 * Checks if an array is empty
 * @param {Array} arr Array to be tested
 * @returns {Boolean} Boolean value
 */
export const isNotEmptyArray = (arr: any) => Array.isArray(arr) && arr.length > 0;

export const changeNumberToArrayList = (number: any) => Array.from(Array(number).keys());

// /**
//  * Delete an array of keys from a given object
//  * @param {Object} targetObj Object to remove propeties from
//  * @param {Array} props Array of object properties to be deleted
//  * @returns {Object} A copy of the orginal object excluding the specified properties
//  */
export const omit = (targetObj: any, props: any): any => {
    // Clone the targetObj to avoid mutating the original data
    // eslint-disable-next-line prefer-object-spread
    const obj: any = Object.assign({}, targetObj);
    if (!Array.isArray(props)) {
        return;
    }

    props.forEach((prop) => {
        // eslint-disable-next-line no-prototype-builtins
        obj.hasOwnProperty(prop) && delete obj[prop];
    });

    // eslint-disable-next-line consistent-return
    return obj;
};

export const getUrlParams = (url = window.location.href) => {
    const params = {};
    // @ts-ignore
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        // @ts-ignore
        params[key] = value;
    });
    return params;
};

export const generateRandomString = () => uuidv4();

/**
 * Checks if an object has no set properties
 * @param {*} obj The object to test
 * @returns {*} boolean
 */
export const isObjectEmpty = (obj = {}) => !obj || Object.keys(obj).length === 0;

export const formatOptions = (options = []) => {
    options = JSON.parse(JSON.stringify(options));
    const opt = [];
    for (let index = 0; index < options.length; index++) {
        const element = options[index];
        opt.push({
            label: element,
            value: element
        });
    }
    return opt;
};

// /**
//  * Ensure that a given string matches the character count and ellipsized at that point
//  * @param {String} text Target text
//  * @param {Number} numChars Number of characters needed
//  * @returns {String} Truncated text
//  */
export const truncateMultilineText = (text: string, numChars: number) => {
    if (!text) return '';

    // Because '...' will be appended to long strings,
    // this ensures that the entire character count is as specified
    const maxStringLength = numChars - 3;

    return maxStringLength > text.length ? text : `${text.trim().substring(0, maxStringLength)}...`;
};

/**
 * Function that does nothing:
 * Useful as a default value for an optional Component prop
 * that's of type - function
 * Or for stubbing function calls in Tests and Storybook Docs
 * @returns {*} undefined
 */
export const noOp = () => {};

// /**
//  * Method to extract error message from error response object
//  * @returns {*} The error messgae
//  */
export const extractErrorMessage = (err: any): any => {
    const errResponse = err.response || err.toString();
    const validations = errResponse?.data?.errors ?? errResponse?.data?.validations ?? [];
    let apiErrors: any = {};
    const errorMessage =
        errResponse === null
            ? 'Something went Wrong. Please try again'
            : errResponse && errResponse.data && errResponse.data.message
            ? errResponse.data.message
            : err.toString();
    if (Array.isArray(validations)) {
        validations.forEach((v: any) => {
            apiErrors = { ...apiErrors, [v.property]: v.message };
        });
    } else {
        for (const key in validations) {
            if (Object.prototype.hasOwnProperty.call(validations, key)) {
                const element = validations[key];
                apiErrors[key] = element;
            }
        }
    }
    // validations.forEach((v: any) => {
    //     // apiErrors = { ...apiErrors, [v.property]: v.message };
    // });

    Sentry.captureException(err);
    return { msg: errorMessage, errors: apiErrors };
};

// /**
//  * Method to Extract initials from Full name
//  * @param {string} name name
//  * @returns {string} initials
//  */
export const getInitials = (name: string) => {
    const fullName = name.split(' ');
    const initials = fullName[0].substring(0, 1).toUpperCase();

    if (fullName.length > 1) {
        initials.concat(fullName[fullName.length - 1].substring(0, 1).toUpperCase());
    }

    return initials;
};

export const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

export const scrollDown = () => {
    window.scrollTo({
        // bottom:0,
        behavior: 'smooth'
    });
};

export const formatDate = (date: Date) => {
    if (!date) return '';
    const d = new Date(date);

    const newDate = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
    return newDate;
};
export const formatDate2 = (date: Date) => {
    if (!date) return '';
    return moment(date).format('DD-MM-YYYY');
};

// call this function, passing-in your date
export const dateToFromNowDaily = (myDate: string) => {
    // get from-now for this date
    const fromNow = moment(myDate).fromNow();
    const dateFormating = `${moment(myDate).format('MM/DD/YYYY H:mm A')} ${getTimeZone()}`;

    return moment(myDate).isBetween(moment(), moment().add(12, 'hour'))
        ? fromNow
        : moment(myDate).calendar(null, {
              lastWeek: '[' + dateFormating + ']',
              lastDay: '[' + dateFormating + ']',
              sameDay: `[Today] h:mm A [${getTimeZone()}]`,
              nextDay: `[Tomorrow] h:mm A [${getTimeZone()}]`,
              nextWeek: '[' + dateFormating + ']',
              sameElse: () => '[' + dateFormating + ']'
          });
};

export const capitalizeFirstLetter = (string = '') => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const extractFirstLetter = (string = '') => {
    if (string) {
        return string.charAt(0).toUpperCase();
    }

    return string;
};

export const clearNumber = (value: any) => value.replace(/\D+/g, '');

export const clearPhoneNumber = (value: any) => value.replace(/([^\w]+|\s+)/g, '');

export const formatCVC = (value: any, allValues = {}) => {
    const clearValue = clearNumber(value);
    let maxLength = 4;
    // @ts-ignore
    if (allValues.number) {
        // @ts-ignore
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === 'amex' ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
};

export const formatExpirationDate = (value: any) => {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
};

export const validateImage = (file: any): boolean => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
    if (validTypes.indexOf(file.type) === -1) {
        return false;
    }

    return true;
};
export const dateIsValid = (date: any) => moment(date).isValid();
export const getFileXtension = (file: File) => {
    const fileName = file.name;
    return fileName.split('.').pop();
};
export const getFileName = (file: File) => {
    const fileName = file.name;
    return fileName || '';
};
export const replaceUnderScore = (str = '') =>
    // Empty
    str.split('_').join(' ');

export const replaceSpaceWithUnderScore = (str = '') =>
    // Empty
    str.split(' ').join('_');

export const changeStringToLowerCase = (str = '') => str.toLowerCase();

export const getDateFromWeek = (w: any, y: any) => {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

    return ISOweekStart;
};

export const snakeCaseToCamelCase = (str: string): string =>
    str
        .toLowerCase()
        .replace(/_[a-z]/g, (match: string) => match[1].toUpperCase())
        .replace(/_/g, '');

export const camelToSnakeCase = (str: string): string => str.replaceAll(/[A-Z1-9]/g, (letter) => `_${letter.toLowerCase()}`);

export const camelToSnakeObject = (obj: any): Object => {
    let newObject: any = {};
    Object.keys(obj).forEach((key: string) => {
        newObject = { ...newObject, [camelToSnakeCase(key)]: obj[key] };
    });
    return newObject;
};

export const snakeToCamelObject = (obj: any): Object => {
    let newObject: any = {};
    Object.keys(obj).forEach((key: string) => {
        newObject = { ...newObject, [snakeCaseToCamelCase(key)]: obj[key] };
    });
    return newObject;
};

export const formatCurrency = (value: any, sign: Boolean) => {
    if (sign) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 3 }).format(value);
    }
    return new Intl.NumberFormat('en-US').format(value).replace(' ', '.');
};

export const extractAddress = (address: any, useGeometryFunction = true) => {
    const {
        address_components,
        formatted_address,
        geometry: { location }
    }: any = address;
    let obj = {
        state: '',
        city: '',
        country: '',
        street: '',
        longitude: '',
        latitude: '',
        zip_code: '',
        address_1: '',
        address_2: '',
        address_name: ''
    };

    for (let i = 0; i < address_components.length; i++) {
        for (let j = 0; j < address_components[i].types.length; j++) {
            switch (address_components[i].types[j]) {
                case 'street_number':
                    obj = { ...obj, street: address_components[i]?.long_name };
                    break;
                case 'postal_code':
                    obj = { ...obj, zip_code: address_components[i]?.long_name };
                    break;
                case 'country':
                    obj = { ...obj, country: address_components[i]?.short_name };
                    break;
                case 'administrative_area_level_1':
                    obj = { ...obj, state: address_components[i]?.short_name };
                    break;
                case 'neighborhood':
                    obj = { ...obj, city: address_components[i]?.long_name };
                    break;
                case 'locality':
                    obj = { ...obj, city: address_components[i]?.long_name };
                    break;
                case 'sublocality_level_1':
                    obj = { ...obj, city: address_components[i]?.long_name };
                    break;
                case 'route':
                    obj = { ...obj, address_1: address_components[i]?.long_name };
                    break;
                case 'political':
                    obj = { ...obj, address_2: address_components[i]?.long_name };
                    break;
                default:
                    break;
            }
        }
    }

    obj = {
        ...obj,
        street: `${obj.street}`,
        address_1: `${obj.street} ${obj.address_1}`,
        address_name: formatted_address,
        longitude: useGeometryFunction ? location.lng() : location.lng,
        latitude: useGeometryFunction ? location.lat() : location.lat
    };

    return obj;
};

export const notEmptyString = (string: string): boolean => {
    if (string !== undefined && string !== null) {
        return true;
    }
    return false;
};

export const mutateArray = (operation: string, array: Array<any>, data: { id: string; _id?: string }): Array<any> => {
    switch (operation) {
        case MutateOperation.ADD:
            return [data, ...array];
        case MutateOperation.REMOVE: {
            return array.filter((val: any) => (val.id !== data.id && val._id !== data.id) ?? val);
        }
        case MutateOperation.UPDATE: {
            const index = array.findIndex((val: any) => val.id === data.id || val._id === data.id);
            if (index !== -1) array[index] = data;
            return array;
        }
        default:
            return array;
    }
};

export const getDateInterval = (interval: string): any => {
    const DATE_FORMAT = 'YYYY-MM-DD';
    const next_day = moment().add(2, 'days').format(DATE_FORMAT);
    switch (interval) {
        case 'today':
            return { start: moment().format(DATE_FORMAT), end: next_day };
        case 'last_week':
            return { start: moment().add(-6, 'days').format(DATE_FORMAT), end: next_day };
        case 'last_month':
            return {
                start: moment().subtract(1, 'months').startOf('month').format(DATE_FORMAT),
                end: moment().subtract(1, 'months').endOf('month').format(DATE_FORMAT)
            };
        case 'last_year':
            return {
                start: moment().add(-11, 'month').format(DATE_FORMAT),
                end: next_day
            };
        default:
            return { start: moment().year(2020).format(DATE_FORMAT), end: next_day };
    }
};

export const bufferToSrcImage = (buffer: string): string => {
    const _buffer = `data:image/png;base64,${Buffer.from(buffer).toString('base64')}`;
    return buffer ? _buffer : '';
};

export const getCounters = (counters: any = [], total_order_growth: number = 0, completed_order_growth: number = 0): any => {
    let total_orders: number = 0;
    let total_completed_orders: number = 0;
    let total_pending_orders: number = 0;
    let total_cost: number = 0;

    counters.forEach(({ status, count, cost }: any) => {
        total_orders += count;
        total_cost += cost;
        total_completed_orders += status === 'DELIVERED' ? count : 0;
        total_pending_orders += ['CONFIRMED', 'PENDING', 'CREATED', 'PICKED_UP'].includes(status) ? count : 0;
    });

    return [
        {
            label: 'Total orders',
            value: total_orders,
            growth: total_order_growth,
            icon: totalOderIcon,
            key: 'ALL',
            hasBadge: true
        },
        {
            label: 'Completed orders',
            value: total_completed_orders,
            growth: completed_order_growth,
            icon: completedOderIcon,
            key: 'DELIVERED',
            hasBadge: true
        },
        {
            label: 'Pending orders',
            value: total_pending_orders,
            growth: 0,
            icon: pendingOderIcon,
            key: 'PENDING'
        },
        {
            label: 'Total money spent',
            value: formatCurrency(total_cost, true),
            growth: 0,
            icon: moneyIcon,
            key: 'COST'
        }
    ];
};

export const getTotalReviews = (totals: any = []): any => {
    let ratings: number = 0;
    let orders: number = 0;
    let percent: number = 0;
    totals.forEach(({ total_ratings, total_orders }: any) => {
        ratings += total_ratings;
        orders += total_orders;
    });
    percent = Math.round((ratings / orders) * 100);
    return [
        {
            total_ratings: ratings,
            total_orders: orders,
            percentage: percent
        }
    ];
};

export const checkIfDataIsEmpty = (isLoading = false, data = []) => {
    if (isNotEmptyArray(data) && !isLoading) return false;
    if (isLoading) return false;
    return true;
};

export const getAverageRating = (ratings: any = []): any => {
    let totale_rating = 0;
    let totale_starts = 0;
    let average = 0;
    ratings.forEach(({ total, start_number }: any) => {
        totale_rating += total;
        totale_starts += total * start_number;
    });
    if (totale_rating > 0) {
        average = Math.round(totale_starts / totale_rating);
    }
    return {
        ratins: totale_rating,
        average
    };
};

export const getlastRewiews = (counts: any = []): any => {
    const reviews: any[] = [];
    counts.forEach(({ content, starNumber, provider, amount, adress, orderId }: any) => {
        reviews.push({
            message: content ?? '',
            start: starNumber,
            amount,
            provider,
            adress: adress.address_name,
            tracking: 'https://www.xpressrun.com/tracking?track=' + orderId
        });
    });
    return reviews;
};

export const getRankings = (ratings: any = []): any => {
    const rankins: any[] = [];
    let totale_rating = 0;
    ratings.forEach(({ total }: any) => {
        totale_rating += total;
    });

    for (let i = 5; i > 0; i--) {
        const rat = ratings.find((element: any) => element.start_number === i);
        if (rat) {
            rankins.push({
                total: rat.total,
                start_number: rat.start_number,
                percent: Math.round((rat.total / totale_rating) * 100)
            });
        } else {
            rankins.push({
                total: 0,
                start_number: i,
                percent: 0
            });
        }
    }
    return rankins;
};

export const getColor = (order: number) => {
    let value = '#79B3FA';
    switch (order) {
        case 5:
            value = '#5838CA';
            break;
        case 4:
            value = '#674ACE';
            break;
        case 3:
            value = '#755CD1';
            break;
        case 2:
            value = '#9380D7';
            break;
        default:
            value = '#B0A3DE';
    }
    return value;
};

export const isValidURL = (string: string) => {
    const res = string.match(
        /(https:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
    );
    return res !== null;
};

export const humanizer = (value: number, data?: any) => {
    const shortEnglishHumanizer = humanizeDuration.humanizer({
        language: 'shortEn',
        languages: {
            shortEn: {
                y: () => 'y',
                mo: () => 'mo',
                w: () => 'w',
                d: () => 'd',
                h: () => 'h',
                m: () => 'm',
                s: () => 's',
                ms: () => 'ms'
            }
        }
    });

    return shortEnglishHumanizer(value, data);
};

export const combineDateAndTime = function (date: any, time: any) {
    const day = moment(date).format('yyyy-MM-DD');
    const timeHrs = moment(time).format('HH:mm');
    const datetime = moment(day + ' ' + timeHrs);
    return datetime.format('yyyy-MM-DD HH:mm');
};
