/* eslint-disable consistent-return */
import axios from 'axios';
import { errorToast } from 'components/Toast';
import { API_URL } from 'constants/apiRoutes';
import keycloak, { updateToken } from 'keycloak';
import { extractErrorMessage } from 'libs';

const httpCommon = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-type': 'application/json'
    }
});

// Request interceptor for API calls
httpCommon.interceptors.request.use(
    (config) => {
        if (keycloak.authenticated) {
            const cb = () => {
                config.headers = {
                    Authorization: `Bearer ${keycloak?.token}`
                };
                return config;
            };
            return updateToken(cb);
        }
        config.headers = {
            'Content-type': 'application/json'
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

httpCommon.interceptors.response.use(
    (res) => res,
    (err) => {
        const { msg } = extractErrorMessage(err);
        if (axios.isCancel(err)) return;

        errorToast(msg);
        return Promise.reject(err);
    }
);

export default httpCommon;
