/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';

import './dropdownselect.scss';

const Icon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
        <path d="M7 7.5L0.504809 0.75L13.4952 0.75L7 7.5Z" fill="#D9D9D9" />
    </svg>
);

const CloseIcon = () => (
    <svg height="20" width="20" viewBox="0 0 20 20">
        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
    </svg>
);

const DropdownSelect = ({ placeHolder, options, isMulti, isSearchable, onChange, value, allSelectOtion = true }: any) => {
    const [showMenu, setShowMenu] = useState(false);
    const [selectedValue, setSelectedValue]: any = useState(isMulti ? [] : value);
    const [searchValue, setSearchValue] = useState('');
    const searchRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        setSearchValue('');
        if (showMenu && searchRef.current) {
            // @ts-ignore
            searchRef.current.focus();
        }
    }, [showMenu]);

    useEffect(() => {
        const handler = (e: any) => {
            // @ts-ignore
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setShowMenu(false);
            }
        };

        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    });
    const handleInputClick = (e: any) => {
        setShowMenu(!showMenu);
    };
    const removeOption = (option: any) => selectedValue.filter((o: any) => o.value !== option.value);
    const onTagRemove = (e: any, option: any) => {
        e.stopPropagation();
        const newValue = removeOption(option);
        setSelectedValue(newValue);
        onChange(newValue);
    };

    const getDisplay = () => {
        if (!selectedValue || selectedValue.length === 0) {
            return placeHolder;
        }
        if (isMulti) {
            return (
                <div className="dropdown-tags">
                    {selectedValue.map((option: any) => (
                        <div key={option.value} className="dropdown-tag-item">
                            {option.label}
                            <span onClick={(e) => onTagRemove(e, option)} className="dropdown-tag-close">
                                <CloseIcon />
                            </span>
                        </div>
                    ))}
                </div>
            );
        }
        return selectedValue.label;
    };

    const onItemClick = (option: any) => {
        let newValue;
        if (isMulti) {
            if (selectedValue.findIndex((o: any) => o.value === option.value) >= 0) {
                newValue = removeOption(option);
            } else {
                newValue = [...selectedValue, option];
            }
        } else {
            newValue = option;
        }
        setSelectedValue(newValue);
        onChange(newValue);
    };

    const isSelected = (option: any) => {
        if (isMulti) {
            return selectedValue.filter((o: any) => o.value === option.value).length > 0;
        }

        if (!selectedValue) {
            return false;
        }

        return selectedValue.value === option.value;
    };

    const onSearch = (e: any) => {
        setSearchValue(e.target.value);
    };

    const getOptions = () => {
        if (!searchValue) {
            if (allSelectOtion) return [{ label: 'All', value: '' }, ...options];
            if (!allSelectOtion) return [...options];
        }
        return options.filter((option: any) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0);
    };

    return (
        <div className="drop-down-select">
            <div className="dropdown-container border py-[8px] px-[10px]">
                {/* @ts-ignore */}
                <div ref={inputRef} onClick={handleInputClick} className="dropdown-input">
                    <div className="dropdown-selected-value text-[14.3px]">{getDisplay()}</div>
                    <div className="dropdown-tools">
                        <div className="dropdown-tool">
                            <Icon />
                        </div>
                    </div>
                </div>
                {showMenu && (
                    <div className="dropdown-menu pb-2">
                        {isSearchable && (
                            <div className="search-box px-2">
                                {/* @ts-ignore */}
                                <input onChange={onSearch} value={searchValue} ref={searchRef} />
                            </div>
                        )}
                        {getOptions().map((option: any) => (
                            <div
                                onClick={() => onItemClick(option)}
                                key={option.value}
                                className={`px-3 mx-1 mt-1 rounded-sm dropdown-item text-[15px] ${isSelected(option) && 'selected app-bg'}`}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DropdownSelect;
