import React, { useEffect } from 'react';
import Routes from 'routes';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak, { getUserInfo, keycloakInitConfig } from 'keycloak';
import Loader from 'components/Loadable/Loader';
import { Toaster } from 'react-hot-toast';
import { useIntercom } from 'react-use-intercom';
import * as Sentry from '@sentry/react';

const App = (): JSX.Element => {
    const { update } = useIntercom();
    const onEvent = (event: any, error: any) => {
        switch (event) {
            case 'onReady': {
                update({
                    name: getUserInfo()?.name ?? '',
                    email: getUserInfo()?.email ?? '',
                    userId: getUserInfo()?.business_id ?? ''
                });
                Sentry.setUser({ email: getUserInfo()?.email ?? '' });
                break;
            }
            default:
                break;
        }
    };

    return (
        <>
            <ReactKeycloakProvider
                initOptions={keycloakInitConfig}
                // @ts-ignore
                LoadingComponent={<Loader />}
                onEvent={onEvent}
                authClient={keycloak}
            >
                <Routes />
            </ReactKeycloakProvider>
            <Toaster position="top-right" reverseOrder={false} />
        </>
    );
};
export default App;
