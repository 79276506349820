/* eslint-disable react/button-has-type */
import React from 'react';
import './button.scss';
import { Oval } from 'react-loader-spinner';
import AnimateElement from 'ui-components/AnimateElement';

const Button = (props: ButtonPropsType): JSX.Element => {
    const { text, action, className, icon, isLoading, disabled, animateClass, ...rest } = props;

    const btnLoadingComponent = () => (
        <div className="flex justify-center w-full items-center">
            <Oval
                height={22}
                width={60}
                color="white"
                wrapperStyle={{}}
                wrapperClass=""
                visible
                ariaLabel="oval-loading"
                secondaryColor="white"
                strokeWidth={10}
                strokeWidthSecondary={10}
            />
        </div>
    );
    return (
        <AnimateElement className={animateClass}>
            {!icon ? (
                <button
                    // @ts-ignore
                    type="button"
                    onClick={action}
                    className={` ${className} ${disabled ? 'button-disabled' : ''} ${isLoading ? 'not-allowed' : ''}`}
                    disabled={disabled}
                    {...rest}
                >
                    {isLoading ? <div>{btnLoadingComponent()}</div> : <div className="text-center w-full">{text}</div>}
                </button>
            ) : (
                <button
                    // @ts-ignore
                    type="button"
                    onClick={action}
                    className={`app-icon-button ${className} ${disabled ? 'button-disabled' : ''} ${isLoading ? 'not-allowed' : ''}`}
                    disabled={disabled}
                    {...rest}
                >
                    {isLoading ? (
                        <span>{btnLoadingComponent()}</span>
                    ) : (
                        <>
                            <span>{icon}</span>
                            {text && <span>{text}</span>}
                        </>
                    )}
                </button>
            )}
        </AnimateElement>
    );
};
export default Button;
