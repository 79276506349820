import React from 'react';
import logoIcon from 'assets/icons/sidebar/new_xpressRun_logo.svg';
import './xpressrunloader.scss';
import { Skeleton } from '@mui/material';
import { changeNumberToArrayList } from 'libs';
import { ColorRing, Oval } from 'react-loader-spinner';

const XpressrunLoader = () => (
    <div className="xpressrun-loader">
        <div>
            <img src={logoIcon} alt="Loader" />
        </div>
    </div>
);
export const XpressrunPageLoader = () => (
    <div className="xpressrun-page-loader">
        <div>
            {/* <img src={logo} alt="Loader" /> */}
            <Oval
                height={200}
                width={70}
                color="#5838CA"
                wrapperStyle={{}}
                wrapperClass=""
                visible
                ariaLabel="oval-loading"
                secondaryColor="#5838CA"
                strokeWidth={8}
                strokeWidthSecondary={8}
            />
        </div>
    </div>
);

export const TableLoader = ({ rows = 1, columns = 1 }) => (
    <>
        {changeNumberToArrayList(rows).map((row, index) => (
            <tr className="pointer" key={`tr-${index}`}>
                {changeNumberToArrayList(columns).map((row, index) => (
                    <td className={`${index === 0 ? 'md:pl-8' : 'pl-2'} ${index + 1 === columns ? 'md:pr-8' : 'pr-2'}`}>
                        <Skeleton variant="rounded" animation="wave" width="85%" height={25} />
                    </td>
                ))}
            </tr>
        ))}
    </>
);
export const TableSpinningLoader = ({ rows = 1, columns = 1 }) => (
    <ColorRing
        visible
        height="100"
        width="65"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={['#5838CA', '#5838CA', '#5838CA', '#5838CA', '#5838CA']}
    />
);

export const InputSpinningLoader = ({ rows = 1, columns = 1 }) => (
    <ColorRing
        visible
        height="80"
        width="50"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={['#5838CA', '#5838CA', '#5838CA', '#5838CA', '#5838CA']}
    />
);

export default XpressrunLoader;
