/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable arrow-body-style */
import React, { useEffect, useReducer } from 'react';
import './plaindashboardlayout.scss';
import { Outlet, useNavigate, useParams, Link } from 'react-router-dom';

const PlainDashboardLayout = (props: any) => {
    const navigate = useNavigate();
    const params = useParams();
    const initialState = {
        pageLoading: true
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { activeIndex, pages } = state;

    return (
        <div className="">
            <Outlet />
        </div>
    );
};
export default PlainDashboardLayout;
