import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { IntegrationUrl } from 'constants/integrationUrl';
import { useSearchParams } from 'react-router-dom';

function IntegrationCheck() {
    const [searchParams] = useSearchParams();
    const { initialized, keycloak } = useKeycloak();

    useEffect(() => {
        if (initialized) {
            if (!keycloak?.authenticated) {
                window.location = `${IntegrationUrl.SIGNUP_URL}?${searchParams}`;
            } else {
                window.location = `${IntegrationUrl.BACKEND_URL}/${
                    searchParams.get('provider_name')?.toLowerCase() ?? 'shopify'
                }/integration?jwt=${keycloak.token}&token=${searchParams.get('token')}&failed_redirect=${
                    IntegrationUrl.FAILED_URL
                }&success_redirect=${IntegrationUrl.SUCCESS_URL}`;
            }
        }
    }, [initialized, keycloak]);

    return <div>Checking...</div>;
}

export default IntegrationCheck;
