import React from 'react';
import { useLocation } from 'react-router-dom';
import icon400 from 'assets/icons/utils_icons/error_bad_request.svg';

const Page400 = () => {
    const { search } = useLocation();

    // Parse the query parameters from the location search string
    const searchParams = new URLSearchParams(search);

    // Retrieve the 'message' query parameter
    const message = searchParams.get('message');

    return (
        <div>
            <div className="flex justify-center align-center w-4/12 mx-auto">
                <img src={icon400} alt="" />
            </div>
            {message && (
                <div className="text-center text-warning mb-2 mt-1">
                    <p>{message}</p>
                </div>
            )}
        </div>
    );
};

export default Page400;
