/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useRef, useState } from 'react';
import './dropdown.scss';

const Icon = () => (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.375 1.25L6 5.625L1.625 1.25" stroke="#19094C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const CloseIcon = () => (
    <svg height="20" width="20" viewBox="0 0 20 20">
        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
    </svg>
);

const Dropdown = ({ placeHolder = 'Select', options = [], isMulti = false, isSearchable = false, value = null, onChange }: any) => {
    const [showMenu, setShowMenu] = useState(false);
    const [selectedValue, setSelectedValue] = useState(isMulti ? [] : value);
    const [searchValue, setSearchValue] = useState('');
    const searchRef = useRef();
    const inputRef = useRef();
    useEffect(() => {
        setSearchValue('');
        if (showMenu && searchRef.current) {
            // @ts-ignore
            searchRef.current.focus();
        }
    }, [showMenu]);

    useEffect(() => {
        const handler = (e: any) => {
            // @ts-ignore
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setShowMenu(false);
            }
        };

        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    });

    useEffect(() => {
        setSelectedValue(isMulti ? [] : value);
    }, [value]);

    const handleInputClick = (e: any) => {
        setShowMenu(!showMenu);
    };

    const getDisplay = () => {
        if (!selectedValue || selectedValue.length === 0) {
            return placeHolder;
        }
        if (isMulti) {
            return (
                <div className="dropdown-tags">
                    {selectedValue.map((option: any) => (
                        <div key={option?.value} className="dropdown-tag-item">
                            {/* @ts-ignore */}
                            {option.label}
                            <span onClick={(e) => onTagRemove(e, option)} className="dropdown-tag-close">
                                <CloseIcon />
                            </span>
                        </div>
                    ))}
                </div>
            );
        }
        // @ts-ignore
        return selectedValue.label;
    };
    // @ts-ignore
    const removeOption = (option: { value: any }) => selectedValue.filter((o) => o.value !== option.value);

    const onTagRemove = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, option: any) => {
        e.stopPropagation();
        const newValue = removeOption(option);
        setSelectedValue(newValue);
        onChange(newValue);
    };

    const onItemClick = (option: { value: any }) => {
        let newValue;
        if (isMulti) {
            // @ts-ignore
            if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
                newValue = removeOption(option);
            } else {
                // @ts-ignore
                newValue = [...selectedValue, option];
            }
        } else {
            newValue = option;
        }
        // @ts-ignore
        setSelectedValue(newValue);
        onChange(newValue);
    };

    const isSelected = (option: { value: any }) => {
        if (isMulti) {
            // @ts-ignore
            return selectedValue.filter((o) => o.value === option.value).length > 0;
        }

        if (!selectedValue) {
            return false;
        }
        // @ts-ignore
        return selectedValue.value === option.value;
    };

    // const onSearch = (e: { target: { value: React.SetStateAction<string> } }) => {
    //     setSearchValue(e.target.value);
    // };

    const getOptions = () => {
        if (!searchValue) {
            return options;
        }

        return options.filter((option: { label: string }) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0);
    };

    return (
        <div className="">
            <div className="dropdown-container modal-dropdown min-w-[100px] px-[7px]">
                {/* @ts-ignore */}
                <div ref={inputRef} onClick={handleInputClick} className="dropdown-input">
                    <div className="dropdown-selected-value">{getDisplay()}</div>
                    <div className="dropdown-tools">
                        <div className="dropdown-tool">
                            <Icon />
                        </div>
                    </div>
                </div>
                {showMenu && (
                    <div className="dropdown-menu">
                        {getOptions().map((option: { value: React.Key | null | undefined; label: any }) => (
                            <div
                                onClick={() => onItemClick(option)}
                                key={option.value}
                                className={`dropdown-item ${isSelected(option) && 'selected'}`}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dropdown;
