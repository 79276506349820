import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// @ts-ignore
import PWAPrompt from 'react-ios-pwa-prompt';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SENTRY_ENV } from 'constants/apiRoutes';
import TagManager from 'react-gtm-module';

Sentry.init({
    dsn: 'https://6faa146527d444f2859fd73e6cd71ddf@o4504419645194240.ingest.sentry.io/4504419647356928',
    integrations: [new BrowserTracing()],
    beforeSend(event, hint) {
        const error: any = hint.originalException;
        if (error?.code === 'ERR_CANCELED') return null;
        return event;
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: SENTRY_ENV
});

const tagManagerArgs = {
    gtmId: 'AW-10862022836'
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter>
        <IntercomProvider initializeDelay={5000} appId="y7xn4ijq" autoBoot>
            <App />
            <PWAPrompt
                copyTitle="XpressRun"
                copyBody="This application has app functionality. Add it to your home screen to use it in fullscreen and while offline "
                promptOnVisit={1}
                timesToShow={3}
                copyClosePrompt="Close"
                permanentlyHideOnDismiss={false}
            />
        </IntercomProvider>
    </BrowserRouter>
);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
