/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useReducer } from 'react';
import Button from 'components/Button';
import { Modal } from 'flowbite-react';
import 'react-datepicker/dist/react-datepicker.css';
import { useApp } from 'app-context';
import { validateData } from 'helpers';
import { isObjectEmpty } from 'libs';
import { X } from 'tabler-icons-react';
import AnimateElement from 'ui-components/AnimateElement';
import { useNavigate } from 'react-router-dom';
import NewDropDownSelect from 'components/NewDropDownSelect';
import { IntegrationHttpService } from 'http-service/Integration-http-service';
import { warningToast } from 'components/Toast';

const ImportOrderModal = (props: any) => {
    const { stores, update } = useApp() as AppContextType;
    const navigate = useNavigate();
    const { show, toggleModal, title } = props;
    const initialState = {
        pageLoading: true,
        selectedStore: null,
        errors: {},
        storeArray: [],
        isLoading: false
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { selectedStore, errors, isLoading, storeArray } = state;
    const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setState({
            selectedStore: e,
            errors: {}
        });
    };

    const importOrderFromStore = async () => {
        try {
            setState({
                isLoading: true
            });
            const apiRequest = await new IntegrationHttpService().getWithUrl(`/orders/synchronize/${selectedStore?.id}`);
            if (apiRequest?.data?.data) {
                update({
                    selectedStoreObj: selectedStore
                });
                navigate('/orders/listing?tab=import');
                toggleModal();
            } else {
                warningToast('Zero orders to import from the selected store');
            }
        } catch (error) {
            // Empty
        } finally {
            setState({
                isLoading: false
            });
        }
    };

    const submit = async (e: React.SyntheticEvent<Element, Event>) => {
        e.preventDefault();
        const rules = {
            selectedStore: 'required'
        };
        const messages = {
            'selectedStore.required': 'Select a store'
        };
        const validate = await validateData({ selectedStore }, rules, messages);

        if (isObjectEmpty(validate)) {
            importOrderFromStore();
            // update({ newCampaignName: formData?.name });
            // if (title) toggleModal();
            // if (!title) saveDraft();
        } else {
            setState({
                errors: validate
            });
        }
    };

    useEffect(() => {
        const newStoreArray = stores.map((obj) => ({ ...obj, label: obj.name, value: obj.id }));
        setState({
            storeArray: newStoreArray
        });
    }, [stores]);

    return (
        <Modal show={show} size="xl">
            <Modal.Body className="pt-4 px-3 md:px-6">
                <div className="flex justify-between items-center">
                    <h5 className="font-semi-bold text-xl">{title || 'Select Store/Business'}</h5>
                    <div>
                        <span className="pointer" onClick={() => toggleModal()}>
                            <AnimateElement>
                                <X size={30} strokeWidth={2} color="#19094C" />
                            </AnimateElement>
                        </span>
                    </div>
                </div>
                <div className="mt-[24px]">
                    <NewDropDownSelect placeHolder="Select store" options={storeArray} onChange={handleSelectChange} />
                </div>

                {errors?.selectedStore && <span className="text-sm error-text">{errors?.selectedStore}</span>}
                <div className="mt-10 flex justify-end gap-x-3">
                    <Button
                        action={() => toggleModal()}
                        text="Cancel"
                        disabled={isLoading}
                        className="app-dark-outline-btn rounded-md px-4 py-2"
                    />
                    <Button
                        action={(e: any) => submit(e)}
                        text="Continue"
                        isLoading={isLoading}
                        disabled={isLoading}
                        className="app-btn-border-0 rounded-md px-4 py-2"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ImportOrderModal;
