import { useRoutes } from 'react-router-dom';
import {
    DashboardRoutes,
    IntegrationRoutes,
    TrackingPageRoutes,
    StoreRoutes,
    PublicRoutes,
    DashboardPlainLayOut,
    ClientLayOut,
    PublicRoutePlainLayOut
} from './app-routes';

// ===========================|| ROUTING RENDER ||=========================== //

export default function Routes() {
    return useRoutes([
        DashboardRoutes,
        TrackingPageRoutes,
        PublicRoutes,
        IntegrationRoutes,
        StoreRoutes,
        DashboardPlainLayOut,
        ClientLayOut,
        PublicRoutePlainLayOut
    ]);
}
