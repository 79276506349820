import React from 'react';
import './searchbar.scss';
import searchIcon from 'assets/icons/search.svg';
import AnimateElement from 'ui-components/AnimateElement';

const SearchBar = (props: any): JSX.Element => {
    const { text, children, secondaryText, value, className, onChange, placeholder, inputClassName = '', parentClass = '' } = props;
    return (
        <div className={`search-input ${parentClass}`}>
            <div className="wrap">
                <form className={`search ${className} ${inputClassName}`} action="#">
                    {/* <AnimateElement> */}
                    <button type="submit" className={`searchButton ${inputClassName}`}>
                        <img src={searchIcon} alt="search" />
                    </button>
                    {/* </AnimateElement> */}
                    <input
                        type="text"
                        value={value}
                        onChange={onChange}
                        className={`searchTerm border-transparent focus:border-transparent focus:ring-0 ${inputClassName}`}
                        placeholder={placeholder || 'Search for deliveries, transactions'}
                    />
                </form>
            </div>
        </div>
    );
};

export default SearchBar;
