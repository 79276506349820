/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useReducer } from 'react';
import Sidebar from './Sidebar';
import './clientDashboardLayout.scss';
import SearchBar from 'components/SearchBar';
import WhatisNew from './NavDropdowns/WhatisNew';
import { Outlet } from 'react-router-dom';
import AnimateElement from 'ui-components/AnimateElement';
import questionMarkIcon from 'assets/icons/question_mark.svg';

const ClientsLayout = (props: any) => {
    const initialState = {
        openMobileSideBar: false
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { openMobileSideBar } = state;

    const toogleMobileMenu = () => {
        setState({
            openMobileSideBar: !openMobileSideBar
        });
    };

    const getSidebarMatch = () => {
        const match = /^\/settings/;
        if (window.location.pathname.match(match)) {
            return (
                <div>
                    <Outlet />
                </div>
            );
        }
        return (
            <div className="main-body">
                <Outlet />
            </div>
        );
    };

    const setDashboardState = () => {
        setState({
            ...state
        });
    };
    const closeMobileMenu = () => {
        setState({
            openMobileSideBar: false
        });
    };

    useEffect(() => {
        setState({
            openMobileSideBar: false
        });
        // Cleanup method
        return () => {
            setState({
                ...initialState
            });
        };
    }, []);

    return (
        <div className="client-dashboard-layout">
            <div className="sidebar-container fixed">
                <Sidebar setDashboardState={setDashboardState} />
            </div>
            <div className={`mobile-sidebar-container ${openMobileSideBar ? 'open-mobile-sidebar' : ''}`}>
                <div>
                    <Sidebar setDashboardState={setDashboardState} />
                </div>
                <div onClick={() => closeMobileMenu()} className="w-screen h-screen relative" />
            </div>
            <main className="w-full main relative">
                <div className="nav-container fixed">
                    <nav className="flex justify-between w-full main-nav items-center">
                        <div className="nav-search-bar">
                            <SearchBar inputClassName="bg-white" />
                        </div>
                        <AnimateElement>
                            <div className="mobile-hamburger-menu pointer" onClick={toogleMobileMenu} />
                        </AnimateElement>
                        {/* <div className="flex gap-x-12 items-center">
                            <WhatisNew />
                            <AnimateElement>
                                <div className="feed-back flex items-center justify-center pointer">
                                    <img src={questionMarkIcon} alt="Feedback" />
                                </div>
                            </AnimateElement>
                        </div> */}
                    </nav>
                </div>
                {getSidebarMatch()}
            </main>
        </div>
    );
};

export default ClientsLayout;
