/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PageTitle from 'components/PageTitle';
import '../../settings.scss';
import React, { useEffect, useReducer, useState } from 'react';
import { useApp } from 'app-context';
import { extractFirstLetter, formatDate, isObjectEmpty, truncateMultilineText } from 'libs';
import BusinessForm from './BusinessForm';

const BusinessListing = () => {
    const { businesses } = useApp() as AppContextType;
    const initialState = {
        business: ''
    };

    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { business } = state;

    const tooggleBusiness = (business: any = '') => {
        setState({ business });
    };

    useEffect(() => {}, []);

    return (
        <div className="team w-full">
            <div className="flex grid grid-cols-3  flex justify-between items-center gap-y-8">
                <div className="col-span-3 sm:col-span-3 md:col-span-2 lg:col-span-2">
                    <PageTitle text="My Businesses" />
                    <div className="secondary-text mt-4">You can manage your business here</div>
                </div>
            </div>
            <div className="overflow-x-scroll md:overflow-visible w-full h-full">
                <div style={{ display: 'table', tableLayout: 'fixed' }} className="w-full">
                    <div className="mt-6 md:mt-10">
                        <table className="borderless-table mt-4 w-full">
                            <thead>
                                <tr>
                                    <th className="pl-3" />
                                    <th className="px-3">Name</th>
                                    <th className="px-3">Email</th>
                                    <th className="px-3">Category</th>
                                    <th className="px-3">Free&nbsp;Order</th>
                                    <th className="px-3">User</th>
                                </tr>
                            </thead>
                            <tbody className="w-full">
                                {businesses
                                    .filter((val: any) => val.email && val.phoneNumber)
                                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                                    .map((datum: any, index: number) => (
                                        <tr className="pointer" onClick={() => tooggleBusiness(datum)} key={index}>
                                            <td className="pl-3" colSpan={1}>
                                                <div className="avatar-container">
                                                    <div className="initials">{extractFirstLetter(datum?.name)}</div>
                                                </div>
                                            </td>
                                            <td className="px-3" colSpan={1}>
                                                <div>
                                                    <div>{truncateMultilineText(datum?.name, 15)}</div>
                                                </div>
                                            </td>
                                            <td className="px-3" colSpan={1}>
                                                <div>
                                                    <div> {truncateMultilineText(datum?.email, 20)}</div>
                                                </div>
                                            </td>
                                            <td className="px-3" colSpan={1}>
                                                <div>
                                                    <div>{datum?.category}</div>
                                                </div>
                                            </td>
                                            <td className="px-3" colSpan={1}>
                                                <div>
                                                    <div>{datum?.freeOrderCount}</div>
                                                </div>
                                            </td>
                                            <td className="px-3" colSpan={1}>
                                                <div>
                                                    <div>{datum?.users.length}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {business && <BusinessForm show={!isObjectEmpty(business)} toggle={tooggleBusiness} business={business} />}
        </div>
    );
};

export default BusinessListing;
