/* eslint-disable jsx-a11y/label-has-associated-control */
import { propsToClassKey } from '@mui/styles';
import React, { forwardRef } from 'react';
import { PatternFormat } from 'react-number-format';
import './formInput.scss';

/**
 *
 * @param {*} param0
 */
const FormInput = forwardRef(
    (
        {
            errorMessage = '',
            extraClass = '',
            label = '',
            labelClass = 'label-class',
            icon = '',
            inputName,
            inputType,
            inputClass = '',
            showErrorMessage = true,
            placeholderIcon = '',
            required = false,
            ...restInputProps
        }: any,
        ref
    ) => (
        <div className={`form-group ${icon ? 'formGroupIcon' : ''} ${errorMessage ? 'formError' : ''} ${extraClass}`}>
            {label && (
                <label className={labelClass}>
                    {label} {required && <span className="error-text">*</span>}{' '}
                </label>
            )}
            {inputType === 'pattern' ? (
                <PatternFormat
                    name={inputName}
                    className={`form-input w-full border-transparent focus:border-transparent focus:ring-0 ${inputClass}`}
                    {...restInputProps}
                />
            ) : (
                <input
                    autoComplete="off"
                    ref={ref}
                    className={`form-input w-full border-transparent focus:border-transparent focus:ring-0 ${inputClass}`}
                    name={inputName}
                    type={inputType}
                    {...restInputProps}
                />
            )}
            {placeholderIcon && placeholderIcon}
            <span className="icon">{icon && icon} </span>
            {showErrorMessage && errorMessage && <span className="text-sm error-text">{errorMessage}</span>}
        </div>
    )
);

export default FormInput;
