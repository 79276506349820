import Keycloak, { KeycloakInstance } from 'keycloak-js';

const keycloakConfig = {
    url: process.env.REACT_APP_KC_URL,
    realm: process.env.REACT_APP_KC_REALM,
    clientId: process.env.REACT_APP_KC_CLI
};
// @ts-ignore
const keycloak: KeycloakInstance = new Keycloak(keycloakConfig);

export const keycloakInitConfig = {
    onLoad: 'check-sso',
    pkceMethod: 'S256',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
};

export const isSuperAdmin = () => {
    if (keycloak) {
        return ['SUPER_ADMIN'].some((r) => {
            const realm = keycloak.hasRealmRole(r);
            return realm;
        });
    }
    return false;
};

export const isBetaUser = () => {
    if (keycloak) {
        return ['BETA_USER', 'SUPER_ADMIN'].some((r) => {
            const realm = keycloak.hasRealmRole(r);
            return realm;
        });
    }
    return false;
};

export const getUserInfo = () => keycloak.tokenParsed;

export const updateToken = (successCallback: any) => keycloak.updateToken(5).then(successCallback).catch(keycloak.login);

export default keycloak;
