import Button from 'components/Button';
import React from 'react';
import toast from 'react-hot-toast';
import checkIcon from 'assets/icons/white_circle_check.svg';
import warningIcon from 'assets/icons/warning_octagon.svg';
import { AlertOctagon, AlertCircle, CircleCheck } from 'tabler-icons-react';

export const successToast = (msg = '') =>
    toast(msg, {
        icon: <CircleCheck size={25} strokeWidth={2} color="#fff" />,
        position: 'bottom-center',
        duration: 3000,
        style: {
            borderRadius: '10px',
            background: '#5838CA',
            color: '#fff'
        }
    });

export const errorToast = (msg = '') =>
    toast(msg, {
        icon: <AlertOctagon size={25} strokeWidth={2} color="#fff" />,
        position: 'bottom-center',
        duration: 3000,
        style: {
            borderRadius: '10px',
            background: '#F56464',
            color: '#fff'
        }
    });

export const warningToast = (msg = '') =>
    toast(msg, {
        icon: <AlertCircle size={25} strokeWidth={2} color="#fff" />,
        position: 'bottom-center',
        duration: 3000,
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff'
        }
    });
