/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './formtextarea.scss';

/**
 *
 * @param {*} param0
 */
const FormTextarea = ({
    errorMessage = '',
    extraClass = '',
    label = '',
    labelClass = 'label-class',
    helperText = '',
    icon = '',
    inputName,
    inputType,
    inputClass = '',
    showErrorMessage = true,
    placeholderIcon = '',
    required = false,
    ...restInputProps
}: any) => (
    <div className={`form-group ${icon ? 'formGroupIcon' : ''} ${errorMessage ? 'formError' : ''} ${extraClass}`}>
        {label && (
            <label className={labelClass}>
                {label} {required && <span className="text-danger">*</span>}{' '}
            </label>
        )}
        <textarea
            autoComplete="off"
            className={`form-textarea w-full border-transparent focus:border-transparent focus:ring-0 ${inputClass}`}
            name={inputName}
            type={inputType}
            {...restInputProps}
        />

        {placeholderIcon && placeholderIcon}
        {showErrorMessage && errorMessage && <span className="error-text text-sm text-danger">{errorMessage}</span>}
        {helperText && <div className="helper-text">{helperText}</div>}
    </div>
);

export default FormTextarea;
