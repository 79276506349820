/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
import PageTitle from 'components/PageTitle';
import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { usePaginationV3 } from 'helpers/hooks/usePagination';
import { TableSpinningLoader, XpressrunPageLoader } from 'ui-components/XpressrunLoader';
import { capitalizeFirstLetter, getDateInterval, isNotEmptyArray, truncateMultilineText } from 'libs';
import moment from 'moment';
import AppBagde from 'components/Badges';
import TableDropdown from 'components/TableDropdown';
import EventModal from './EventModal';
import { FormSelectSearch } from 'components/FormSelect';
import DateFilter from 'pages/Orders/OrderListing/DateFilter';
import AnimateElement from 'ui-components/AnimateElement';
import { useApp } from 'app-context';
import { isSuperAdmin } from 'keycloak';
import StatusFilter from 'pages/Orders/OrderListing/StatusFilter';
import DropdownSelect from 'pages/Orders/OrderListing/DropdownSelect';

const ENVS = [
    { label: 'DEV', value: 'DEV' },
    { label: 'PROD', value: 'PROD' }
];

const APIS = [
    { label: 'API', value: 'API' },
    { label: 'OPEN_API', value: 'OPEN_API' }
];

const DATES: { label: string; value: string }[] = [
    { label: 'Today', value: 'today' },
    { label: 'Last 7 days', value: 'last_week' },
    { label: 'Last Month', value: 'last_month' },
    { label: 'All Time', value: 'all' }
];

const METHODS = [
    { label: 'POST', value: 'POST' },
    { label: 'GET', value: 'GET' },
    { label: 'PUT', value: 'PUT' },
    { label: 'DELETE', value: 'DELETE' }
];

const SERVICES = [
    { label: 'BUSINESS', value: '/businesses' },
    { label: 'ESTIMATION', value: '/drivers/estimate' },
    { label: 'ORDER', value: '/order' },
    { label: 'INTEGRATION', value: '/integrations' },
    { label: 'WEBHOOKS', value: '/drivers/webhooks' },
    { label: 'PAYMENT', value: '/payment' },
    { label: 'STORE', value: '/stores' },
    { label: 'NOTIFICATION', value: '/notifications' },
    { label: 'PRODUCT', value: '/products' }
];

const Event = () => {
    const observer: any = useRef();
    const initialState = {
        paginationObj: {
            totalPages: 0,
            currentPage: 0,
            limit: 15
        },
        params: { environment: process.env.REACT_APP_ENV, status: '' },
        dayOf: 'all_time',
        selectedEvt: null
    };
    const { selectedBusiness, getBusinessInfo } = useApp() as AppContextType;
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { paginationObj, selectedEvt, params, dayOf } = state;
    const { data, hasMore, totalElements, statusCount, loading, setReload, initialLoading } = usePaginationV3(
        '/logs',
        params,
        paginationObj?.currentPage,
        15
    );

    const toogleEventModal = (evt: any) => {
        setState({
            selectedEvt: evt
        });
    };

    const tabs: any = [...statusCount.map(({ status, value }) => ({ title: status, key: status, count: value }))];

    const lastDataElementRef = useCallback(
        (node: any) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setState({
                        paginationObj: {
                            ...paginationObj,
                            currentPage: paginationObj.currentPage + 1
                        }
                    });
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const handleParamsChange = (path: any, value: any) => {
        setReload(true);
        setState({
            params: { ...params, path: params.api === 'OPEN_API' ? '' : params.path, [path]: value },
            paginationObj: {
                ...paginationObj,
                currentPage: 0
            }
        });
    };

    const onApplyStatus = (data: any) => {
        const statsData = data.join(',').trim();
        handleParamsChange('status', statsData);
    };

    const handleChangeDate = (dayOf: any) => {
        const { start, end } = getDateInterval(dayOf);
        setReload(true);
        setState({
            dayOf,
            params: { ...params, start_date: start, end_date: end },
            paginationObj: {
                ...paginationObj,
                currentPage: 0
            }
        });
    };

    useEffect(() => {
        handleParamsChange('business_id', selectedBusiness);
    }, [selectedBusiness]);

    return (
        <div>
            <div className="">
                {initialLoading ? (
                    <XpressrunPageLoader />
                ) : (
                    <div>
                        <>
                            <div className="flex grid grid-cols-3  flex justify-between items-center gap-y-6 md:gap-y-8">
                                <div className="col-span-3 sm:col-span-3 md:col-span-2 lg:col-span-2">
                                    <PageTitle text="Event Logs" />
                                </div>
                            </div>
                            <div className="flex items-center flex-wrap  gap-x-[12px] md:gap-x-[34px] gap-y-[20px] md:mb-0 mb-0 w-full mt-6 md:mt-[30px]">
                                <div className=" min-w-[200px] mt-0">
                                    <DropdownSelect
                                        isSearchable={false}
                                        placeHolder="Date"
                                        allSelectOtion={false}
                                        options={DATES}
                                        onChange={({ value }: any) => handleChangeDate(value)}
                                        value={dayOf ? DATES.find((val) => val.value === dayOf) : null}
                                    />
                                </div>
                                <div className=" min-w-[200px] mt-0">
                                    <DropdownSelect
                                        isSearchable
                                        value={params.environment ? ENVS.find((val) => val.value === params.environment) : null}
                                        onChange={({ value }: any) => handleParamsChange('environment', value)}
                                        options={ENVS}
                                        placeHHolder="Environments"
                                    />
                                </div>
                                <div className=" min-w-[200px] mt-0">
                                    <DropdownSelect
                                        isSearchable
                                        value={params.api ? APIS.find((val) => val.value === params.api) : null}
                                        onChange={({ value }: any) => handleParamsChange('api', value)}
                                        options={APIS}
                                        placeHolder="Apis"
                                    />
                                </div>
                                <div className=" min-w-[200px] mt-0">
                                    <DropdownSelect
                                        isSearchable
                                        value={params.method ? METHODS.find((val) => val.value === params.method) : null}
                                        onChange={({ value }: any) => handleParamsChange('method', value)}
                                        options={METHODS}
                                        placeHolder="Methods"
                                    />
                                </div>

                                {params.api !== 'OPEN_API' && (
                                    <div className=" min-w-[200px] mt-0">
                                        <DropdownSelect
                                            isSearchable
                                            value={params.path ? SERVICES.find((val) => val.value === params.path) : null}
                                            onChange={({ value }: any) => handleParamsChange('path', value)}
                                            options={SERVICES}
                                            placeHolder="Services"
                                        />
                                    </div>
                                )}
                                <div className=" min-w-[200px] mt-0">
                                    <StatusFilter placeholder="Status" tabs={tabs} onApply={onApplyStatus} filterParams={params?.status} />
                                </div>
                            </div>
                            {/* <div className="flex items-center flex-wrap  gap-x-[12px] md:gap-x-[34px] gap-y-[20px] md:mb-0 mb-0 w-full mt-6 md:mt-[30px]">
                                {tabs.map(({ title, key, count }: any, i: number) => (
                                    <AnimateElement
                                        key={i}
                                        className={`${
                                            params.status === key ? 'order-active-tab default-color font-semi-bold' : 'order-tab'
                                        } pointer py-[8px] px-[16px]`}
                                    >
                                        <div onClick={() => handleParamsChange('status', key)}>
                                            <div className="flex gap-x-2 font-normal items-center break-normal">
                                                <div className="no-wrap text-base">{title}</div>
                                                <div
                                                    className={`flex items-center justify-center text-[12.5px] ${
                                                        params.status === key ? 'active-badge' : 'non-active-badge'
                                                    }`}
                                                >
                                                    {count ?? 0}
                                                </div>
                                            </div>
                                        </div>
                                    </AnimateElement>
                                ))}
                            </div> */}
                            <div className="grid grid-cols-9 gap-x-14 gap-y-9">
                                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12">
                                    <div className="pb-24 md:pb-0 relative w-full overflow-y-auto md:overflow-visible">
                                        <table className="w-full text-sm text-left  mt-7 border rounded-lg">
                                            <thead className="" style={{ backgroundColor: '#F8FAFC' }}>
                                                <tr>
                                                    {isSuperAdmin() && (
                                                        <th
                                                            scope="col"
                                                            className="px-3 pl-7 py-5 whitespace-nowrap header font-semi-bold text-sm"
                                                        >
                                                            Business
                                                        </th>
                                                    )}
                                                    <th
                                                        scope="col"
                                                        className="px-3 pl-7 py-5 whitespace-nowrap header font-semi-bold text-sm"
                                                    >
                                                        Event Id
                                                    </th>
                                                    <th scope="col" className="px-3 py-5 whitespace-nowrap header font-semi-bold text-sm">
                                                        Method
                                                    </th>
                                                    <th scope="col" className="px-3 py-5 whitespace-nowrap header font-semi-bold text-sm">
                                                        Status
                                                    </th>
                                                    <th scope="col" className="px-3 py-5 whitespace-nowrap header font-semi-bold text-sm">
                                                        Path
                                                    </th>
                                                    <th scope="col" className="px-3 py-5 whitespace-nowrap header font-semi-bold text-sm">
                                                        Environment
                                                    </th>
                                                    <th scope="col" className="px-3 py-5 whitespace-nowrap header font-semi-bold text-sm" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isNotEmptyArray(data) ? (
                                                    <>
                                                        {data?.map((datum: any, index: number) => (
                                                            <tr
                                                                ref={data.length === index + 1 ? lastDataElementRef : null}
                                                                id={`${index}`}
                                                                key={`event-tr-${index}`}
                                                                className="border"
                                                            >
                                                                {isSuperAdmin() && (
                                                                    <td className="whitespace-nowrap px-3 py-4">
                                                                        <div className=" py-2 text-base font-semi-bold default-color">
                                                                            {datum?.businessId
                                                                                ? capitalizeFirstLetter(
                                                                                      truncateMultilineText(
                                                                                          getBusinessInfo(datum?.businessId)?.name,
                                                                                          20
                                                                                      )
                                                                                  )
                                                                                : 'N/A'}
                                                                        </div>
                                                                    </td>
                                                                )}

                                                                <td className="whitespace-nowrap px-3 pl-7 py-4">
                                                                    <div className="py-2 text-base font-semi-bold default-color">
                                                                        {capitalizeFirstLetter(
                                                                            truncateMultilineText(datum?.request_id, 20)
                                                                        )}
                                                                    </div>
                                                                    <div className=" -mt-1 text-xs font-extralight text-secondary">
                                                                        Created At {moment(new Date(datum?.createdAt)).fromNow()}
                                                                    </div>
                                                                </td>
                                                                <td className="whitespace-nowrap px-3 py-4">
                                                                    <div className=" py-2 text-base font-semi-bold default-color">
                                                                        {datum?.requestMethod}
                                                                    </div>
                                                                    <div className=" -mt-1 text-xs font-extralight text-secondary">
                                                                        Duration {datum.duration} s
                                                                    </div>
                                                                </td>
                                                                <td className=" whitespace-nowrap px-3 py-4">
                                                                    <div className=" py-2 text-base text-left">
                                                                        <AppBagde
                                                                            type=""
                                                                            text={`${datum?.responseStatusCode}`}
                                                                            className="p-1.5 px-5 font-semi-bold text-xs "
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="whitespace-nowrap px-3 py-4">
                                                                    <div className=" py-2 text-base font-semi-bold default-color">
                                                                        {truncateMultilineText(datum?.requestPath, 30)}
                                                                    </div>
                                                                </td>
                                                                <td className="whitespace-nowrap px-3 py-4">
                                                                    <div className=" py-2 text-base font-semi-bold default-color">
                                                                        {datum?.environment}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className="whitespace-nowrap px-3 py-4"
                                                                    onClick={(e: any) => e.stopPropagation()}
                                                                >
                                                                    <div className="py-2 text-base default-color">
                                                                        <TableDropdown
                                                                            actions={[
                                                                                {
                                                                                    text: 'show event',
                                                                                    action: () => toogleEventModal(datum)
                                                                                }
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        {!loading && (
                                                            <tr className="border">
                                                                <td colSpan={4} className="text-center py-5">
                                                                    No data found
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                )}
                                                {loading && (
                                                    <tr className="border-0">
                                                        <td colSpan={4} className="text-center py-5">
                                                            <div className="w-full flex justify-center">
                                                                <TableSpinningLoader />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                )}
            </div>
            {selectedEvt && <EventModal requestInfo={selectedEvt} show={selectedEvt} toogle={() => toogleEventModal(null)} />}
        </div>
    );
};

export default Event;
