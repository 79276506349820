import React from 'react';
import './loadable.scss';
import LinearProgress from '@mui/material/LinearProgress';

// ===========================|| Loader ||=========================== //

const Loader = () => {
    // @ts-ignore
    const classes = null;

    return (
        <div className="">
            <LinearProgress color="primary" />
        </div>
    );
};

export const LoaderBullets = () => (
    <div className="loader">
        <div className="bullet-container">
            <div className="bullet">&nbsp;</div>
            <div className="bullet">&nbsp;</div>
            <div className="bullet">&nbsp;</div>
        </div>
    </div>
);

export default Loader;
