import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import './dashboard-searchbar.scss';
import searchIcon from 'assets/icons/search.svg';
import boxIcon from 'assets/icons/order_icons/boxtick.svg';
import AnimateElement from 'ui-components/AnimateElement';
import { OrderHttpService } from 'http-service/Order-http-service';
import { ApiEndpoints } from 'constants/apiRoutes';
import usePagination, { useSearchBarPagination } from 'helpers/hooks/usePagination';
import { isNotEmptyArray } from 'libs';
import { TableSpinningLoader } from 'ui-components/XpressrunLoader';
import { Link } from 'react-router-dom';

const DashboardSearchBar = (props: any): JSX.Element => {
    const { text, children, secondaryText, className, placeholder, inputClassName = '', parentClass = '' } = props;
    const observer: any = useRef();
    const ref: any = useRef();
    const initialState = {
        paginationObj: {
            totalPages: 0,
            currentPage: 1,
            limit: 7
        },
        searchTerm: '',
        componentLoading: false
    };

    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { paginationObj, searchTerm, componentLoading } = state;

    const { data, hasMore, loading, error, initialLoading, setGeneralState } = useSearchBarPagination(
        ApiEndpoints.ORDER,
        searchTerm,
        paginationObj?.currentPage,
        'query',
        7
    );

    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setState({
            searchTerm: value,
            paginationObj: { ...paginationObj, currentPage: 1 }
        });
    };
    const lastDataElementRef = useCallback(
        (node: any) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setState({
                        paginationObj: {
                            ...paginationObj,
                            currentPage: paginationObj.currentPage + 1
                        }
                    });
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );
    const renderResult = () => {
        if (!initialLoading && searchTerm) return true;
        return false;
    };

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setGeneralState();
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        setState({
            componentLoading: false
        });
        // Cleanup method
        return () => {
            setState({
                ...initialState
            });
        };
    }, []);
    return (
        <div className="dashboard-search-bar relative" ref={ref}>
            <div className="wrap">
                {/* @ts-ignore */}
                <div className={`search ${className} ${inputClassName}`}>
                    <button type="submit" className={`searchButton ${inputClassName}`}>
                        <img src={searchIcon} alt="search" />
                    </button>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className={`searchTerm border-transparent focus:border-transparent focus:ring-0 ${inputClassName}`}
                        placeholder={placeholder || 'Search for deliveries, transactions'}
                    />
                </div>
            </div>
            {renderResult() && (
                <div className="absolute w-full bg-white z-[10000] max-h-[40vh]  overflow-y-scroll search-result-container">
                    {data?.map((datum: any, _i: number) => (
                        <Link to={`orders/${datum?.id}`} key={`search_${_i}`} onClick={() => setGeneralState()}>
                            <div
                                className="flex gap-x-[14px] items-center px-[20px] py-[15px] border-b"
                                ref={data.length === _i + 1 ? lastDataElementRef : null}
                            >
                                <div>
                                    <img src={boxIcon} alt="" />
                                </div>
                                <div>
                                    <div className="text-[16px]">{datum?.dropoff_information?.address?.address_name}</div>
                                    <div className="text-[14px] text-[#9FACBC] font-semi-bold">
                                        {datum?.dropoff_information?.receiver?.name}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                    {loading && (
                        <div className="w-full flex justify-center">
                            <TableSpinningLoader />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DashboardSearchBar;
