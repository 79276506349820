/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useReducer, useRef, Fragment } from 'react';
import AnimateElement from 'ui-components/AnimateElement';
import './track_delivery.scss';
import purplePhoneIcon from 'assets/icons/purple_phone.svg';
import moment from 'moment';
import { ChevronDown, DotsVertical } from 'tabler-icons-react';
import CustomMap from 'components/Maps';
import { capitalizeFirstLetter, formatCurrency, getTimeZone, isObjectEmpty } from 'libs';
import customLogo from 'assets/icons/custom_logo.svg';
import { Menu, Transition } from '@headlessui/react';
import cancelIcon from 'assets/icons/x_circle.svg';
// Ad icons
import freeDeliveryIcon from 'assets/icons/advert_icons/free_delivery_box.svg';
import discountIcon from 'assets/icons/advert_icons/discount.svg';
import couponIcon from 'assets/icons/advert_icons/coupon.svg';
import salesIcon from 'assets/icons/advert_icons/sales.svg';
import mailingIcon from 'assets/icons/advert_icons/mailing_list.svg';
import generalTemplateIcon from 'assets/icons/advert_icons/general_template.svg';
import { BusinessHttpService } from 'http-service/Business-http-service';

const defaultAppPurple = '#6B55B9';
const inactiveGrey = '#9FACBC';
const iconMapping: any = {
    DEFAULT: discountIcon,
    FREE_DELIVERY: freeDeliveryIcon,
    DISCOUNTS: discountIcon,
    COUPON: couponIcon,
    JOIN_MAILING_LIST: mailingIcon,
    SALES: salesIcon
};

const MobileTrackingView = (props: any) => {
    const {
        orderDetails,
        deliveryDistance,
        orderTimeLinePayload,
        trackingConfig,
        driverInfo,
        driverPosition,
        pickupCoordinate,
        dropOffCoordinate,
        handleCallDriver,
        statusString,
        etaObj,
        abObject
    } = props;
    const cardRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const initialState = {
        showDeliveryInfo: false,
        showMapView: true,
        orderTimeLinePayload: [],
        deliveryProgress: 0,
        showAd: true,
        numberCopied: false
    };

    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { showDeliveryInfo, showMapView, deliveryProgress, showAd, numberCopied } = state;

    const handleDeliveryInfoExpansion = () => {
        setState({
            showDeliveryInfo: !showDeliveryInfo
        });
    };
    const handleCopyDriverNumber = async () => {
        await navigator.clipboard.writeText(driverInfo?.phone_number);
        setState({
            numberCopied: true
        });
    };
    const toggleMapView = () => {
        setState({
            showMapView: !showMapView
        });
    };

    const handleUrlClick = async () => {
        try {
            new BusinessHttpService().persistWithUrl(`public/${orderDetails?.business_id}/${abObject?.id}/click/advertising`, {
                id: abObject?.id
            });
        } catch (error) {
            setState({
                ...state
            });
        }
    };

    useEffect(() => {
        if (!showDeliveryInfo) {
            if (cardRef.current) cardRef.current.style.height = `${contentRef.current.getBoundingClientRect().height}px`;
        } else if (cardRef.current) cardRef.current.style.height = '0px';
    }, [showDeliveryInfo]);

    const showDeliveryDiv = (label: string, text: string, textClass = '') => (
        <div>
            <div className="flex items-center justify-between">
                <div className="font-semi-bold">{label}</div>
                <div className={textClass}>{text}</div>
            </div>
        </div>
    );

    const getDeliveryPercentage = () => {
        let progress = 0;
        const incrementNumber = 100 / orderTimeLinePayload.length;
        for (let index = 0; index < orderTimeLinePayload.length; index++) {
            const element = orderTimeLinePayload[index];
            if (element.status) progress += incrementNumber;
        }
        setState({
            deliveryProgress: progress
        });
    };
    const menuDropDown = () => {
        return (
            <Menu as="div" className="relative ">
                <div className="w-full">
                    <Menu.Button className="flex gap-x-1 items-center w-full">
                        <DotsVertical size={28} strokeWidth={2} color={'white' || trackingConfig?.colorPrim} />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        static
                        className="absolute right-[25px] top-[4px] z-10 mt-2 w-[200px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        <Menu.Item>
                            {({ active }) => (
                                <div className="py-2 px-3" onClick={() => handleDeliveryInfoExpansion()}>
                                    {showDeliveryInfo ? 'Close delivery details' : 'Show delivery details'}
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <div className="py-2 px-3" onClick={() => toggleMapView()}>
                                    {showMapView ? 'Show delivery timeline' : 'Show delivery map'}
                                </div>
                            )}
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        );
    };

    const renderStatusHeader = () => {
        switch (statusString) {
            case 'CREATED':
            case 'CONFIRMED':
                return (
                    <>
                        <div className="text-center font-semi-bold">
                            Estimated pick-up time is
                            {etaObj?.order_pickup ? ` ${moment(etaObj.order_pickup).format('h:mm A')} ${getTimeZone()}` : ''}
                        </div>
                        <div className="text-center xpressrun-grey font-normal text-sm mt-2.5">Order waiting to be picked-up</div>
                    </>
                );
            case 'PICKED_UP':
                return (
                    <>
                        <div className="text-center font-semi-bold">
                            Estimated delivery time is
                            {etaObj?.order_delivery ? ` ${moment(etaObj.order_delivery).format('h:mm A')} ${getTimeZone()}` : ''}
                        </div>
                        <div className="text-center xpressrun-grey font-normal text-sm mt-2.5">Your order has been picked-up</div>
                    </>
                );
            case 'DELIVERED':
                return (
                    <>
                        <div className="text-center font-semi-bold">
                            Your order has been delivered successfully
                            {etaObj?.order_delivery ? ` ${moment(etaObj.order_delivery).format('h:mm A')} ${getTimeZone()}` : ''}
                        </div>
                        <div className="text-center xpressrun-grey font-normal text-sm mt-2.5">Delivery successful</div>
                    </>
                );
            case 'CANCELLED':
                return <div className="text-center font-semi-bold">Your Order has been cancelled</div>;
            default:
                return (
                    <>
                        <div className="text-center font-semi-bold">
                            Estimated delivery time is
                            {etaObj?.order_delivery ? ` ${moment(etaObj.order_delivery).format('h:mm A')} ${getTimeZone()}` : ''}
                        </div>
                        <div className="text-center xpressrun-grey font-normal text-sm mt-2.5">Your order has been picked up</div>
                    </>
                );
        }
    };
    const removeAd = () => {
        setState({
            showAd: !showAd
        });
    };
    const renderAdvert = () => {
        switch (abObject.layout) {
            case 'BOTTOM_BAR':
            case 'TOP_BAR':
                return (
                    <div className="w-full border">
                        <div className="flex w-full justify-center relative">
                            <div className=" bg-white w-full px-2 flex justify-between py-1 items-center mt-4">
                                <AnimateElement className="absolute  pointer top-[-30px] right-[-10px]">
                                    <div className="w-full h-full" onClick={() => removeAd()}>
                                        <img src={cancelIcon} alt="" width={75} />
                                    </div>
                                </AnimateElement>

                                <div className="font-lora flex items-center">
                                    <div className="">
                                        <img src={iconMapping[abObject?.selectedAd]} alt="mailingIcon" className="w-[60.94px]" />
                                    </div>
                                </div>
                                <div className="text-xxs w-[185.59px]">
                                    <div className="font-semi-bold text-lg leading-6">{abObject?.heading}</div>
                                    {abObject?.body}
                                </div>
                                <a href={abObject?.ctaUrl} onClick={() => handleUrlClick()} target="_blank" rel="noreferrer">
                                    <AnimateElement className="flex pointer black-3-border">
                                        <div
                                            className="text-white maroon-bg font-semi-bold text-xxs px-3.5 py-3"
                                            style={{
                                                backgroundColor: abObject?.btnColor,
                                                color: abObject?.btnTextColor
                                            }}
                                        >
                                            {abObject?.btnText}
                                        </div>
                                    </AnimateElement>
                                </a>
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="bg-white w-[350.25px] text-center rounded">
                        <AnimateElement className="absolute  pointer top-[-30px] right-[-35px]">
                            <div className="w-full h-full" onClick={() => removeAd()}>
                                <img src={cancelIcon} alt="" width={75} />
                            </div>
                        </AnimateElement>
                        <div className="w-[130.7px] h-[100px] mx-auto mt-3">
                            <img src={iconMapping[abObject?.selectedAd]} alt="mailingIcon" className="w-full" />
                        </div>
                        <div className="pt-6 text-lg font-extra-bold">{abObject?.heading}</div>
                        <div className="pt-4   w-[251px] mx-auto font-normal text-center">{abObject?.body}</div>
                        <div className="pt-5 pb-10  px-10 flex justify-center">
                            <a href={abObject?.ctaUrl} onClick={() => handleUrlClick()} target="_blank" rel="noreferrer">
                                <AnimateElement className="flex pointer black-3-border rounded">
                                    <div
                                        className="px-10 font-semi-bold  py-3"
                                        style={{
                                            backgroundColor: abObject?.btnColor,
                                            color: abObject?.btnTextColor
                                        }}
                                    >
                                        {abObject?.btnText}
                                    </div>
                                </AnimateElement>
                            </a>
                        </div>
                    </div>
                );
        }
    };

    useEffect(() => {
        getDeliveryPercentage();
        // Cleanup method
        return () => {
            setState({
                ...initialState
            });
        };
    }, [orderTimeLinePayload, driverPosition]);

    return (
        <div className="p-0 m-0 max-h-screen min-h-screen mobile-tracking overflow-y-auto relative">
            <div
                className=" fixed top-0 z-50 w-full "
                style={{
                    backgroundColor: trackingConfig?.colorBack || defaultAppPurple
                }}
            >
                <div className="flex justify-between items-center w-full px-3 py-3">
                    <div className="flex items-center gap-x-3">
                        <img src={trackingConfig.imgUrl || customLogo} width={42} alt="Logo" />
                        {/* <div className="font-semi-bold text-lg">{capitalizeFirstLetter(trackingConfig?.brandName)}</div> */}
                    </div>
                    <div className="pointer">{menuDropDown()}</div>
                </div>
                {showDeliveryInfo && (
                    <div className="bg-white relative">
                        <AnimateElement className="absolute  pointer top-[-5px] right-[-13px]">
                            <div className="w-full h-full" onClick={() => handleDeliveryInfoExpansion()}>
                                <img src={cancelIcon} alt="" width={75} />
                            </div>
                        </AnimateElement>
                        <div className="show">
                            <div className="px-3 ">
                                <div className="py-5 show">
                                    <div className="mt-8">{showDeliveryDiv('OrderId', `${orderDetails?.id}`)}</div>
                                    <div className="mt-5">{showDeliveryDiv('Provider', orderDetails?.provider)}</div>
                                    <div className="mt-5">{showDeliveryDiv('Dimensions', '--')}</div>
                                    <div className="mt-5">{showDeliveryDiv('Weight', '--')}</div>
                                    <div className="mt-5">{showDeliveryDiv('Distance', deliveryDistance)}</div>
                                    <div className="mt-5">
                                        {showDeliveryDiv('Price', `${formatCurrency(orderDetails?.price, true)}`, 'font-bold')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showMapView ? (
                <div>
                    <div className="map-area mt-12">
                        <CustomMap
                            driverPoint={statusString === 'DELIVERED' ? {} : driverPosition}
                            originPoint={pickupCoordinate}
                            destPoint={dropOffCoordinate}
                            height="100%"
                            isTracking
                        />
                    </div>
                    <div className="px-4 pt-4">
                        <div className="text-center font-semi-bold">{renderStatusHeader()}</div>
                        <div className="mt-5 py-6 px-4 border-b border-t ">
                            <div className="flex  justify-between mobile-order-process oveflow-x-hidden ">
                                {/* Replaces pseduo elements */}
                                <div
                                    className="mobile-order-process-before absolute content-[' '] w-full h-[6px] top-[39%] z-[-10]"
                                    style={{ backgroundColor: ` ${inactiveGrey}` }}
                                >
                                    <div
                                        className="h-full z-10"
                                        style={{
                                            backgroundColor: trackingConfig?.colorBack || defaultAppPurple,
                                            width: `${deliveryProgress}%`
                                        }}
                                    />
                                </div>
                                {orderTimeLinePayload.map((orderTimeLine: any, _i: number) => {
                                    const parentBorderColor = orderTimeLine?.status
                                        ? `${trackingConfig?.colorBack || defaultAppPurple}`
                                        : inactiveGrey;

                                    return (
                                        <div
                                            className="flex items-center justify-center icon-container relative w-[25%]"
                                            style={{
                                                backgroundColor: parentBorderColor
                                            }}
                                        >
                                            {orderTimeLine?.svgIcon ? (
                                                <>{orderTimeLine?.icon}</>
                                            ) : (
                                                <img src={orderTimeLine?.icon} alt="Order confirmation" />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="p-0 m-0 relative timeline-area">
                    <div className="px-6 pl-20 mt-20">
                        <div className="tracking-timeline track">
                            {orderTimeLinePayload.map((orderTimeLine: any, _i: number) => {
                                const parentBorderColor = orderTimeLine?.status
                                    ? `${trackingConfig?.colorBack || defaultAppPurple}`
                                    : inactiveGrey;
                                return (
                                    <>
                                        <div className="parent-time-line py-4 pb-8 ml-4 relative">
                                            {/* Replaces pseduo element */}
                                            <div
                                                className="parent-time-line-before absolute content-[' '] top-[0px] left-[-16px] w-[1px] h-full"
                                                style={{
                                                    border: `1.2px solid  ${parentBorderColor || defaultAppPurple}`
                                                }}
                                            />
                                            <div
                                                className="parent-time-line-icon flex justify-center items-center"
                                                style={{
                                                    backgroundColor: parentBorderColor
                                                }}
                                            >
                                                {orderTimeLine?.svgIcon ? (
                                                    <>{orderTimeLine?.icon}</>
                                                ) : (
                                                    <img src={orderTimeLine?.icon} alt="Timeline icon" />
                                                )}
                                            </div>
                                            <div className="time-line-date absolute text-sm">
                                                {orderTimeLine?.occurred_at ? moment(orderTimeLine?.occurred_at).format('h:mm a') : ''}
                                            </div>
                                            <div className="flex justify-between items-center ml-4">
                                                <div className="text">{orderTimeLine?.name}</div>
                                                <div className="eta default-color text-xs">
                                                    {etaObj[orderTimeLine.key] && (
                                                        <>
                                                            <span className="font-bold">ETA </span>
                                                            {moment(etaObj[orderTimeLine.key]).format('h:mm a')}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {orderTimeLine?.stages.map((stage: any, index: number) => {
                                            const childBorderColor = stage?.completed
                                                ? `${trackingConfig?.colorBack || defaultAppPurple}`
                                                : inactiveGrey;
                                            return (
                                                <div className="child-time-line py-3 pb-6 ml-4 relative">
                                                    {/* Replaces pseduo element */}
                                                    <div
                                                        className="child-time-line-before content-[' '] absolute top-[0px] left-[-16px] w-[1px] h-full"
                                                        style={{
                                                            border: `1.2px solid  ${childBorderColor || defaultAppPurple}`
                                                        }}
                                                    />
                                                    <span
                                                        className={`child-timeline-icon ${
                                                            stage?.completed ? 'default-bg' : 'xpressrun-grey-bg'
                                                        }
                                                        `}
                                                        style={{
                                                            backgroundColor: childBorderColor || defaultAppPurple
                                                        }}
                                                    />
                                                    <div className="time-line-date child-time-line-date absolute text-sm">
                                                        {stage?.occurred_at ? moment(stage?.occurred_at).format('h:mm a') : ''}
                                                    </div>
                                                    <div className="flex justify-between items-center ml-4">
                                                        <div className="text-sm text">{stage?.text}</div>
                                                        {/* <div>ETA 12:20</div> */}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}

            <div className=" py-6 px-8 ">
                {driverInfo?.first_name && (
                    <div className="flex justify-between items-center border-b pb-6">
                        <div>
                            <div>{driverInfo?.first_name}</div>
                            <div className="xpressrun-grey font-normal text-sm mt-1.5">Driver</div>
                        </div>
                        <div>
                            <div className="pointer flex items-center justify-center" onClick={() => handleCallDriver()}>
                                <AnimateElement className="pointer shadow-lg phone-icon-container flex items-center justify-center">
                                    <div className=" flex items-center justify-center w-full h-full">
                                        <img src={purplePhoneIcon} alt="Phone" />
                                    </div>
                                </AnimateElement>
                            </div>
                            <div className="mt-3 xpressrun-grey" onClick={() => handleCopyDriverNumber()}>
                                <AnimateElement className="w-full h-full flex items-center justify-center pointer text-sm">
                                    {`${numberCopied ? "Driver's number copied!" : "Copy driver's number"}`}
                                </AnimateElement>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showAd && <> {!isObjectEmpty(abObject) && <div className={` w-full px-3`}>{renderAdvert()}</div>}</>}
        </div>
    );
};

export default MobileTrackingView;
