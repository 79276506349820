/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/react-in-jsx-scope */
import { forwardRef, Fragment, useEffect, useState } from 'react';
import './formselect.scss';

import Select from 'react-select';
import { capitalizeFirstLetter } from 'libs';
/**
 *
 * @param {*} param0
 */
const FormSelect = forwardRef(
    (
        {
            errorMessage = '',
            extraClass = '',
            label = '',
            labelClass = 'label-class',
            icon = '',
            selectName,
            placeholder,
            selectClass = '',
            search = false,
            showErrorMessage = true,
            required = false,
            name = '',
            onChange,
            value = '',
            defaultValue = '',
            options = [],
            ...restInputProps
        }: any,
        ref
    ) => (
        <div className={`form-select ${icon ? 'formGroupIcon' : ''} ${errorMessage ? 'formError' : ''} ${extraClass}`}>
            {label && (
                <label className={labelClass}>
                    {label} {required && <span className="error-text">*</span>}{' '}
                </label>
            )}

            <Select
                className={`w-full border-transparent focus:border-transparent focus:ring-0 ${selectClass}`}
                name={selectName}
                options={options}
                styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                defaultValue={
                    defaultValue || value
                        ? {
                              label: capitalizeFirstLetter(defaultValue || value).replaceAll('_', ' '),
                              value: defaultValue || value
                          }
                        : null
                }
                placeholder={placeholder}
                onChange={(e: any) => onChange(e, name)}
                {...restInputProps}
            />
            {showErrorMessage && errorMessage && <span className="text-sm error-text">{errorMessage}</span>}
        </div>
    )
);

export const FormSelectSearch = ({ className, value, addAll = true, options, ...restInputProps }: any) => {
    const [_options, setOptions] = useState([...options]);

    useEffect(() => {
        if (addAll) {
            setOptions([{ label: 'All', value: '' }, ...options]);
        }
    }, [addAll]);

    return <Select options={_options} defaultValue={value} {...restInputProps} />;
};

export default FormSelect;
