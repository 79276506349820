/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable arrow-body-style */
import React from 'react';
import './trackingpagelayout.scss';
import { Outlet } from 'react-router-dom';

const TrackingPageLayout = (props: any) => {
    return (
        <div>
            <Outlet />
        </div>
    );
};
export default TrackingPageLayout;
