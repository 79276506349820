/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-return-assign */
import { useEffect, useState } from 'react';
import axios, { Canceler } from 'axios';
import httpCommon from 'http-service/Http-common';
// For Next js
export default function usePagination(url: string, query: string | null, pageNumber: any, searchTermKey: any, limit = 15) {
    const [loading, setLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData]: any = useState([]);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        setData([]);
    }, [query]);

    useEffect(() => {
        setLoading(true);
        setError(false);
        if (reload) {
            setData([]);
        }
        const constructParam: any = {
            limit
        };
        if (query) constructParam[searchTermKey] = query;
        if (pageNumber) constructParam.page = pageNumber;
        let cancel: Canceler;
        httpCommon({
            method: 'GET',
            url,
            params: constructParam,
            cancelToken: new axios.CancelToken((c) => (cancel = c))
        })
            .then((res: any) => {
                if (res) {
                    const resultData = res?.data?.data?.data || res?.data?.data;
                    const totalPages = res?.data?.data?.totalPages || res?.data?.totalPages;
                    if (reload) {
                        setData(resultData);
                        setReload(false);
                    } else if (query) setData(resultData);
                    else setData([...data, ...resultData]);
                    setHasMore(totalPages !== pageNumber);
                    setLoading(false);
                    setInitialLoading(false);
                }
            })
            .catch((e) => {
                if (axios.isCancel(e)) return;
                setError(true);
            });
        return () => cancel();
    }, [query, pageNumber, reload]);
    return { loading, error, data, hasMore, initialLoading, setReload, setData };
}

// For spring boot
export function usePaginationV2(url: string, query: string | null, pageNumber: any, searchTermKey: any, limit = 15) {
    const [loading, setLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData]: any = useState([]);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        setData([]);
    }, [query]);

    useEffect(() => {
        setLoading(true);
        setError(false);
        if (reload) {
            setData([]);
        }
        const constructParam: any = {
            limit
        };
        if (query) constructParam.query = query;
        if (pageNumber !== null) constructParam.page = pageNumber;
        let cancel: Canceler;
        httpCommon({
            method: 'GET',
            url,
            params: constructParam,
            cancelToken: new axios.CancelToken((c) => (cancel = c))
        })
            .then((res: any) => {
                if (res) {
                    const resultData = res?.data?.data?.content || res?.data?.data;
                    if (reload) {
                        setData(resultData);
                        setReload(false);
                    } else {
                        setData([...data, ...resultData]);
                    }
                    setHasMore(!res?.data?.data?.last);
                    setLoading(false);
                    setInitialLoading(false);
                }
            })
            .catch((e) => {
                if (axios.isCancel(e)) return;
                setError(true);
            });
        return () => cancel();
    }, [query, pageNumber]);
    return { loading, error, data, hasMore, initialLoading, setReload, setData };
}

// For spring boot with params
export function usePaginationV3(url: string, query: Object | null, pageNumber: any, limit = 15) {
    const [loading, setLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [statusCount, setStatusCount] = useState([]);
    const [error, setError] = useState(false);
    const [data, setData]: any = useState([]);
    const [totalElements, setTotalElements]: any = useState([]);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        setData([]);
    }, [query]);

    useEffect(() => {
        setLoading(true);
        setError(false);
        if (reload) {
            setData([]);
        }
        const constructParam: any = {
            limit,
            ...query
        };
        if (pageNumber !== null) constructParam.page = pageNumber;
        let cancel: Canceler;
        httpCommon({
            method: 'GET',
            url,
            params: constructParam,
            cancelToken: new axios.CancelToken((c) => (cancel = c))
        })
            .then((res: any) => {
                if (res) {
                    const _data = res?.data?.data;
                    if (reload) {
                        setData(_data?.data);
                        setReload(false);
                    } else {
                        setData([...data, ..._data?.data]);
                    }
                    setHasMore(_data?.metadata.hasNext);
                    setLoading(false);
                    setStatusCount(_data.metadata.responseStatusCode);
                    setTotalElements(_data.metadata.total_logs);
                    setInitialLoading(false);
                }
            })
            .catch((e) => {
                if (axios.isCancel(e)) return;
                setError(true);
            });
        return () => cancel();
    }, [query, pageNumber]);
    return { loading, error, data, totalElements, statusCount, hasMore, initialLoading, setReload, setData };
}

export function useSearchBarPagination(url: string, query: string | null, pageNumber: any, searchTermKey: any, limit = 15) {
    const [loading, setLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData]: any = useState([]);
    const [hasMore, setHasMore] = useState(false);

    const setGeneralState = () => {
        setLoading(false);
        setInitialLoading(true);
        setReload(false);
        setError(false);
        setData([]);
        setHasMore(false);
    };
    useEffect(() => {
        setData([]);
    }, [query]);

    useEffect(() => {
        setLoading(true);
        setError(false);
        if (reload) {
            setData([]);
        }
        const constructParam: any = {
            limit
        };
        if (query) constructParam[searchTermKey] = query.trim();
        if (pageNumber) constructParam.page = pageNumber;
        let cancel: Canceler;
        httpCommon({
            method: 'GET',
            url,
            params: constructParam,
            cancelToken: new axios.CancelToken((c) => (cancel = c))
        })
            .then((res: any) => {
                if (res) {
                    const resultData = res?.data?.data?.data || res?.data?.data;
                    const totalResults = res?.data?.data?.metadata?.total_orders || res?.data?.metadata?.total_orders;
                    if (reload) {
                        setData(resultData);
                        setReload(false);
                    } else if (query) setData(resultData);
                    else {
                        setData([...data, ...resultData]);
                    }
                    setHasMore(Math.ceil((totalResults ?? 0) / 7) !== pageNumber);
                    setLoading(false);
                    setInitialLoading(false);
                }
            })
            .catch((e) => {
                if (axios.isCancel(e)) return;
                setError(true);
            });
        return () => cancel();
    }, [query, pageNumber]);
    return { loading, error, data, hasMore, initialLoading, setReload, setGeneralState };
}

export function useSearchProductPagination(url: string, query: string | null, pageNumber: any, searchTermKey: any, limit = 15) {
    const [loading, setLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData]: any = useState([]);
    const [hasMore, setHasMore] = useState(false);

    const setGeneralState = () => {
        setLoading(false);
        setInitialLoading(true);
        setReload(false);
        setError(false);
        setData([]);
        setHasMore(false);
    };
    useEffect(() => {
        setData([]);
    }, [query]);

    useEffect(() => {
        setLoading(true);
        setError(false);
        if (reload) {
            setData([]);
        }
        const constructParam: any = {
            limit
        };
        if (query) constructParam[searchTermKey] = query.trim();
        if (pageNumber) constructParam.page = pageNumber;
        let cancel: Canceler;
        httpCommon({
            method: 'GET',
            url,
            params: constructParam,
            cancelToken: new axios.CancelToken((c) => (cancel = c))
        })
            .then((res: any) => {
                if (res) {
                    const resultData = res?.data?.data?.data || res?.data?.data;
                    const totalResults = res?.data?.data?.metadata?.total_products || res?.data?.metadata?.total_products;
                    if (reload) {
                        setData(resultData);
                        setReload(false);
                    } else if (query) setData(resultData);
                    else {
                        setData([...data, ...resultData]);
                    }
                    setHasMore(Math.ceil((totalResults ?? 0) / limit) !== pageNumber);
                    setLoading(false);
                    setInitialLoading(false);
                }
            })
            .catch((e) => {
                if (axios.isCancel(e)) return;
                setError(true);
            });
        return () => cancel();
    }, [query, pageNumber]);
    return { loading, error, data, hasMore, initialLoading, setReload, setGeneralState };
}
