/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './tabledropdown.scss';
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

// @ts-ignore
const TableDropdown = ({ actions }): JSX.Element => {
    const initialState = {};
    return (
        <Menu as="div" className="relative inline-block text-left ">
            <div>
                <Menu.Button className="rounded-md border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-700  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    static
                    className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none top-0"
                >
                    <div className="py-1">
                        {actions.map((action: any, _i: number) => (
                            <Menu.Item key={_i}>
                                {({ active }) => (
                                    <div>
                                        {action?.type === 'link' ? (
                                            <Link
                                                to={action?.path}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm table-menu'
                                                )}
                                            >
                                                {action.text}
                                            </Link>
                                        ) : (
                                            <div
                                                onClick={action?.action}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm table-menu pointer'
                                                )}
                                            >
                                                {action.text}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default TableDropdown;
