/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useReducer, useRef } from 'react';
import { ChevronDown, XboxX } from 'tabler-icons-react';
import AnimateElement from 'ui-components/AnimateElement';
import './track_delivery.scss';
import orderConfirmationIcon from 'assets/icons/new_tracking_page_icons/order_confirmation.svg';
import orderDeliveryIcon from 'assets/icons/new_tracking_page_icons/order_delivery.svg';
import orderDropOffIcon from 'assets/icons/new_tracking_page_icons/order_drop_off.svg';
import orderPickUpIcon from 'assets/icons/new_tracking_page_icons/order_pickup.svg';
import headerOrderPickUpIcon from 'assets/icons/new_tracking_page_icons/header_order_pickup.svg';
import headerOrderDeliveredIcon from 'assets/icons/new_tracking_page_icons/tracking_delivered_order.svg';
import moment from 'moment';
import MobileTrackingView from './MobileTrackingView';
import CustomMap from 'components/Maps';
import { OrderHttpService } from 'http-service/Order-http-service';
import { DriverHttpService } from 'http-service/Driver-http-service';
import { useParams } from 'react-router-dom';
import { BusinessHttpService } from 'http-service/Business-http-service';
import { convertDistance, getDistance } from 'geolib';
import avatarImage from 'assets/icons/rider_avatar.svg';
import { HUB_URL } from 'constants/apiRoutes';
import XpressrunLoader from 'ui-components/XpressrunLoader';
import { capitalizeFirstLetter, formatCurrency, getTimeZone, isNotEmptyArray, isObjectEmpty } from 'libs';
import customLogo from 'assets/icons/custom_logo.svg';
import greenPhone from 'assets/icons/new_tracking_page_icons/green_phone.svg';
import { ReactComponent as IconFacebook } from 'assets/icons//new_tracking_page_icons/facebook.svg';
import { ReactComponent as IconInstagram } from 'assets/icons//new_tracking_page_icons/instagram.svg';
import { ReactComponent as IconTiktok } from 'assets/icons//new_tracking_page_icons/tiktok.svg';
import { ReactComponent as IconTwitter } from 'assets/icons//new_tracking_page_icons/twitter.svg';
import cancelIcon from 'assets/icons/x_circle.svg';
// Ad icons
import freeDeliveryIcon from 'assets/icons/advert_icons/free_delivery_box.svg';
import discountIcon from 'assets/icons/advert_icons/discount.svg';
import couponIcon from 'assets/icons/advert_icons/coupon.svg';
import salesIcon from 'assets/icons/advert_icons/sales.svg';
import mailingIcon from 'assets/icons/advert_icons/mailing_list.svg';
import RatingsModal from './RatingsModal';

const defaultAppPurple = '#6B55B9';
const inactiveGrey = '#9FACBC';

const iconMapping: any = {
    DEFAULT: discountIcon,
    FREE_DELIVERY: freeDeliveryIcon,
    DISCOUNTS: discountIcon,
    COUPON: couponIcon,
    JOIN_MAILING_LIST: mailingIcon,
    SALES: salesIcon
};

const RenderAdvertAndSocials = (props: any) => {
    const { abObject, trackingConfig, removeAd, showAd, businessId } = props;
    const socialLinks = [
        {
            url: trackingConfig.fbUrl,
            icon: (
                <IconFacebook
                    fill={trackingConfig?.colorTitle || defaultAppPurple}
                    stroke={trackingConfig?.colorBack || defaultAppPurple}
                />
            )
        },
        {
            url: trackingConfig.instaUrl,
            icon: (
                <IconInstagram
                    fill={trackingConfig?.colorTitle || defaultAppPurple}
                    stroke={trackingConfig?.colorBack || defaultAppPurple}
                />
            )
        },
        {
            url: trackingConfig.twtUrl,
            icon: (
                <IconTwitter fill={trackingConfig?.colorTitle || defaultAppPurple} stroke={trackingConfig?.colorBack || defaultAppPurple} />
            )
        },
        {
            url: trackingConfig.tiktok_link,
            icon: <IconTiktok fill={trackingConfig?.colorBack || defaultAppPurple} stroke={trackingConfig?.colorBack || defaultAppPurple} />
        }
    ];
    const initialState = {
        adClasses: '',
        socialLinksClasses: ''
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { adClasses, socialLinksClasses } = state;
    const handleUrlClick = async () => {
        try {
            new BusinessHttpService().persistWithUrl(`public/${businessId}/${abObject?.id}/click/advertising`, {
                id: abObject?.id
            });
        } catch (error) {
            setState({
                ...state
            });
        }
    };
    const formatElementClasses = () => {
        let advertClass = '';
        let socialClass = '';
        switch (abObject.layout) {
            case 'BOTTOM_BAR':
                advertClass = 'flex justify-center bottom-[90px] absolute ';
                socialClass = 'top-[60px] right-[10px] absolute z-50';
                break;
            case 'TOP_BAR':
                advertClass = 'flex  justify-center top-[17px] absolute';
                socialClass = 'bottom-[200px] right-[10px] absolute';
                break;
            case 'SIDE_BAR_UP':
                advertClass = 'flex  justify-end right-[10px] top-[70px]  absolute';
                socialClass = 'bottom-[150px] right-[10px] absolute';
                break;
            case 'SIDE_BAR_BOTTOM':
                advertClass = 'flex  justify-end right-[10px] bottom-[150px]  absolute';
                socialClass = 'top-[60px] right-[10px] absolute';
                break;
            default:
                advertClass = 'flex justify-center bottom-[90px] absolute ';
                socialClass = 'top-[60px] right-[10px] absolute z-50';
                break;
        }
        setState({
            adClasses: advertClass,
            socialLinksClasses: socialClass
        });
    };
    const renderAdvert = () => {
        switch (abObject.layout) {
            case 'BOTTOM_BAR':
            case 'TOP_BAR':
                return (
                    <div className="w-full">
                        <div className="flex w-full justify-center ">
                            <div className=" bg-white w-[594.72px] px-7 flex justify-between py-1 items-center relative">
                                <AnimateElement className="absolute  pointer top-[-30px] right-[-40px]">
                                    <div className="w-full h-full" onClick={() => removeAd()}>
                                        <img src={cancelIcon} alt="" width={75} />
                                    </div>
                                </AnimateElement>

                                <div className="font-lora flex items-center">
                                    <div className="">
                                        <img src={iconMapping[abObject?.selectedAd]} alt="mailingIcon" className="w-[86.94px]" />
                                    </div>
                                    <div className="font-semi-bold text-lg leading-6">{abObject?.heading}</div>
                                </div>
                                <div className="text-xxs w-[185.59px]">{abObject?.body}</div>
                                <a href={abObject?.ctaUrl} onClick={() => handleUrlClick()} target="_blank" rel="noreferrer">
                                    <AnimateElement className="flex pointer black-3-border">
                                        <div
                                            className="text-white maroon-bg font-semi-bold text-xxs px-3.5 py-3"
                                            style={{
                                                backgroundColor: abObject?.btnColor,
                                                color: abObject?.btnTextColor
                                            }}
                                        >
                                            {abObject?.btnText}
                                        </div>
                                    </AnimateElement>
                                </a>
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="bg-white w-[350.25px] text-center rounded">
                        <AnimateElement className="absolute  pointer top-[-30px] right-[-35px]">
                            <div className="w-full h-full" onClick={() => removeAd()}>
                                <img src={cancelIcon} alt="" width={75} />
                            </div>
                        </AnimateElement>
                        <div className="w-[130.7px] h-[100px] mx-auto mt-3">
                            <img src={iconMapping[abObject?.selectedAd]} alt="mailingIcon" className="w-full" />
                        </div>
                        <div className="pt-6 text-lg font-extra-bold">{abObject?.heading}</div>
                        <div className="pt-4   w-[251px] mx-auto font-normal text-center">{abObject?.body}</div>
                        <div className="pt-5 pb-10  px-10 flex justify-center">
                            <a href={abObject?.ctaUrl} onClick={() => handleUrlClick()} target="_blank" rel="noreferrer">
                                <AnimateElement className="flex pointer black-3-border rounded">
                                    <div
                                        className="px-10 font-semi-bold  py-3"
                                        style={{
                                            backgroundColor: abObject?.btnColor,
                                            color: abObject?.btnTextColor
                                        }}
                                    >
                                        {abObject?.btnText}
                                    </div>
                                </AnimateElement>
                            </a>
                        </div>
                    </div>
                );
        }
    };
    useEffect(() => {
        formatElementClasses();
    }, []);
    const filteredSocialLinks = socialLinks.filter((val: any) => val.url);
    return (
        <>
            {showAd && <> {!isObjectEmpty(abObject) && <div className={`${adClasses} w-full `}>{renderAdvert()}</div>}</>}
            <div className={`${socialLinksClasses}`}>
                <div>
                    <div
                        className="flex  flex-col items-center "
                        style={{
                            border: `1.2px solid ${trackingConfig?.colorBack || defaultAppPurple}`
                        }}
                    >
                        {filteredSocialLinks.map(({ url, icon }: any, key: number) => {
                            const bottomBorder = key + 1 !== filteredSocialLinks.length;
                            return (
                                <a key={key} target="_blank" rel="noreferrer" href={url}>
                                    <div
                                        className="w-[72px] h-[72.19px] flex justify-center items-center"
                                        style={{
                                            borderBottom: bottomBorder
                                                ? `1.2px solid ${trackingConfig?.colorBack || defaultAppPurple}`
                                                : '',
                                            backgroundColor: trackingConfig?.colorBack
                                        }}
                                    >
                                        {icon}
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

const TrackDelivery = () => {
    const cardRef: any = useRef(null);
    const contentRef: any = useRef(null);
    const { id } = useParams();
    const initialState = {
        showDeliveryInfo: false,
        orderTimeLinePayload: [],
        orderDetails: {},
        driverPosition: {},
        pickupCoordinate: {},
        dropOffCoordinate: {},
        event: {},
        trackingConfig: {},
        deliveryDistance: '',
        pageLoading: true,
        numberCopied: false,
        driverInfo: {},
        orderCurrentStatus: {},
        etaObj: {},
        statusString: '',
        advertData: {},
        showAd: true,
        showRatingModal: false
    };

    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const {
        showDeliveryInfo,
        orderTimeLinePayload,
        orderDetails,
        deliveryDistance,
        trackingConfig,
        pickupCoordinate,
        dropOffCoordinate,
        numberCopied,
        driverInfo,
        driverPosition,
        pageLoading,
        orderCurrentStatus,
        etaObj,
        statusString,
        advertData,
        showAd,
        showRatingModal
    } = state;

    const orderTimeLineStages = [
        {
            name: 'Order confirmation',
            key: 'order_confirmation',
            icon: orderConfirmationIcon,
            stages: [],
            stages_key: {
                CREATED: 'Your order has been created',
                CONFIRMED: 'Your order has been confirmed'
            }
        },
        {
            name: 'Order Pickup',
            key: 'order_pickup',
            icon: orderPickUpIcon,
            stages: [],
            stages_key: {
                EN_ROUTE_TO_PICKUP: 'Our rider is on the way to pick up your order',
                APPROACHING_PICKUP: 'Our rider is almost at the pickup point',
                AT_PICKUP: 'Our rider is at the pickup point',
                PICKED_UP: 'We have successfully picked your order'
            }
        },
        {
            name: 'Order Drop Off',
            key: 'order_drop_off',
            icon: orderDropOffIcon,
            stages: [],
            stages_key: {
                EN_ROUTE_TO_DELIVERY: 'Our rider is on the way to drop off your order',
                APPROACHING_DELIVERY: 'Our rider is almost at the dropoff point'
            }
        },
        {
            name: 'Order delivery',
            key: 'order_delivery',
            icon: orderDeliveryIcon,
            stages: [],
            stages_key: {
                AT_DELIVERY: 'Your rider has reached the drop off location',
                DELIVERED: 'We have successfully delivered your order'
            }
        }
    ];

    const handleDeliveryInfoExpansion = () => {
        setState({
            showDeliveryInfo: !showDeliveryInfo
        });
    };
    const handleCallRiderAction = () => {
        window.location.href = `tel:${driverInfo?.phone_number}`;
    };

    const handleCopyDriverNumber = async () => {
        await navigator.clipboard.writeText(driverInfo?.phone_number);
        setState({
            numberCopied: true
        });
    };

    const showDeliveryDiv = (label: string, text: string, textClass = '') => (
        <div>
            <div className="flex items-center justify-between">
                <div className="font-semi-bold">{label}</div>
                <div className={textClass}>{text}</div>
            </div>
        </div>
    );

    const constructTimelineData = (eventListing: any, orderStatus = '') => {
        const arrayObj: any = JSON.parse(JSON.stringify([...orderTimeLineStages]));
        const newObj: any = {};
        const arr = eventListing;
        for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            newObj[element.name] = element;
        }
        for (let i = 0; i < arrayObj.length; i++) {
            const el = arrayObj[i];
            el.status = false;
            let breakArrayLoop = false;
            for (const key in el.stages_key) {
                if (Object.prototype.hasOwnProperty.call(el.stages_key, key)) {
                    if (newObj[key]) {
                        if (orderStatus === 'CANCELLED' && !newObj[key].occurred_at) {
                            breakArrayLoop = true;
                            break;
                        }
                        el.stages.push({
                            ...newObj[key],
                            text: el.stages_key[key],
                            completed: newObj[key].occurred_at || null
                        });
                        el.status = newObj[key].occurred_at || el.status;
                    } else {
                        el.stages.push({
                            name: key,
                            text: el.stages_key[key],
                            completed: false,
                            occurred_at: null,
                            location: null
                        });
                    }
                }
            }
        }
        if (orderStatus === 'CANCELLED') {
            const newArrayPayload = [];
            for (let j = 0; j < arrayObj.length; j++) {
                const arrayObjElement = arrayObj[j];

                if (arrayObjElement?.status) {
                    newArrayPayload.push(arrayObjElement);
                }
            }
            newArrayPayload.push({
                name: 'Order cancelled',
                key: 'order_cancelled',
                svgIcon: true,
                icon: <XboxX size={22} strokeWidth={1.8} color="white" />,
                stages: [],
                stages_key: {
                    CREATED: 'Your order has been cancelled',
                    CONFIRMED: 'Your order has been confirmed'
                },
                status: true
            });
            setState({
                orderTimeLinePayload: newArrayPayload
            });
        } else {
            setState({
                orderTimeLinePayload: arrayObj
            });
        }
    };

    const fetchApiData = async () => {
        let allowRatings = false;
        try {
            const apiRequest = await Promise.all([
                new OrderHttpService().getWithUrl(`/public/${id}`),
                new DriverHttpService().getWithUrl(`/public/${id}/dasher-information`)
            ]);
            const orderDetailsRequest = apiRequest[0]?.data?.data;
            const apiRequest2 = await Promise.all([
                new BusinessHttpService().getWithUrl(`public/${orderDetailsRequest?.business_id}/tracking`),
                new BusinessHttpService().getWithUrl(`public/${orderDetailsRequest?.business_id}/advertising`)
            ]);
            const driver = apiRequest[1]?.data?.data;
            let orderStatus = {};
            const trackingConfigRequest = apiRequest2[0];
            const { eventv2, dasher_location, dasher } = driver;
            const originPoint = {
                lat: orderDetailsRequest?.pickup_information?.address.latitude,
                lng: orderDetailsRequest?.pickup_information?.address?.longitude
            };
            const destinationPoint = {
                lat: orderDetailsRequest?.dropoff_information?.address?.latitude,
                lng: orderDetailsRequest?.dropoff_information?.address?.longitude
            };
            if (isNotEmptyArray(orderDetailsRequest.event)) orderStatus = orderDetailsRequest.event[orderDetailsRequest.event.length - 1];
            constructTimelineData(eventv2, orderDetailsRequest.status);
            if (isObjectEmpty(orderDetailsRequest?.rating) && orderDetailsRequest?.status === 'DELIVERED') allowRatings = true;
            setState({
                orderDetails: orderDetailsRequest,
                deliveryDistance: `${convertDistance(getDistance(originPoint, destinationPoint), 'mi').toFixed(2)} mi`,
                trackingConfig: trackingConfigRequest?.data?.data,
                pickupCoordinate: originPoint,
                showRatingModal: allowRatings,
                dropOffCoordinate: destinationPoint,
                driverInfo: dasher,
                driverPosition: dasher_location,
                pageLoading: false,
                orderCurrentStatus: orderStatus,
                statusString: orderDetailsRequest?.status,
                etaObj: {
                    order_pickup: orderDetailsRequest?.pickup_ready,
                    order_delivery: orderDetailsRequest?.dropoff_ready
                },
                advertData: !apiRequest2[1]?.data?.data?.enabled ? {} : apiRequest2[1]?.data?.data
            });
        } catch (error: any) {
            setState({ pageLoading: false });
        }
    };
    const renderStatusHeader = () => {
        switch (statusString) {
            case 'CREATED':
            case 'CONFIRMED':
                return (
                    <div className="px-6 py-[13px]">
                        <div className="flex justify-between items-center">
                            <div className="flex gap-x-2 items-center ">
                                <img src={headerOrderPickUpIcon} alt="Order stage" />
                                <div className="font-semi-bold">Order waiting to be picked-up</div>
                            </div>
                            <div className="font-semi-bold ">
                                <span className="text-[14.29px]"> ETA</span>
                                <div className="font-light -mt-1 text-sm">{`${moment(etaObj.order_pickup).format(
                                    'h:mm A'
                                )} ${getTimeZone()}`}</div>
                            </div>
                        </div>
                    </div>
                );
            case 'PICKED_UP':
                return (
                    <div className="px-6 py-3">
                        <div className="flex justify-between items-center mt-2.5">
                            <div className="flex gap-x-2 items-center">
                                <img src={headerOrderPickUpIcon} alt="Order stage" />
                                <div className="font-semi-bold text-[14px]">Rider is approaching drop off location</div>
                            </div>
                            <div className="font-light text-sm">{`${moment(etaObj.order_delivery).format('h:mm A')} ${getTimeZone()}`}</div>
                        </div>
                    </div>
                );
            case 'DELIVERED':
                return (
                    <div className="px-6 py-3">
                        <div
                            className="flex justify-between items-center mt-2.5"
                            style={{ color: `${trackingConfig?.colorTitle || '#ffff'} !important` }}
                        >
                            <div className="flex gap-x-2 items-center ">
                                <img src={headerOrderDeliveredIcon} alt="Order stage" />
                                <div className="font-semi-bold">Delivery is complete</div>
                            </div>
                            <div className="font-light  text-sm">
                                {`${moment(orderCurrentStatus.date).format('h:mm A')} ${getTimeZone()}`}
                            </div>
                        </div>
                    </div>
                );
            case 'CANCELLED':
                return (
                    <div className="px-6 py-3">
                        <div className="flex justify-between items-center mt-2.5">
                            <div className="flex gap-x-2 items-center ">
                                <img src={headerOrderPickUpIcon} alt="Order stage" />
                                <div className="font-semi-bold">Delivery is cancelled</div>
                            </div>
                            <div className="font-light text-white text-sm">
                                {`${moment(orderCurrentStatus?.date).format('h:mm A')} ${getTimeZone()}`}
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="px-6 py-3">
                        <div className="flex justify-between items-center mt-2.5">
                            <div className="flex gap-x-2 items-center">
                                <img src={headerOrderPickUpIcon} alt="Order stage" />
                                <div className="font-semi-bold">Estimated pick-up time</div>
                            </div>
                            <div className="font-light">{`${moment(etaObj.order_pickup).format('h:mm A')} ${getTimeZone()}`}</div>
                        </div>
                    </div>
                );
        }
    };
    const handleSource1Message = (event: any) => {
        const { eventv2, status, dasher, events } = JSON.parse(event?.data);
        constructTimelineData(eventv2, status);
        setState({
            statusString: status
        });
        if (isObjectEmpty(driverInfo)) {
            setState({
                driverInfo: dasher
            });
        }
        const newEtaObj = { ...etaObj };
        let etaChanged = false;
        for (let index = 0; index < events.length; index++) {
            const element = events[index];
            if (element.status === 'PICKED_UP') {
                newEtaObj.order_pickup = element.date;
                etaChanged = true;
            }
            if (element.status === 'DELIVERED') {
                newEtaObj.order_delivery = element.date;
                etaChanged = true;
            }
        }
        if (etaChanged) setState({ etaObj: newEtaObj });
    };

    const handleSource2Message = (event: any) => {
        const latLng = JSON.parse(event?.data);
        setState({
            driverPosition: latLng
        });
    };
    const removeAd = () => {
        setState({
            showAd: !showAd
        });
    };
    const toggleRatingsModal = () => {
        setState({
            showRatingModal: !showRatingModal
        });
    };
    useEffect(() => {
        const source1 = new EventSource(`${HUB_URL}?topic=tracking/${id}`);
        const source2 = new EventSource(`${HUB_URL}?topic=dasher-position/${id}`);

        source1.addEventListener('message', handleSource1Message);
        source2.addEventListener('message', handleSource2Message);

        // fetchApiData();
        return () => {
            source1.removeEventListener('message', handleSource1Message);
            source2.removeEventListener('message', handleSource2Message);
            source1.close();
            source2.close();
        };
    }, [id]);

    useEffect(() => {
        if (!pageLoading) {
            if (showDeliveryInfo) {
                cardRef.current.style.height = `${contentRef.current.getBoundingClientRect().height}px`;
            } else {
                cardRef.current.style.height = '0px';
            }
        }
    }, [showDeliveryInfo, pageLoading]);

    useEffect(() => {
        fetchApiData();
        // Cleanup method
        return () => {
            setState({
                ...initialState
            });
        };
    }, []);
    return (
        <div className="p-0 m-0">
            {pageLoading ? (
                <XpressrunLoader />
            ) : (
                <>
                    <div className="block md:hidden p-0 m-0">
                        <MobileTrackingView
                            orderDetails={orderDetails}
                            deliveryDistance={deliveryDistance}
                            orderTimeLinePayload={orderTimeLinePayload}
                            trackingConfig={trackingConfig}
                            driverInfo={driverInfo}
                            handleCallDriver={handleCallRiderAction}
                            driverPosition={driverPosition}
                            pickupCoordinate={pickupCoordinate}
                            dropOffCoordinate={dropOffCoordinate}
                            statusString={statusString}
                            abObject={advertData}
                            etaObj={etaObj}
                        />
                    </div>
                    <div className="hidden md:block p-0 m-0">
                        <div className="p-0 m-0 max-h-screen min-h-screen track-delivery-desktop overflow-hidden">
                            <div
                                className="flex items-center "
                                style={{ backgroundColor: `${trackingConfig?.colorBack || defaultAppPurple}` }}
                            >
                                <div
                                    className="w-[450px] border-r-[0.5px] border-[#ffff]"
                                    style={{ color: `${trackingConfig?.colorTitle || '#ffff'}` }}
                                >
                                    <div
                                        className={`bg-[${trackingConfig?.colorBack}] `}
                                        style={{ color: `${trackingConfig?.colorTitle || '#ffff'}` }}
                                    >
                                        {renderStatusHeader()}
                                    </div>
                                </div>
                                <div
                                    className="flex-grow flex items-center gap-x-[105px] justify-end pr-[12px]"
                                    style={{ color: `${trackingConfig?.colorTitle || 'white'}` }}
                                >
                                    <div>
                                        <div className="flex items-center gap-x-[20px]">
                                            {/* <div className="font-semi-bold text-lg">{capitalizeFirstLetter(trackingConfig?.brandName)}</div> */}
                                            <img src={trackingConfig.imgUrl || customLogo} width={42} alt="Logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex ">
                                <div className="tracking-sidebar relative  w-[450px] max-h-screen min-h-screen overflow-y-scroll pb-20">
                                    <div className="p-0 m-0 fixed z-50 w-[442px]  bg-white">
                                        <div className="">
                                            {driverInfo?.first_name && (
                                                <div className="pt-[21px] pb-[21px]  px-6">
                                                    <div className="flex justify-between">
                                                        <div className="flex items-center gap-x-2">
                                                            <img src={avatarImage} width={50} alt="Avatar" />
                                                            <div>
                                                                <div className="text-sm mb-0">{driverInfo?.first_name}</div>
                                                                <span className="text-xs xpressrun-grey font-semi-bold">
                                                                    {orderDetails?.provider.toUpperCase()} delivery driver
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div
                                                                className="pointer flex items-center justify-end"
                                                                onClick={() => handleCallRiderAction()}
                                                            >
                                                                <AnimateElement className="w-full h-full flex items-center justify-center pointer shadow-lg  desktop-phone-container">
                                                                    <img src={greenPhone} width={17.33} height={17.33} alt="Phone" />
                                                                </AnimateElement>
                                                            </div>
                                                            <div className="mt-3 xpressrun-grey" onClick={() => handleCopyDriverNumber()}>
                                                                <AnimateElement className="w-full h-full flex items-center justify-center pointer text-sm">
                                                                    {`${numberCopied ? "Driver's number copied!" : "Copy driver's number"}`}
                                                                </AnimateElement>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="delivery-info-section py-2.5 relative">
                                                <AnimateElement className="pointer">
                                                    <div
                                                        className="flex xpressrun-grey flex justify-center  items-center font-semi-bold text-sm"
                                                        onClick={() => handleDeliveryInfoExpansion()}
                                                    >
                                                        Delivery Information
                                                        <ChevronDown size={28} strokeWidth={1.8} color="#9FACBC" />
                                                    </div>
                                                </AnimateElement>
                                                <div ref={cardRef}>
                                                    <div ref={contentRef}>
                                                        <div className="show">
                                                            <div className="px-6 ">
                                                                <div className="py-5 show">
                                                                    <div className="mt-8">{showDeliveryDiv('OrderId', `${id}`)}</div>
                                                                    <div className="mt-5">
                                                                        {showDeliveryDiv('Provider', orderDetails?.provider)}
                                                                    </div>
                                                                    <div className="mt-5">{showDeliveryDiv('Dimensions', '--')}</div>
                                                                    <div className="mt-5">{showDeliveryDiv('Weight', '--')}</div>
                                                                    <div className="mt-5">
                                                                        {showDeliveryDiv('Distance', deliveryDistance)}
                                                                    </div>
                                                                    <div className="mt-5">
                                                                        {showDeliveryDiv(
                                                                            'Price',
                                                                            `${formatCurrency(orderDetails?.price, true)}`,
                                                                            'font-bold'
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={driverInfo?.first_name ? 'pt-40' : 'pt-16'}>
                                        <div className="pr-10 pl-24">
                                            <div className="tracking-timeline track">
                                                {orderTimeLinePayload.map((orderTimeLine: any, _i: number) => {
                                                    const parentBorderColor = orderTimeLine?.status
                                                        ? `${trackingConfig?.colorBack || defaultAppPurple}`
                                                        : inactiveGrey;
                                                    return (
                                                        <>
                                                            <div
                                                                className={`parent-time-line py-4 pb-8 ml-4 relative ${
                                                                    orderTimeLine?.key === 'order_cancelled' ? 'pb-0' : ''
                                                                }`}
                                                            >
                                                                {/* Replaces css pseduo element! */}
                                                                <div
                                                                    className="parent-time-line-before absolute content-[' '] top-[0px] left-[-16px] w-[1px] h-full"
                                                                    style={{
                                                                        border: `1.2px solid  ${parentBorderColor || defaultAppPurple}`
                                                                    }}
                                                                />
                                                                <div
                                                                    className="parent-time-line-icon flex justify-center items-center"
                                                                    style={{
                                                                        backgroundColor: parentBorderColor
                                                                    }}
                                                                >
                                                                    {orderTimeLine?.svgIcon ? (
                                                                        <>{orderTimeLine?.icon}</>
                                                                    ) : (
                                                                        <img src={orderTimeLine?.icon} alt="Timeline icon" />
                                                                    )}
                                                                </div>
                                                                <div className="time-line-date absolute text-sm">
                                                                    {orderTimeLine?.occurred_at
                                                                        ? moment(orderTimeLine?.occurred_at).format('h:mm A')
                                                                        : ''}
                                                                </div>
                                                                <div className="flex justify-between items-center ml-4">
                                                                    <div className="text">{orderTimeLine?.name}</div>
                                                                    <div className="eta default-color text-xs">
                                                                        {etaObj[orderTimeLine.key] && (
                                                                            <>
                                                                                <span className="font-bold">ETA </span>
                                                                                {moment(etaObj[orderTimeLine.key]).format('h:mm a')}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {orderTimeLine?.stages.map((stage: any, index: number) => {
                                                                const childBorderColor = stage?.completed
                                                                    ? `${trackingConfig?.colorBack || defaultAppPurple}`
                                                                    : inactiveGrey;
                                                                return (
                                                                    <div className="child-time-line py-3 pb-6 ml-4 relative">
                                                                        {/* Replaces pseduo element */}
                                                                        <div
                                                                            className="child-time-line-before content-[' '] absolute top-[0px] left-[-16px] w-[1px] h-full"
                                                                            style={{
                                                                                border: `1.2px solid  ${
                                                                                    childBorderColor || defaultAppPurple
                                                                                }`
                                                                            }}
                                                                        />
                                                                        <span
                                                                            className={`child-timeline-icon ${
                                                                                stage?.completed ? 'default-bg' : 'xpressrun-grey-bg'
                                                                            }
                                                        `}
                                                                            style={{
                                                                                backgroundColor: childBorderColor || defaultAppPurple
                                                                            }}
                                                                        />
                                                                        <div className="time-line-date child-time-line-date absolute text-sm">
                                                                            {stage?.occurred_at
                                                                                ? moment(stage?.occurred_at).format('h:mm a')
                                                                                : ''}
                                                                        </div>
                                                                        <div className="flex justify-between items-center ml-4">
                                                                            <div className="text-sm text">{stage?.text}</div>
                                                                            {/* <div>ETA 12:20</div> */}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow relative">
                                    <CustomMap
                                        driverPoint={statusString === 'DELIVERED' ? {} : driverPosition}
                                        originPoint={pickupCoordinate}
                                        destPoint={dropOffCoordinate}
                                        height="100%"
                                        status={statusString}
                                        isTracking
                                    />
                                    <RenderAdvertAndSocials
                                        abObject={advertData}
                                        trackingConfig={trackingConfig}
                                        removeAd={removeAd}
                                        showAd={showAd}
                                        businessId={orderDetails?.business_id}
                                    />
                                    {/* <div className="absolute w-full bg-white top-0 mx-3">Ad goes here</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showRatingModal && <RatingsModal show={showRatingModal} toggleModal={toggleRatingsModal} />}
        </div>
    );
};

export default TrackDelivery;
