/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './formtoogle.scss';

/**
 *
 * @param {*} param0
 */
const FormToogle = ({ text, value, onChange, name = 'form-toggle', textClass = 'secondary-text', ...restInputProps }: any) => (
    <div className="data-toggle">
        <div className={`${text ? 'gap-x-[9px]' : ''} flex  items-center`}>
            <input type="checkbox" id={name} className="hidden input-toggle" onChange={onChange} checked={value} {...restInputProps} />
            <label htmlFor={name} className="toggle">
                <span />
            </label>
            <span className={` text-[#14142B] ${textClass}`}>{text}&nbsp;&nbsp;</span>
        </div>
    </div>
);

export default FormToogle;
