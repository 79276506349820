// /* eslint-disable jsx-a11y/no-static-element-interactions */
// /* eslint-disable jsx-a11y/click-events-have-key-events */
// /* eslint-disable react/jsx-no-bind */
// import React, { Fragment, useState } from 'react';

// import { Menu } from '@headlessui/react';
// import filterIcon from 'assets/icons/filter.svg';
// import '../DateFilter/tablefilters.scss';
// import Button from 'components/Button';
// import CheckBox from 'components/CheckBox';

// const StatusFilter = () => {
//     const [display, setDisplay] = useState('Today');
//     const [customOpen, setCustomOpen] = useState(false);

//     const buttonClicked = () => {
//         setCustomOpen((prev) => !prev);
//     };

//     return (
//         <div className="table-filter-container">
//             {/* <Menu>
//                 {({ open }) => (
//                     <>
//                         <Menu.Button onClick={buttonClicked} className="flex items-center  gap-8 filter-btn-container">
//                             <img src={filterIcon} alt="Filter" width="18px" />
//                         </Menu.Button>
//                         {customOpen && (
//                             <Menu.Items
//                                 static
//                                 className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none filter-items-container px-4"
//                             >
//                                 <div className="py-2 purple-gray font-semibold text-sm">Filter</div>
//                                 <Menu.Item>{({ active }) => <CheckBox label="Completed" action={() => {}} />}</Menu.Item>
//                                 <Menu.Item>{({ active }) => <CheckBox label="On-going" action={() => {}} />}</Menu.Item>
//                                 <Menu.Item>{({ active }) => <CheckBox label="Cancelled" action={() => {}} />}</Menu.Item>
//                                 <div className="flex w-full justify-between py-3">
//                                     <Button
//                                         text="Clear"
//                                         action={() => {
//                                             buttonClicked();
//                                         }}
//                                         className="grey-outline-btn text-sm p-2 py-1 rounded"
//                                     />
//                                     <Button
//                                         text="Apply"
//                                         action={() => {
//                                             buttonClicked();
//                                         }}
//                                         className="app-btn border-none text-sm p-2 py-1 rounded"
//                                     />
//                                 </div>
//                             </Menu.Items>
//                         )}
//                     </>
//                 )}
//             </Menu> */}
//         </div>
//     );
// };

// export default StatusFilter;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useReducer, useRef } from 'react';
import DatePicker from 'sassy-datepicker';
import moment from 'moment';
import AppBagde from 'components/Badges';
import { isNotEmptyArray } from 'libs';
import Button from 'components/Button';

const StatusFilter = (props: any) => {
    const { label, placeholder, tabs, onApply, filterParams, ...rest } = props;
    const ref: any = useRef();
    const initialState = {
        showDropDown: false,
        data: []
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { showDropDown, data } = state;
    const toggleDatePicker = () => {
        setState({
            showDropDown: !showDropDown
        });
    };
    const handleCheckboxChange = (itemKey: string) => {
        const newData = [...data];
        const getIndex = newData.indexOf(itemKey);
        if (getIndex >= 0) {
            newData.splice(getIndex, 1);
        } else {
            newData.push(itemKey);
        }
        setState({
            data: [...newData]
        });
    };

    const clearSelection = () => {
        setState({
            data: []
        });
    };

    const applySelection = () => {
        onApply(data);
        setState({
            showDropDown: false
        });
    };

    /**
     * React checkIsInArray method
     * @returns {string} check or '' is returned
     * @param {string} itemKey - .
     */
    const checkIsInArray = (itemKey: string): boolean => {
        const check = !!data.includes(itemKey);
        return check;
    };

    useEffect(() => {
        const newArr = filterParams.split(',');
        const newDataArr = [];
        for (let index = 0; index < tabs.length; index++) {
            const element = tabs[index];
            if (newArr.includes(element?.key)) newDataArr.push(element?.key);
        }
        setState({
            data: newDataArr
        });
    }, [filterParams]);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setState({
                    showDropDown: false
                });
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
    return (
        <div ref={ref} className="w-full">
            <div className="flex gap-x-[20px] flex-wrap items-center w-full">
                {label && <div>{label}</div>}
                <div className="relative w-full">
                    <div
                        className=" bg-[#F9FAFA] py-[8px] px-[10px] pointer flex justify-between items-center rounded-sm border"
                        onClick={() => toggleDatePicker()}
                    >
                        <span className="text-[14.3px] mr-3">
                            {isNotEmptyArray(data) ? `${data?.length} items selected ` : placeholder}
                        </span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                                <path d="M7 7.5L0.504809 0.75L13.4952 0.75L7 7.5Z" fill="#D9D9D9" />
                            </svg>
                        </span>
                    </div>
                    {showDropDown && (
                        <div className="absolute z-10 bg-white  top-[42px] rounded px-2.5 py-4 pt-0 w-full border">
                            <div>
                                {tabs.map((tab: any, _i: number) => (
                                    <div key={_i} className="mt-4">
                                        <div className="flex gap-x-4 items-center">
                                            <div>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        checked={checkIsInArray(tab?.key)}
                                                        name="checkbox-one"
                                                        onChange={() => handleCheckboxChange(tab?.key)}
                                                        id="checkbox-one"
                                                        className="  cursor-pointer 
    w-[16px] h-[16px] ring-0 border-[#E6E6E6] border  focus:ring-0 "
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center flex-grow">
                                                <div onClick={() => handleCheckboxChange(tab?.key)} className="pointer">
                                                    <AppBagde
                                                        type={tab?.key?.toLowerCase()}
                                                        text={tab?.title}
                                                        className="rounded-md text-[12px] pointer"
                                                    />{' '}
                                                </div>
                                                <div className="bg-[#F1F5F8] text-[#667489] text-[12px] rounded-[360px] px-2.5 py-0.5">
                                                    {tab?.count}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="w-full flex justify-between items-center mt-4">
                                <Button text="Clear" action={() => clearSelection()} className="px-2.5 py-1 app-outline-btn rounded" />
                                <Button text="Apply" action={() => applySelection()} className="px-2.5 py-1 app-btn-border-0 rounded" />
                            </div>
                        </div>
                        // <DatePicker
                        //     value={data || new Date()}
                        //     onChange={handleDateChange}
                        //     // maxDate={new Date()}
                        //     weekStartsFrom="Monday"
                        //     className="absolute z-50  top-[30px]"
                        //     {...rest}
                        // />
                    )}
                </div>
            </div>
        </div>
    );
};

export default StatusFilter;
