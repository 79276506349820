/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
import React, { Fragment, useEffect, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BusinessHttpService } from 'http-service/Business-http-service';
import logoIcon from 'assets/icons/sidebar/new_xpressRun_logo.svg';
import slimLogoIcon from 'assets/icons/sidebar/slim_logo.svg';
import plusIcon from 'assets/icons/vector_plus.svg';
import Button from 'components/Button';
import dashboardIcon from 'assets/icons/sidebar/dashboard_icon.svg';
import ordersIcon from 'assets/icons/sidebar/orders_icon.svg';
import marketingIcon from 'assets/icons/sidebar/marketing_icon.svg';
import trackingIcon from 'assets/icons/sidebar/tracking_icon.svg';
import productsIcon from 'assets/icons/sidebar/products_icon.svg';
import settingsIcon from 'assets/icons/sidebar/settings_icon.svg';
import shopIcon from 'assets/icons/sidebar/shop_integration_icon.svg';
import logoutIcon from 'assets/icons/sidebar/logout.svg';
import userIcon from 'assets/icons/sidebar/user.svg';
import ecommerceBasketIcon from 'assets/icons/sidebar/ecommerce_basket.svg';
import { ChevronDown, ChevronRight } from 'tabler-icons-react';
import { getUserInfo, isBetaUser } from 'keycloak';
import 'reactjs-popup/dist/index.css';
import SidebarMenuDropDown from './SideBarDropDown';
import AnimateElement from 'ui-components/AnimateElement';
import { useKeycloak } from '@react-keycloak/web';
import { useApp } from 'app-context';
import { extractFirstLetter, truncateMultilineText } from 'libs';
import { appSideBarType } from 'data';
import { Menu, Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const Sidebar = (props: any): JSX.Element => {
    const { keycloak } = useKeycloak();
    const { getBusinessInfo, update, sideBarType } = useApp() as AppContextType;
    const { setDashboardState } = props;
    const navigate = useNavigate();
    const initialState = {
        sidebarMenu: [
            {
                name: 'Dashboard',
                path: '/dashboard',
                match: /^\/dashboard/,
                icon: dashboardIcon,
                hasChildren: true,
                opened: false,
                disabled: false,
                children: [
                    {
                        name: 'Activities',
                        path: '/dashboard/activities',
                        slimSideBar: false,
                        match: '/dashboard/activities'
                    },
                    {
                        name: 'Analytics',
                        path: '/dashboard/analytics',
                        slimSideBar: false,
                        match: '/dashboard/analytics'
                    },
                    {
                        name: 'Ratings',
                        path: '/dashboard/ratings',
                        slimSideBar: false,
                        match: '/dashboard/ratings'
                    }
                ]
            },
            {
                name: 'Orders',
                path: '/orders/listing',
                match: /^\/orders/,
                disabled: false,
                slimSideBar: true,
                icon: ordersIcon
            },

            {
                name: 'Marketing',
                path: '/marketing',
                match: /^\/marketing/,
                icon: marketingIcon,
                hasChildren: true,
                opened: false,
                disabled: false,
                children: [
                    {
                        name: 'SMS campaigns',
                        path: '/marketing/campaigns',
                        slimSideBar: false,
                        match: '/marketing/campaigns'
                    },
                    {
                        name: 'Contacts',
                        path: '/marketing/contacts',
                        slimSideBar: false,
                        match: '/marketing/contacts'
                    },
                    {
                        name: 'Advertising ',
                        path: '/marketing/adverts',
                        slimSideBar: false,
                        match: '/marketing/adverts'
                    }
                ]
            },
            // {
            //     name: 'SMS campaigns',
            //     path: '/sms/campaigns',
            //     match: /^\/sms/,
            //     extraText: 'NEW!',
            //     icon: 'sms'
            // },
            {
                name: 'Tracking',
                path: '/settings/tracking-pages',
                match: /^\/tracking-pages/,
                disabled: false,
                slimSideBar: true,
                icon: trackingIcon
            },
            {
                name: 'Products',
                path: '/products',
                match: /^\/products/,
                disabled: false,
                slimSideBar: false,
                icon: productsIcon
            },
            {
                name: 'Settings',
                path: '/settings/teams',
                match: /^\/settings/,
                divider: true,
                icon: settingsIcon,
                slimSideBar: true,
                disabled: false
            },
            {
                name: 'Store integration',
                path: '/settings/shop-ingeration',
                match: /^\/shop-ingeration/,
                icon: shopIcon,
                slimSideBar: true,
                disabled: false
            }
        ],
        onboardingData: {
            setPayment: false,
            setOrder: false,
            setAddress: false,
            setTracking: false,
            setStore: false
        },
        dismissShopInfo: false,
        showOnboardingProcess: false
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { sidebarMenu, dismissShopInfo, showOnboardingProcess, onboardingData } = state;

    const getMatch = (item: string) => {
        if (window.location.pathname.match(item)) {
            return true;
        }
        return false;
    };

    const handleMenuClick = (itemIndex: number) => {
        const currentMenu = sidebarMenu[itemIndex];
        currentMenu.opened = !currentMenu.opened;
        setState({
            ...state,
            sidebarMenu: [...sidebarMenu]
        });
    };

    const setAppSideBarState = (makeSlim = false) => {
        if (makeSlim) {
            update({ sideBarType: appSideBarType?.SLIM });
        } else {
            update({ sideBarType: appSideBarType?.FULL });
        }
        setDashboardState();
    };

    const determineActiveMenu = () => {
        const sidebarMenuArray = sidebarMenu;
        for (let index = 0; index < sidebarMenuArray.length; index++) {
            const element = sidebarMenuArray[index];

            if (element.hasChildren && window.location.pathname.match(element?.match)) {
                element.opened = true;
                break;
            }
            if (window.location.pathname.match(element?.match) && element?.slimSideBar) {
                setAppSideBarState(true);
            }
        }
        setState({
            sidebarMenu: [...sidebarMenuArray]
        });
    };

    const getOnboardingStats = async () => {
        try {
            const apiData = await new BusinessHttpService().getWithUrl('onboarding');
            const data = apiData?.data?.data;
            setState({
                onboardingData: {
                    // setPayment: data?.setPayment,
                    setPayment: false,
                    setOrder: data?.setOrder,
                    setAddress: data?.setAddress,
                    setTracking: data?.setTracking,
                    setStore: data?.setStore
                },
                onboardingDataLoading: false
            });
        } catch (error) {
            // Empty
            setState({
                onboardingDataLoading: false
            });
        }
    };
    const getOnBoardingPercentage = () => {
        let completed = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const key in onboardingData) {
            // eslint-disable-next-line no-prototype-builtins
            if (onboardingData.hasOwnProperty(key)) {
                if (onboardingData[key]) completed += 1;
            }
        }
        return (completed * 100) / Object.keys(onboardingData).length;
    };

    const xpressRunSvg = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="82" height="16" viewBox="0 0 82 16" fill="none">
            <path
                d="M4.16391 5.79179L0.0669838 0.150391H2.89581L5.57832 3.85713L8.27709 0.150391H11.1059L6.99273 5.79179L11.1547 11.5145H8.32586L5.57832 7.7427L2.83078 11.5145H0.00195312L4.16391 5.79179Z"
                fill="#364661"
            />
            <path
                d="M12.0326 3.71081H14.4062V4.57247C15.1974 3.83545 16.1729 3.46695 17.3326 3.46695C18.1455 3.46695 18.8608 3.64036 19.4786 3.98719C20.1072 4.32318 20.5949 4.80549 20.9418 5.43412C21.2886 6.05191 21.462 6.77266 21.462 7.59638C21.462 8.40927 21.2886 9.13002 20.9418 9.75865C20.5949 10.3873 20.1072 10.8804 19.4786 11.2381C18.8608 11.5849 18.1455 11.7583 17.3326 11.7583C16.7798 11.7583 16.2542 11.677 15.7556 11.5145C15.257 11.3519 14.8343 11.1189 14.4875 10.8154V15.0749H12.0326V3.71081ZM16.7148 9.83994C17.3759 9.83994 17.9124 9.63401 18.3243 9.22215C18.747 8.81029 18.9583 8.26837 18.9583 7.59638C18.9583 6.9244 18.747 6.3879 18.3243 5.98688C17.9124 5.58586 17.3759 5.38535 16.7148 5.38535C16.2054 5.38535 15.7502 5.52083 15.3492 5.79179C14.9481 6.06275 14.6609 6.42042 14.4875 6.86479V8.34423C14.6501 8.79945 14.9319 9.16253 15.3329 9.43349C15.7448 9.70446 16.2054 9.83994 16.7148 9.83994Z"
                fill="#364661"
            />
            <path
                d="M22.5194 3.71081H24.893V4.83259C25.2073 4.45324 25.6463 4.16602 26.2099 3.97093C26.7735 3.765 27.413 3.66204 28.1283 3.66204V5.53167C27.3804 5.53167 26.7193 5.64547 26.1449 5.87308C25.5813 6.10068 25.1911 6.44751 24.9743 6.91356V11.5145H22.5194V3.71081Z"
                fill="#364661"
            />
            <path
                d="M33.3336 11.7583C31.8379 11.7583 30.662 11.4007 29.8057 10.6853C28.9495 9.95916 28.5214 8.94577 28.5214 7.64516C28.5214 6.35539 28.9007 5.33657 29.6594 4.58872C30.429 3.84087 31.5236 3.46695 32.9435 3.46695C33.8756 3.46695 34.6668 3.64036 35.3171 3.98719C35.9674 4.33402 36.4605 4.81633 36.7965 5.43412C37.1325 6.04107 37.3005 6.72931 37.3005 7.49884V8.49055H30.8625C31.0359 9.48769 31.968 9.98626 33.6588 9.98626C34.1574 9.98626 34.6722 9.9429 35.2033 9.85619C35.7344 9.76949 36.195 9.65026 36.5852 9.49853V11.2218C36.2058 11.3844 35.7181 11.5145 35.122 11.612C34.5259 11.7096 33.9298 11.7583 33.3336 11.7583ZM34.9757 6.89731C34.9432 6.37706 34.7481 5.97604 34.3904 5.69424C34.0327 5.4016 33.5396 5.25529 32.911 5.25529C32.2932 5.25529 31.8054 5.4016 31.4478 5.69424C31.0901 5.98688 30.895 6.3879 30.8625 6.89731H34.9757Z"
                fill="#364661"
            />
            <path
                d="M41.5359 11.7583C40.9506 11.7583 40.322 11.6933 39.65 11.5632C38.9888 11.444 38.4686 11.2706 38.0893 11.043V9.1246C38.5228 9.38472 39.043 9.59607 39.65 9.75865C40.2569 9.92123 40.8205 10.0025 41.3408 10.0025C41.7852 10.0025 42.1103 9.96458 42.3162 9.88871C42.5222 9.81284 42.6251 9.67194 42.6251 9.46601C42.6251 9.30344 42.5655 9.17337 42.4463 9.07583C42.3271 8.96744 42.1428 8.87532 41.8936 8.79945C41.6551 8.71274 41.2541 8.5881 40.6905 8.42552C40.0293 8.23043 39.5091 8.03534 39.1297 7.84025C38.7612 7.63432 38.4794 7.37962 38.2844 7.07614C38.1001 6.77267 38.008 6.38248 38.008 5.90559C38.008 5.09271 38.3277 4.48576 38.9672 4.08474C39.6175 3.67288 40.4954 3.46695 41.6009 3.46695C42.1428 3.46695 42.701 3.52656 43.2754 3.64578C43.8607 3.75417 44.3105 3.90048 44.6248 4.08474V5.95437C44.3105 5.74844 43.8986 5.58044 43.3892 5.45038C42.8907 5.32032 42.403 5.25529 41.9261 5.25529C41.4817 5.25529 41.1349 5.29864 40.8856 5.38535C40.6471 5.47205 40.5279 5.63463 40.5279 5.87308C40.5279 6.07901 40.6417 6.23616 40.8693 6.34455C41.1078 6.45293 41.5467 6.58841 42.1862 6.75099L42.5113 6.83228C43.1942 7.01653 43.7252 7.22246 44.1046 7.45007C44.4839 7.66683 44.7495 7.93238 44.9012 8.24669C45.0638 8.55017 45.1451 8.93493 45.1451 9.40098C45.1451 10.1163 44.8362 10.6908 44.2184 11.1243C43.6006 11.547 42.7064 11.7583 41.5359 11.7583Z"
                fill="#364661"
            />
            <path
                d="M49.403 11.7583C48.8178 11.7583 48.1891 11.6933 47.5172 11.5632C46.856 11.444 46.3358 11.2706 45.9564 11.043V9.1246C46.39 9.38472 46.9102 9.59607 47.5172 9.75865C48.1241 9.92123 48.6877 10.0025 49.208 10.0025C49.6523 10.0025 49.9775 9.96458 50.1834 9.88871C50.3893 9.81284 50.4923 9.67194 50.4923 9.46601C50.4923 9.30344 50.4327 9.17337 50.3135 9.07583C50.1943 8.96744 50.01 8.87532 49.7607 8.79945C49.5223 8.71274 49.1212 8.5881 48.5576 8.42552C47.8965 8.23043 47.3763 8.03534 46.9969 7.84025C46.6284 7.63432 46.3466 7.37962 46.1515 7.07614C45.9673 6.77267 45.8751 6.38248 45.8751 5.90559C45.8751 5.09271 46.1949 4.48576 46.8343 4.08474C47.4846 3.67288 48.3626 3.46695 49.4681 3.46695C50.01 3.46695 50.5682 3.52656 51.1426 3.64578C51.7279 3.75417 52.1777 3.90048 52.492 4.08474V5.95437C52.1777 5.74844 51.7658 5.58044 51.2564 5.45038C50.7579 5.32032 50.2701 5.25529 49.7932 5.25529C49.3489 5.25529 49.002 5.29864 48.7527 5.38535C48.5143 5.47205 48.3951 5.63463 48.3951 5.87308C48.3951 6.07901 48.5089 6.23616 48.7365 6.34455C48.9749 6.45293 49.4139 6.58841 50.0534 6.75099L50.3785 6.83228C51.0613 7.01653 51.5924 7.22246 51.9718 7.45007C52.3511 7.66683 52.6166 7.93238 52.7684 8.24669C52.931 8.55017 53.0122 8.93493 53.0122 9.40098C53.0122 10.1163 52.7033 10.6908 52.0856 11.1243C51.4678 11.547 50.5736 11.7583 49.403 11.7583Z"
                fill="#364661"
            />
            <path
                d="M53.9049 0.150391H58.8309C60.1207 0.150391 61.1233 0.475543 61.8386 1.12585C62.5648 1.76532 62.9279 2.692 62.9279 3.9059C62.9279 4.7513 62.7328 5.47205 62.3426 6.06817C61.9524 6.65344 61.3997 7.08156 60.6843 7.35252L62.8466 11.5145H60.3104L58.3107 7.66142H56.376V11.5145H53.9049V0.150391ZM58.392 5.5967C59.0206 5.5967 59.5138 5.46122 59.8714 5.19026C60.2399 4.9193 60.4242 4.49118 60.4242 3.9059C60.4242 3.28811 60.2454 2.85458 59.8877 2.60529C59.53 2.34517 59.0315 2.21511 58.392 2.21511H56.376V5.5967H58.392Z"
                fill="#364661"
            />
            <path
                d="M66.9215 11.7583C65.8594 11.7583 65.0627 11.4711 64.5317 10.8967C64.0006 10.3114 63.735 9.53646 63.735 8.57184V3.71081H66.1899V8.31172C66.1899 9.33053 66.6614 9.83994 67.6043 9.83994C68.0162 9.83994 68.401 9.74239 68.7586 9.5473C69.1163 9.35221 69.4144 9.05957 69.6528 8.66939V3.71081H72.1077V11.5145H69.7341V10.5715C69.3873 10.9726 68.9646 11.2706 68.466 11.4657C67.9783 11.6608 67.4634 11.7583 66.9215 11.7583Z"
                fill="#364661"
            />
            <path
                d="M73.4934 3.71081H75.867V4.67001C76.2247 4.26899 76.6474 3.97093 77.1351 3.77584C77.6337 3.56991 78.1539 3.46695 78.6959 3.46695C79.758 3.46695 80.5492 3.75416 81.0695 4.3286C81.6006 4.8922 81.8661 5.65631 81.8661 6.62093V11.5145H79.4112V6.89731C79.4112 5.88933 78.9452 5.38535 78.013 5.38535C77.6012 5.38535 77.2164 5.48831 76.8587 5.69424C76.5011 5.88933 76.1976 6.18197 75.9483 6.57215V11.5145H73.4934V3.71081Z"
                fill="#364661"
            />
        </svg>
    );

    useEffect(() => {
        getOnboardingStats();
        // const showOnboarding = localStorage.getItem('showOnboardingProcess');
        setState({
            // showOnboardingProcess: showOnboarding !== 'no'
            showOnboardingProcess: false
        });
        return () => {
            setState({
                ...initialState
            });
        };
    }, []);
    useEffect(() => {
        determineActiveMenu();
    }, [window.location.href]);

    return (
        <div className="sidebar ">
            <div className={`w-full px-[21px] mb-[19px] sidebar-content relative ${showOnboardingProcess ? '' : ''}`}>
                <div
                    className={` pb-[15px]  md:bg-white bg-white  flex  w-[215px] items-center gap-x-[9px] pt-5 z-[20] top-[0px] sidebar-logo-container md:fixed  ${
                        sideBarType === appSideBarType?.FULL ? 'w-[215px]  border-b border-[#E6E6E6] ' : 'md:w-[35px]'
                    } `}
                >
                    <img src={slimLogoIcon} alt="Slim Logo" className="  ml-[4.5px]    " />
                    <span className={`${sideBarType === appSideBarType?.FULL ? '' : 'hidden slim-logo'}`}>{xpressRunSvg()}</span>
                </div>
                <div className="mt-5 md:mt-[85px]">
                    {sidebarMenu
                        .filter((item: any) => !item.disabled)
                        .map((item: any, index: any) => (
                            <div key={index}>
                                {item?.hasChildren ? (
                                    <>
                                        <div
                                            className={`text-center side-bar-links  w-full py-[9.5px] px-[12px]  ${
                                                index === 0 ? 'first-sidebar-link' : 'sidebar-link text-[16px]'
                                            } ${getMatch(item.match) ? 'active-sidebar-link font-semi-bold mb-[8px]' : 'mb-[15px]'}  ${
                                                item?.opened ? 'mb-[7.9px]' : ''
                                            } side-bar-nav-${item?.icon}`}
                                            key={index}
                                        >
                                            <div
                                                className="flex pointer justify-between items-center"
                                                onClick={() => handleMenuClick(index)}
                                            >
                                                <div className="flex items-center gap-x-2.5">
                                                    <span className="sidebar-icon-container">
                                                        {' '}
                                                        <img src={item?.icon} alt="" className="w-[20px] h-[20px]" />
                                                    </span>
                                                    <div
                                                        className={`flex gap-x-2 items-center menu-text ${
                                                            sideBarType === appSideBarType?.FULL ? '' : 'hidden'
                                                        }`}
                                                    >
                                                        <div>{item?.name}</div>
                                                        {item?.extraText && (
                                                            <div className="text-xs green-text font-bold new-feature">
                                                                {item?.extraText}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <ChevronRight
                                                    size={18}
                                                    strokeWidth={2}
                                                    color="#19094C"
                                                    className={`${item?.opened ? 'rotate-sidebar-chevron' : ''} ${
                                                        sideBarType === appSideBarType?.FULL ? '' : 'hidden'
                                                    }   sidebar-chevron menu-text duration-100`}
                                                />
                                            </div>
                                        </div>
                                        {/* {item?.opened && ( */}
                                        <div className={`${sideBarType === appSideBarType?.FULL ? '' : 'hidden'} child-menu-items-hover`}>
                                            <div
                                                className={`pl-[16px] rounded  pb-3  mt-[-0px] duration-100  ${
                                                    item?.opened ? '' : 'hidden'
                                                } `}
                                            >
                                                {item.children?.map((child: any, _i: number) => (
                                                    <div
                                                        className="flex pointer justify-between items-center pl-3  mt-1"
                                                        key={`index-${_i}`}
                                                    >
                                                        <div className="flex items-start pl-0">
                                                            <Link
                                                                to={child.path}
                                                                className={` child-link text-[16px] ${
                                                                    getMatch(child.match) ? ' default-color font-semi-bold' : 'font-normal'
                                                                }`}
                                                                onClick={() => setAppSideBarState(item?.slimSideBar)}
                                                            >
                                                                {child?.name}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {/* )} */}
                                    </>
                                ) : (
                                    <>
                                        {item?.divider && <div className="border-t-[1px] border-[#E6E6E6] my-[16px]" />}
                                        <div
                                            className={`text-center side-bar-links flex items-center py-[9.5px] px-[12px] mb-[15px] ${
                                                index === 0 ? 'first-sidebar-link' : 'sidebar-link'
                                            } ${getMatch(item.match) ? 'active-sidebar-link font-semi-bold ' : ''} side-bar-nav-${
                                                item?.icon
                                            }`}
                                            key={index}
                                        >
                                            <Link
                                                to={item.path}
                                                className="flex items-center gap-x-2.5 w-full"
                                                onClick={() => setAppSideBarState(item?.slimSideBar)}
                                            >
                                                <div className="sidebar-icon-container w-[20px] h-[20px]">
                                                    <img src={item?.icon} alt="" className="w-[20px] h-[20px]" />
                                                </div>
                                                <div
                                                    className={`flex gap-x-2 items-center menu-text ${
                                                        sideBarType === appSideBarType?.FULL ? '' : 'hidden'
                                                    }`}
                                                >
                                                    <div>{item?.name}</div>
                                                    {item?.extraText && (
                                                        <div className="text-xs green-text font-bold">{item?.extraText}</div>
                                                    )}
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                </div>
            </div>
            <div className=" w-full px-[21px] mb-[30px]">
                {showOnboardingProcess && (
                    <div
                        className={`bg-[#F9F8FE] pt-[10px] pb-[12px] px-[12px] rounded-[4px] hover-block ${
                            sideBarType === appSideBarType?.FULL ? '' : 'hidden'
                        }`}
                    >
                        <div className="onboarding-progress-container">
                            <div
                                className="circular-progress"
                                style={{ background: `conic-gradient(#7d2ae8  ${getOnBoardingPercentage() * 3.6}deg, #ededed 0deg)` }}
                            >
                                <span className="progress-value text-[12px] text-[#19094C] font-semi-bold">
                                    {getOnBoardingPercentage()}%
                                </span>
                            </div>
                        </div>
                        <div className="font-semi-bold text-[14px] default-color mt-[9px]">Integration setup </div>
                        <div className="text-[#6D6B75] text-[12px]">
                            Incomplete integration setup. Proceed with integrating your e-commerce store.
                        </div>
                        <div className="flex mt-[10px] gap-x-[10px] text-[12px]">
                            <AnimateElement className="text-[#6D6B75] pointer">
                                <div onClick={() => localStorage.setItem('showOnboardingProcess', 'no')}>Dismiss</div>
                            </AnimateElement>
                            <AnimateElement className="app-color pointer">
                                <div
                                    onClick={() => {
                                        navigate('/settings/my-shops');
                                        update({ sideBarType: appSideBarType?.SLIM });
                                    }}
                                >
                                    Continue
                                </div>
                            </AnimateElement>{' '}
                        </div>
                    </div>
                )}
                {/* {!dismissShopInfo && (
                    <div
                        className={`bg-[#F7F6FC] pt-[10px] pb-[12px] px-[12px] rounded-[4px] hover-block ${
                            sideBarType === appSideBarType?.FULL ? '' : 'hidden'
                        }`}
                    >
                        <div>
                            <img src={ecommerceBasketIcon} alt="" className="rounded-[360px] w-[51px] h-[51px]" />
                        </div>
                        <div className="font-semi-bold text-[14px] default-color mt-[9px]">Power your e-commerce </div>
                        <div className="text-[#6D6B75] text-[12px]">
                            Integrate your online shop to activate same-day delivery at checkout.
                        </div>
                        <div className="flex mt-[10px] gap-x-[10px] text-[12px]">
                            <AnimateElement className="text-[#6D6B75] pointer">
                                <div onClick={() => setState({ dismissShopInfo: !dismissShopInfo })}>Dismiss</div>
                            </AnimateElement>
                            <AnimateElement className="app-color pointer">
                                <div
                                    onClick={() => {
                                        navigate('/settings/my-shops');
                                        update({ sideBarType: appSideBarType?.SLIM });
                                    }}
                                >
                                    Continue
                                </div>
                            </AnimateElement>{' '}
                        </div>
                    </div>
                )} */}
                <div className="w-full relative mb-[60px] md:mb-0">
                    <div className="flex items-center w-full justify-between mt-[36px] px-3">
                        <div className="flex gap-x-2.5 items-center">
                            <div className="">
                                <img src={userIcon} alt="" />
                            </div>
                            <div className={`${sideBarType === appSideBarType?.FULL ? '' : 'hidden'} business-info`}>
                                {truncateMultilineText(getBusinessInfo()?.name, 16)}
                            </div>
                        </div>
                        <Menu as="div" className={`${sideBarType === appSideBarType?.FULL ? '' : 'hidden'}  business-info relative`}>
                            <div className="">
                                <AnimateElement>
                                    <Menu.Button className="">
                                        <img src={logoutIcon} alt="" />
                                    </Menu.Button>
                                </AnimateElement>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    static
                                    className="absolute left-[-60px] md:right-0 bottom-[20px] z-50 mt-2  origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    style={{ width: '5.2rem' }}
                                >
                                    <AnimateElement>
                                        <div
                                            className="font-normal py-2 px-3.5 pointer analytics-opt-menu flex gap-x-2 items-center"
                                            onClick={() => keycloak.logout()}
                                        >
                                            Log&nbsp;Out&nbsp;
                                        </div>
                                    </AnimateElement>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                        {/* <AnimateElement
                            className={`pointer ${sideBarType === appSideBarType?.FULL ? '' : 'hidden'} business-info pr-[0px]`}
                        >
                            <img src={logoutIcon} alt="" />
                        </AnimateElement> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
