import { useKeycloak } from '@react-keycloak/web';
import { IntegrationUrl } from 'constants/integrationUrl';
import { useNavigate, useSearchParams } from 'react-router-dom';

const SecuredRoute = ({ children }) => {
    const [searchParams] = useSearchParams();
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const isLoggedIn = keycloak.authenticated;

    if (searchParams.get('token') && isLoggedIn) {
        navigate(`${IntegrationUrl.REDIRECT_URL}?${searchParams}`);
    }

    return isLoggedIn ? children : keycloak.login();
};

export default SecuredRoute;
