/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useReducer } from 'react';
import DatePicker from 'sassy-datepicker';
import calendarIcon from 'assets/icons/order_icons/order_calendar.svg';
import moment from 'moment';

const ScheduleDatePicker = (props: any) => {
    const { label, placeholder, date, onChange, objKey = '' } = props;
    const initialState = {
        showDatePicker: false,
        data: date || new Date()
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { showDatePicker, data } = state;
    const toggleDatePicker = () => {
        setState({
            showDatePicker: !showDatePicker
        });
    };
    const handleDateChange = (newDate: Date) => {
        onChange(newDate, objKey);
        setState({
            data: newDate
        });
        toggleDatePicker();
    };

    return (
        <div>
            <div className="flex gap-x-[20px] flex-wrap items-center">
                {label && <div>{label}</div>}
                <div className="relative">
                    <div
                        className="min-w-[142px] bg-[#F1F3F4] py-[8px] px-[10px] pointer flex justify-between rounded"
                        onClick={() => toggleDatePicker()}
                    >
                        <span className="text-[14.3px] mr-3">{moment(data).format('MMMM Do YYYY')}</span>
                        <img src={calendarIcon} alt="" />
                    </div>
                    {showDatePicker && (
                        <DatePicker
                            value={data}
                            onChange={handleDateChange}
                            minDate={new Date()}
                            // weekStartsFrom="Sunday"
                            className="absolute z-50  top-[20px]"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ScheduleDatePicker;
