/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useReducer, useState } from 'react';
import { Modal } from 'flowbite-react/lib/esm/components/Modal';
import DatePicker from 'sassy-datepicker';
import './datepicker.scss';
import Button from 'components/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import calendarIcon from 'assets/icons/order_icons/order_calendar.svg';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import FormToogle from 'components/FormToogle';
import { AnimateSharedLayout, motion } from 'framer-motion';
import '../create_delivery.scss';
import ModalDropDown from './ModalDropdown';
import ScheduleDatePicker from './ScheduleDatePicker';
import AnimateElement from 'ui-components/AnimateElement';
import { daysOfTheMonth, monthsOfTheYear, recurringTypes } from 'data';
import { useApp } from 'app-context';
import { getUrlParams, isObjectEmpty } from 'libs';

interface ScheduleDeliveryProps {
    open: boolean;
    edit?: boolean;
    toggleModal: Function;
    viewOrderSchedule?: boolean;
    updateParentData?: Function;
}
const recurringTypesArray = [
    {
        label: 'Daily',
        value: recurringTypes.DAILY
    },
    {
        label: 'Weekly',
        value: recurringTypes.WEEKLY
    },
    {
        label: 'Monthly',
        value: recurringTypes.MONTHLY
    },
    {
        label: 'Yearly',
        value: recurringTypes.YEARLY
    }
    // {
    //     label: 'Custom',
    //     value: recurringTypes.CUSTOM
    // }
];

const operators = {
    NEVER: 'never',
    AFTER: 'after',
    ON: 'on'
};
const operatorArray = [
    {
        label: 'Never',
        value: operators.NEVER
    },
    {
        label: 'After',
        value: operators.AFTER
    },
    {
        label: 'On',
        value: operators.ON
    }
];

const weekDays = [
    {
        label: 'S',
        key: 'Sunday',
        value: 'SUN'
    },
    {
        label: 'M',
        key: 'Monday',
        value: 'MON'
    },
    {
        label: 'T',
        key: 'Tuesday',
        value: 'TUE'
    },
    {
        label: 'W',
        key: 'Wednesday',
        value: 'WED'
    },
    {
        label: 'T',
        key: 'Thursday',
        value: 'THU'
    },
    {
        label: 'F',
        key: 'Friday',
        value: 'FRI'
    },
    {
        label: 'S',
        key: 'Saturday',
        value: 'SAT'
    }
];

const ScheduleDelivery = (props: ScheduleDeliveryProps): JSX.Element => {
    const { update, recurringOrderData } = useApp() as AppContextType;
    const { open, toggleModal, viewOrderSchedule, updateParentData, edit = false } = props;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(1);
    const initialState = {
        date: new Date(),
        time: moment(new Date()),
        showDateSelection: false,
        showTimeSelection: false,
        scheduleObj: {
            date: new Date(),
            pickupTime: new Date()
        },
        dailySelectionObj: {
            startDate: new Date(),
            endDate: new Date(),
            pickupTime: new Date(),
            operator: operatorArray[0]
        },
        weeklySelectionObj: {
            startDate: new Date(),
            endDate: new Date(),
            pickupTime: new Date(),
            operator: operatorArray[0],
            selectedDays: []
        },
        monthlySelectionObj: {
            startDate: new Date(),
            dayOfTheMonth: daysOfTheMonth[0],
            endDate: new Date(),
            pickupTime: new Date(),
            operator: operatorArray[0]
        },
        yearlySelectionObj: {
            startDate: new Date(),
            monthOfTheYear: monthsOfTheYear[0],
            dayOfTheMonth: daysOfTheMonth[0],
            endDate: new Date(),
            pickupTime: new Date(),
            operator: operatorArray[0]
        },
        customSelectionObj: {},
        showRecurringMode: false,
        recurringRepeatType: recurringTypesArray[0],
        isLoading: false
    };

    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const {
        date,
        scheduleObj,
        showDateSelection,
        showTimeSelection,
        showRecurringMode,
        recurringRepeatType,
        dailySelectionObj,
        weeklySelectionObj,
        monthlySelectionObj,
        yearlySelectionObj,
        isLoading
    } = state;

    const toggleDatePicker = () => {
        setState({
            showDateSelection: !showDateSelection
        });
    };
    const toggleTimePicker = () => {
        setState({
            showTimeSelection: !showTimeSelection
        });
    };

    const handleSchedule = (date: any) => {
        // const { _d } = time;
        // date.setHours(_d.getHours());
        // date.setMinutes(_d.getMinutes());
        // const schedule_date: string = moment(date).format('yyyy-MM-DD HH:mm');
        // if (searchParams.has('schedule_date')) {
        //     searchParams.delete('schedule_date');
        // }
        // searchParams.append('schedule_date', schedule_date);
        // navigate({ pathname: '/orders/create/schedule', search: `?${searchParams.toString()}` });
        // toggleModal();
        setState({
            scheduleObj: {
                ...scheduleObj,
                date
            }
        });
        toggleDatePicker();
    };
    const handleScheduleTime = (time: any) => {
        setState({
            scheduleObj: {
                ...scheduleObj,
                pickupTime: time
            }
        });
    };

    const toogleRecurringMode = () => {
        setState({
            showRecurringMode: !showRecurringMode
        });
    };

    const updateRecurringType = (type: any) => {
        setState({
            recurringRepeatType: type
        });
    };

    // Action methods
    const handleScheduleAction = async (type: string | null) => {
        // if(!viewOrderSchedule){}
        let recurringOrderDataObj: any = {};
        switch (type) {
            case recurringTypes.DAILY:
                recurringOrderDataObj = {
                    type: recurringTypes.DAILY,
                    payload: dailySelectionObj
                };
                break;
            case recurringTypes.WEEKLY:
                recurringOrderDataObj = {
                    type: recurringTypes.WEEKLY,
                    payload: weeklySelectionObj
                };
                break;
            case recurringTypes.MONTHLY:
                recurringOrderDataObj = {
                    type: recurringTypes.MONTHLY,
                    payload: monthlySelectionObj
                };
                break;
            case recurringTypes.YEARLY:
                recurringOrderDataObj = {
                    type: recurringTypes.YEARLY,
                    payload: yearlySelectionObj
                };
                break;

            default:
                recurringOrderDataObj = {
                    type: 'schedule',
                    payload: scheduleObj
                };
                break;
        }
        // Remove endDate if operator is never
        if (recurringOrderDataObj?.payload?.operator?.value === 'never') {
            recurringOrderDataObj.payload.endDate = null;
        }
        if (!viewOrderSchedule) {
            update({
                recurringOrderData: recurringOrderDataObj
            });
            let addressStr = '/orders/create/schedule';
            const getUrlParam: any = getUrlParams();
            if (getUrlParam?.repost_id) {
                addressStr = `/orders/create/schedule?repost_id=${getUrlParam?.repost_id}`;
            }
            navigate(addressStr);
        } else {
            update({
                recurringOrderData: recurringOrderDataObj
            });
            // Might be sending a backend request
            try {
                setState({
                    isLoading: true
                });
                // @ts-ignore
                await updateParentData(recurringOrderDataObj);
            } catch (error) {
                // Empty
            } finally {
                setState({
                    isLoading: false
                });
            }
        }
        toggleModal();
    };
    const scheduleView = () => (
        <div>
            <div className="font-bold text-lg">Scheduling</div>
            <div className="flex gap-x-[20px] flex-wrap mt-[25px] items-center">
                <div>Choose delivery date </div>
                <div className="relative">
                    <div
                        className="min-w-[142px] bg-[#F1F3F4] py-[8px] px-[10px] pointer flex justify-between rounded"
                        onClick={() => toggleDatePicker()}
                    >
                        <span className="text-[14.3px]"> {moment(scheduleObj?.date).format('MMMM Do YYYY')}</span>
                        <img src={calendarIcon} alt="" />
                    </div>
                    {showDateSelection && (
                        <DatePicker
                            value={scheduleObj?.date}
                            onChange={(date: any) => handleSchedule(date)}
                            minDate={new Date()}
                            // weekStartsFrom="Monday"
                            className="absolute z-50  top-[20px]"
                        />
                    )}
                </div>
            </div>
            <div className="flex gap-x-[20px] mt-[20px] items-center">
                <div>Choose delivery pick up time </div>
                <div className="relative order-time-picker">
                    <TimePicker
                        showSecond={false}
                        use12Hours
                        className=" w-[142px] bg-[#F1F3F4] py-[6px] px-[10px] pointer flex justify-between rounded"
                        defaultValue={moment(new Date())}
                        value={moment(scheduleObj?.pickupTime)}
                        onChange={(date: any) => handleScheduleTime(date)}
                    />
                </div>
            </div>
            <div className="mt-[30px]">
                <FormToogle text="Make delivery recurring" textClass="text-[#667489] text-base" onChange={() => toogleRecurringMode()} />
            </div>
            <div className="flex gap-8 justify-end items-center mt-[30px]">
                <Button text="Cancel" className="font-semi-bold" action={toggleModal} />
                <Button
                    text="Continue"
                    action={() => handleScheduleAction('')}
                    className="app-btn-border-0 font-semi-bold px-[34px] py-[10px] rounded-md"
                />
            </div>
        </div>
    );
    //  Daily method
    const updateDailySelectionOperatorType = (type: string) => {
        setState({
            dailySelectionObj: {
                ...dailySelectionObj,
                operator: type
            }
        });
    };
    const handleDailyDataChange = (data: any, key: string) => {
        setState({
            dailySelectionObj: {
                ...dailySelectionObj,
                [key]: data
            }
        });
    };

    const updateWeeklySelectionOperatorType = (type: string) => {
        setState({
            weeklySelectionObj: {
                ...weeklySelectionObj,
                operator: type
            }
        });
    };

    // Weekly Methods
    const addWeekDayToList = (day: any) => {
        let newArray = [...weeklySelectionObj.selectedDays];
        if (newArray.includes(day)) {
            newArray = newArray.filter((data) => data !== day);
        } else {
            newArray.push(day);
        }
        setState({
            weeklySelectionObj: {
                ...weeklySelectionObj,
                selectedDays: newArray
            }
        });
    };

    const handleWeeklyDataChange = (data: any, key: string) => {
        setState({
            weeklySelectionObj: {
                ...weeklySelectionObj,
                [key]: data
            }
        });
    };

    // Monthly Methods
    const updateMonthlyDayOfTheMonth = (day: any) => {
        setState({
            monthlySelectionObj: {
                ...monthlySelectionObj,
                dayOfTheMonth: day
            }
        });
    };

    const handleMonthlyDataChange = (data: any, key: string) => {
        setState({
            monthlySelectionObj: {
                ...monthlySelectionObj,
                [key]: data
            }
        });
    };

    const updateMonthlySelectionOperatorType = (type: any) => {
        setState({
            monthlySelectionObj: {
                ...monthlySelectionObj,
                operator: type
            }
        });
    };

    // Yearly Methods
    const updateYearlyMonthOfTheYear = (data: any) => {
        setState({
            yearlySelectionObj: {
                ...yearlySelectionObj,
                monthOfTheYear: data
            }
        });
    };

    const handleYearlyDataChange = (data: any, key: string) => {
        setState({
            yearlySelectionObj: {
                ...yearlySelectionObj,
                [key]: data
            }
        });
    };

    const updateYearlyDayOfMonth = (data: any) => {
        setState({
            yearlySelectionObj: {
                ...yearlySelectionObj,
                dayOfTheMonth: data
            }
        });
    };

    const renderDailyRecurringView = () => (
        <div className="mt-[26px]">
            <div className="flex items-center gap-x-[10px] flex-wrap">
                <div>Repeat this delivery</div>
                <div>
                    <ModalDropDown options={recurringTypesArray} value={recurringRepeatType} onChange={updateRecurringType} />
                </div>
            </div>
            <div className="flex items-center flex-wrap gap-y-5 md:gap-y-0 gap-x-[10px] mt-[20px]">
                <div>
                    <ScheduleDatePicker
                        label="Start on"
                        objKey="startDate"
                        onChange={handleDailyDataChange}
                        date={dailySelectionObj?.startDate}
                    />
                </div>
                <div className="flex items-center gap-x-[10px]">
                    <div>and end</div>
                    <div>
                        <ModalDropDown
                            options={operatorArray}
                            value={dailySelectionObj?.operator}
                            onChange={updateDailySelectionOperatorType}
                        />
                    </div>
                </div>
            </div>
            {dailySelectionObj?.operator?.value !== operators.NEVER && (
                <div className="mt-[15px]">
                    <ScheduleDatePicker
                        label=""
                        date={dailySelectionObj?.endDate}
                        onChange={handleDailyDataChange}
                        objKey="endDate"
                        placeholder="End date"
                    />
                </div>
            )}
            <div className="flex gap-x-[20px] flex-wrap gap-y-5 mt-[20px] items-center">
                <div>Pick-up time </div>
                <div className="relative order-time-picker">
                    <TimePicker
                        showSecond={false}
                        use12Hours
                        className=" w-[142px] bg-[#F1F3F4] py-[6px] px-[10px] pointer flex justify-between rounded"
                        defaultValue={moment(dailySelectionObj?.pickupTime)}
                        value={moment(dailySelectionObj?.pickupTime)}
                        onChange={(date: any) => handleDailyDataChange(date, 'pickupTime')}
                    />
                </div>
            </div>
        </div>
    );

    const renderWeeklyRecurringView = () => (
        <div className="mt-[26px]">
            <div className="flex items-center flex-wrap gap-y-5 gap-x-[10px]">
                <div>Repeat this delivery</div>
                <div>
                    <ModalDropDown options={recurringTypesArray} value={recurringRepeatType} onChange={updateRecurringType} />
                </div>
            </div>
            <div className="mt-[20px] flex items-center  flex-wrap gap-y-5 gap-x-[10px]">
                <div>Every</div>
                <div className="flex items-center gap-x-[7px]">
                    {weekDays.map((weekDay: any, _i: number) => (
                        <AnimateElement
                            className={`pointer w-[29px] h-[29px] rounded-[360px] border border-[#DDDDDD] ${
                                weeklySelectionObj?.selectedDays.includes(weekDay?.value) ? 'app-bg text-white' : ''
                            }`}
                        >
                            <div
                                className="text-[14px] w-full h-full flex justify-center items-center"
                                onClick={() => addWeekDayToList(weekDay.value)}
                            >
                                {weekDay?.label}
                            </div>
                        </AnimateElement>
                    ))}
                </div>
            </div>
            <div className="flex items-center flex-wrap gap-y-5 gap-x-[10px] mt-[20px]">
                <div>
                    <ScheduleDatePicker
                        label="Start on"
                        objKey="startDate"
                        onChange={handleWeeklyDataChange}
                        date={weeklySelectionObj?.startDate}
                    />
                </div>
                <div className="flex items-center gap-x-[10px]">
                    <div>and end</div>
                    <div>
                        <ModalDropDown
                            options={operatorArray}
                            value={weeklySelectionObj?.operator}
                            onChange={updateWeeklySelectionOperatorType}
                        />
                    </div>
                </div>
            </div>
            {weeklySelectionObj?.operator?.value !== operators.NEVER && (
                <div className="mt-[15px]">
                    <ScheduleDatePicker label="" objKey="endDate" onChange={handleWeeklyDataChange} date={weeklySelectionObj?.endDate} />
                </div>
            )}
            <div className="flex gap-x-[20px] flex-wrap gap-y-5 mt-[20px] items-center">
                <div>Pick-up time </div>
                <div className="relative order-time-picker">
                    <TimePicker
                        showSecond={false}
                        use12Hours
                        className=" w-[142px] bg-[#F1F3F4] py-[6px] px-[10px] pointer flex justify-between rounded"
                        defaultValue={moment(weeklySelectionObj?.pickupTime)}
                        value={moment(weeklySelectionObj?.pickupTime)}
                        onChange={(date: any) => handleWeeklyDataChange(date, 'pickupTime')}
                    />
                </div>
            </div>
        </div>
    );
    const renderMonthlyRecurringView = () => (
        <div className="mt-[26px]">
            <div className="flex items-center gap-x-[10px]">
                <div>Repeat this delivery</div>
                <div className="">
                    <ModalDropDown options={recurringTypesArray} value={recurringRepeatType} onChange={updateRecurringType} />
                </div>
                <div>on the</div>
                <div className="">
                    <ModalDropDown
                        options={daysOfTheMonth}
                        value={monthlySelectionObj?.dayOfTheMonth}
                        onChange={updateMonthlyDayOfTheMonth}
                    />
                </div>
                <div>day of every month</div>
            </div>
            <div className="flex items-center gap-x-[10px] mt-[20px]">
                <div>
                    <ScheduleDatePicker
                        label="Start on"
                        objKey="startDate"
                        onChange={handleMonthlyDataChange}
                        date={monthlySelectionObj?.startDate}
                    />
                </div>
                <div className="flex items-center gap-x-[10px]">
                    <div>and end</div>
                    <div>
                        <ModalDropDown
                            options={operatorArray}
                            value={monthlySelectionObj?.operator}
                            onChange={updateMonthlySelectionOperatorType}
                        />
                    </div>
                </div>
            </div>
            {monthlySelectionObj?.operator?.value !== operators.NEVER && (
                <div className="mt-[15px]">
                    <ScheduleDatePicker label="" objKey="endDate" onChange={handleMonthlyDataChange} date={monthlySelectionObj?.endDate} />
                </div>
            )}
            <div className="flex gap-x-[20px] mt-[20px] items-center">
                <div>Pick-up time </div>
                <div className="relative order-time-picker">
                    <TimePicker
                        showSecond={false}
                        use12Hours
                        className=" w-[142px] bg-[#F1F3F4] py-[6px] px-[10px] pointer flex justify-between rounded"
                        defaultValue={moment(monthlySelectionObj?.pickupTime)}
                        value={moment(monthlySelectionObj?.pickupTime)}
                        onChange={(date: any) => handleMonthlyDataChange(date, 'pickupTime')}
                    />
                </div>
            </div>
        </div>
    );

    const renderYearlyRecurringView = () => (
        <div className="mt-[26px]">
            <div className="flex items-center gap-x-[10px]">
                <div>Repeat this delivery</div>
                <div className="">
                    <ModalDropDown options={recurringTypesArray} value={recurringRepeatType} onChange={updateRecurringType} />
                </div>
                <div>every</div>
                <div className="">
                    <ModalDropDown
                        options={monthsOfTheYear}
                        value={yearlySelectionObj?.monthOfTheYear}
                        onChange={updateYearlyMonthOfTheYear}
                    />
                </div>
            </div>
            <div className="flex items-center gap-x-[10px] mt-[20px]">
                <div>On the </div>
                <div className="">
                    <ModalDropDown options={daysOfTheMonth} value={yearlySelectionObj?.dayOfTheMonth} onChange={updateYearlyDayOfMonth} />
                </div>
                <div>day of the month</div>
            </div>
            <div className="flex items-center gap-x-[10px] mt-[20px]">
                <div>
                    <ScheduleDatePicker
                        label="Start on"
                        objKey="startDate"
                        onChange={handleYearlyDataChange}
                        date={yearlySelectionObj?.startDate}
                    />
                </div>
                <div className="flex items-center gap-x-[10px]">
                    <div>and end</div>
                    <div>
                        <ModalDropDown
                            options={operatorArray}
                            value={yearlySelectionObj?.operator}
                            onChange={updateMonthlySelectionOperatorType}
                        />
                    </div>
                </div>
            </div>
            {monthlySelectionObj?.operator?.value !== operators.NEVER && (
                <div className="mt-[15px]">
                    <ScheduleDatePicker label="" objKey="endDate" onChange={handleYearlyDataChange} date={yearlySelectionObj?.startDate} />
                </div>
            )}
            <div className="flex gap-x-[20px] mt-[20px] items-center">
                <div>Pick-up time </div>
                <div className="relative order-time-picker">
                    <TimePicker
                        showSecond={false}
                        use12Hours
                        className=" w-[142px] bg-[#F1F3F4] py-[6px] px-[10px] pointer flex justify-between rounded"
                        defaultValue={moment(yearlySelectionObj?.pickupTime)}
                        value={moment(yearlySelectionObj?.pickupTime)}
                        onChange={(date: any) => handleYearlyDataChange(date, 'pickupTime')}
                    />
                </div>
            </div>
        </div>
    );
    const recurringView = () => {
        switch (recurringRepeatType?.value) {
            case recurringTypes.DAILY:
                return renderDailyRecurringView();
            case recurringTypes.WEEKLY:
                return renderWeeklyRecurringView();

            case recurringTypes.MONTHLY:
                return renderMonthlyRecurringView();

            case recurringTypes.YEARLY:
                return renderYearlyRecurringView();

            case recurringTypes.CUSTOM:
                return renderDailyRecurringView();
            default:
                return renderDailyRecurringView();
        }
    };
    const tabs = [
        {
            title: 'Scheduling',
            key: 'scheduling',
            view: <> {scheduleView()}</>
        },
        {
            title: 'Recurring',
            key: 'recurring',
            view: <>{recurringView()}</>
        }
    ];
    useEffect(() => {
        if (!isObjectEmpty(recurringOrderData))
            switch (recurringOrderData?.type) {
                case recurringTypes.DAILY:
                    setState({
                        showRecurringMode: true,
                        recurringRepeatType: recurringTypesArray[0],
                        dailySelectionObj: recurringOrderData?.payload
                    });
                    break;
                case recurringTypes.WEEKLY:
                    setState({
                        showRecurringMode: true,
                        recurringRepeatType: recurringTypesArray[1],
                        weeklySelectionObj: recurringOrderData?.payload
                    });
                    break;
                case recurringTypes.MONTHLY:
                    setState({
                        showRecurringMode: true,
                        recurringRepeatType: recurringTypesArray[2],
                        monthlySelectionObj: recurringOrderData?.payload
                    });
                    break;
                case recurringTypes.YEARLY:
                    setState({
                        showRecurringMode: true,
                        recurringRepeatType: recurringTypesArray[3],
                        yearlySelectionObj: recurringOrderData?.payload
                    });
                    break;
                case 'schedule':
                    setState({
                        showRecurringMode: false,
                        scheduleObj: {
                            date: new Date(recurringOrderData?.payload?.date),
                            pickupTime: new Date(recurringOrderData?.payload?.pickupTime)
                        }
                    });
                    break;
                default:
                    break;
            }
    }, []);

    return (
        <Modal show={open} size="2xl">
            {showRecurringMode ? (
                <div className="schedule-modal p-[30px]">
                    <AnimateSharedLayout>
                        <div className="tabs-header flex gap-x-[40px]">
                            {tabs.map(({ title, number }: any, i: number) => {
                                const isActive = i === page;
                                return (
                                    <div
                                        key={i}
                                        className={isActive ? 'active-header' : 'non-active-header'}
                                        onClick={() => {
                                            setPage(i);
                                            if (!i) {
                                                setState({
                                                    showRecurringMode: false
                                                });
                                                setPage(1);
                                            }
                                        }}
                                    >
                                        <div className="flex gap-x-2">
                                            <div className="text-[14px]">{title}</div>
                                        </div>
                                        {isActive && <motion.div className="underline" layoutId="underline" />}
                                    </div>
                                );
                            })}
                            <div className="underline-bg " />
                        </div>
                    </AnimateSharedLayout>
                    <div>
                        {tabs[page]?.view}
                        <div className="flex gap-x-8 justify-end items-center mt-[25px]">
                            <Button text="Cancel" className="font-semi-bold" disabled={isLoading} action={toggleModal} />
                            <Button
                                text="Save"
                                action={() => handleScheduleAction(recurringRepeatType?.value)}
                                disabled={isLoading}
                                isLoading={isLoading}
                                className="app-btn-border-0 font-semi-bold px-[34px] py-[10px] rounded-md"
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="px-[30px] py-[30px]"> {scheduleView()}</div>
            )}
        </Modal>
    );
};

export default ScheduleDelivery;
