import React, { useReducer } from 'react';
import { Modal } from 'flowbite-react/lib/esm/components/Modal';
import Button from 'components/Button';
import moment from 'moment';

const EventModal = (props: any) => {
    const {
        toogle,
        show,
        size = 'xl',
        requestInfo,
        headerClass = 'text-lg font-semi-bold text-white',
        modalHeaderClass = 'app-bg',
        ...rest
    } = props;

    const initialState = {};

    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);

    return (
        <Modal show={show} onClose={toogle} size={size} className="individual-contact-modal">
            <Modal.Header className={`modal-header ${modalHeaderClass} text-white py-4`}>
                <div className={headerClass}>Event {requestInfo.request_id} </div>
            </Modal.Header>
            <Modal.Body className="px-3 md:px-6 max-height-80 overflow-y-auto">
                {/* Display the request gateway event information here */}
                <div>
                    <h2>Created At: {moment(new Date(requestInfo?.createdAt)).fromNow()}</h2>
                    <h2>Duration: {requestInfo?.duration ?? 'N/A'} s</h2>
                    <h2>Business ID: {requestInfo?.businessId ?? 'N/A'}</h2>
                    <h2>Headers:</h2>
                    <pre>{JSON.stringify(requestInfo.headers ?? {}, null, 2)}</pre>
                    <h2>Request Method: {requestInfo.requestMethod}</h2>
                    <h2>Request Path: {requestInfo.requestPath}</h2>
                    <h2>Environment: {requestInfo.environment}</h2>
                    <h2>Status Code: {requestInfo.responseStatusCode}</h2>
                    <h2>Request Body:</h2>
                    <pre>{JSON.stringify(requestInfo.requestBody ?? {}, null, 2)}</pre>
                    <h2>Response Body:</h2>
                    <pre>{JSON.stringify(requestInfo.responseBody ?? {}, null, 2)}</pre>
                </div>
            </Modal.Body>
            <div className="flex gap-x-6 py-4 pb-8 px-6 justify-end items-center w-full">
                <Button action={() => toogle()} text="Close" className="app-outline-btn rounded-md px-4 py-2" />
            </div>
        </Modal>
    );
};

export default EventModal;
