const GM_API_KEY = 'AIzaSyCequMZwfIEciz3mIk8RGItDr-Isdu8N1M';

export const GM_OPTIONS = {
    fields: ['address_components', 'geometry', 'icon', 'name'],
    strictBounds: false,
    types: ['address']
};

export default GM_API_KEY;

export const OUTSIDE_LIMIT = 100;
