/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { capitalizeFirstLetter, changeStringToLowerCase, replaceUnderScore } from 'libs';
import React from 'react';
import './badge.scss';

const AppBagde = (props: BadgeType) => {
    const { type, text, className = '', handleClick, capitalize = true } = props;
    return (
        <>
            {/* @ts-ignore */}
            {handleClick !== null ? (
                <span className={`no-wrap p-1 badge-${changeStringToLowerCase(type)} ${className}`} onClick={handleClick}>
                    {capitalize ? capitalizeFirstLetter(changeStringToLowerCase(replaceUnderScore(text))) : replaceUnderScore(text)}
                </span>
            ) : (
                <span className={`no-wrap p-1 badge-${changeStringToLowerCase(type)} ${className}`}>
                    {changeStringToLowerCase(replaceUnderScore(text))}
                </span>
            )}
        </>
    );
};

export default AppBagde;
