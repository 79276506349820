import { ApiEndpoints } from 'constants/apiRoutes';
import { appSideBarType } from 'data';
import HttpService from 'http-service/Http-service';
import { getUserInfo } from 'keycloak';
import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import XpressrunLoader from 'ui-components/XpressrunLoader';

const ApiDatas = [
    { state: 'cards', url: ApiEndpoints.CARD },
    { state: 'addresses', url: ApiEndpoints.ADDRESS },
    { state: 'businesses', url: ApiEndpoints.BUSINESS },
    { state: 'deliveryCategories', url: `${ApiEndpoints.DRIVER}/categories` },
    { state: 'users', url: ApiEndpoints.USERS },
    { state: 'trackings', url: ApiEndpoints.TRACKING },
    { state: 'notifications', url: ApiEndpoints.NOTIFICATION },
    { state: 'invitations', url: ApiEndpoints.INVITATION },
    { state: 'stores', url: ApiEndpoints.STORE },
    { state: 'tokens', url: ApiEndpoints.TOKENS },
    { state: 'webhooks', url: ApiEndpoints.WEBHOOKS },
    { state: 'allCouriers', url: ApiEndpoints.DELIVERY_PROVIDERS },
    { state: 'contacts', url: `${ApiEndpoints.CONTACTS}?paginate=false` }
    // { state: 'campaigns', url: ApiEndpoints.MARKETING_CAMPAIGNS }
    // { state: 'del'}
];
const AppContext = createContext<AppContextType | null>(null);

export const AppProvider = ({ children }: any) => {
    const initialState = {
        cards: [],
        addresses: [],
        deliveryCategories: [],
        users: [],
        businesses: [],
        trackings: [],
        notifications: [],
        invitations: [],
        stores: [],
        allCouriers: [],
        tokens: [],
        webhooks: [],
        contacts: [],
        storeLocations: [],
        selectedBusiness: '',
        products: { metadata: { total_products: 0, page: 1 }, data: [] },
        deliveryCouriers: [],
        orderBulkData: {
            errors: {},
            data: []
        },
        importedOrder: {},
        selectedStoreObj: null,
        recurringOrderData: {},
        newCampaignName: '',
        selectedSMSCampaignTemplate: null,
        loading: true,
        sideBarType: appSideBarType?.FULL,
        update: () => {}
    };

    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { loading, selectedBusiness, ...restState } = state;
    const fetch = async (_state: string, url: string) => {
        const apiRequest = await new HttpService(url).getAll();
        let data = _state === 'notifications' ? apiRequest?.data : apiRequest?.data?.data;
        data = _state === 'contacts' ? apiRequest?.data?.data?.data : data;
        setState({ [_state]: data ?? [] } as any);
    };

    const fetchAll = async () => {
        setState({ loading: true });
        try {
            await Promise.all(ApiDatas.map(({ state, url }) => fetch(state, url)));
        } catch (error) {
            // Empty
        }

        setState({ loading: false });
    };

    const getBusinessInfo = (business_id?: string) =>
        business_id
            ? restState.businesses.find((business: any) => business.id === business_id)
            : restState.businesses.find((business: any) => business.id === getUserInfo()?.business_id);

    useEffect(() => {
        fetchAll();
    }, []);

    useEffect(() => {
        const locationsArray = [];
        for (let index = 0; index < state.stores.length; index++) {
            const element = state.stores[index];
            // eslint-disable-next-line no-unsafe-optional-chaining
            locationsArray.push(...element?.pickupLocations);
        }
        setState({
            ...state,
            storeLocations: locationsArray
        });
    }, [state.stores]);

    const values = useMemo(() => ({ ...restState, selectedBusiness, update: setState, getBusinessInfo }), [state]);
    return loading ? <XpressrunLoader /> : <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export const useApp = () => useContext(AppContext);
