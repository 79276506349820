/* eslint-disable import/prefer-default-export */
import uberEatsIcon from 'assets/icons/uber_eats.svg';
import roadieIcon from 'assets/icons/roadie.svg';
import doordashIcon from 'assets/icons/doordash.svg';
import masterCardIcon from 'assets/icons/mastercard.svg';
import visaCardIcon from 'assets/icons/visa.svg';
import product1 from 'assets/images/product_1.png';
import product2 from 'assets/images/product_2.png';
import product3 from 'assets/images/product_3.png';
import wooCommerceIcon from 'assets/icons/woo_commerce.svg';
import bigCommerceIcon from 'assets/icons/big_commerce_logo.svg';
import shopifyIcon from 'assets/icons/shopify.svg';
import smallPackageIcon from 'assets/icons/small_package.svg';
import mediumPackageIcon from 'assets/icons/medium_package.svg';
import largePackageIcon from 'assets/icons/large_package.svg';
import xtraLargePackageIcon from 'assets/icons/xtra_large.svg';
import totalOderIcon from 'assets/icons/total_order.svg';
import pendingOderIcon from 'assets/icons/pending_orders.svg';
import completedOderIcon from 'assets/icons/completed_orders.svg';
import moneyIcon from 'assets/icons/money.svg';
import logoIcon from 'assets/icons/sidebar/slim_logo.svg';

export const courierData = [
    {
        header: 'Doordash',
        helperText: 'ETA 10mins [Same day delivery]',
        icon: doordashIcon
    },
    {
        header: 'Roadie',
        helperText: 'ETA 20mins [Same day delivery]',
        icon: roadieIcon
    },
    {
        header: 'Frayt',
        helperText: 'ETA 10mins [Same day delivery]',
        icon: uberEatsIcon
    }
];

export const packageSizeData = [
    {
        header: 'Small',
        helperText: 'Up to 20lbs. Fits in shoebox',
        icon: smallPackageIcon
    },
    {
        header: 'Medium',
        helperText: '21 - 50lbs. Fits in front seat',
        icon: mediumPackageIcon
    },
    {
        header: 'Large',
        helperText: 'Over 50lbs. Fits in backseat / trunk',
        icon: largePackageIcon
    },
    {
        header: 'Extra_Large',
        helperText: 'Oversized item. Fits in SUV',
        icon: xtraLargePackageIcon
    }
];

export const paymentCardData = [
    {
        card: 'Master Card',
        cardNumber: '** 4523',
        cardName: 'Warren Guyuna',
        icon: masterCardIcon,
        status: 'active'
    },
    {
        card: 'Master Card',
        cardNumber: '** 4523',
        cardName: 'Warren Guyuna',
        icon: visaCardIcon,
        status: 'active'
    }
];

export const productsData = [
    {
        id: '1',
        productName: 'Adidas Nmd',
        description: 'Original adiddas foot wa...',
        variant_id: '4225-776-3234',
        weight: '3lbs',
        dimension: '4 X 4 X 4',
        imgSrc: product1
    },
    {
        id: '2',
        productName: 'Adidas Nmd',
        description: 'Original adiddas foot wa...',
        variant_id: '4225-776-3234',
        weight: '3lbs',
        dimension: '4 X 4 X 4',
        imgSrc: product2
    },
    {
        id: '3',
        productName: 'Adidas Nmd',
        description: 'Original adiddas foot wa...',
        variant_id: '4225-776-3234',
        weight: '3lbs',
        dimension: '4 X 4 X 4',
        imgSrc: product3
    }
];

export const appListingData = [
    {
        id: '1',
        name: 'Shopify',
        description: 'Join the worlds best shopping',
        url: 'https://apps.shopify.com/xpressrun',
        imgSrc: shopifyIcon
    },
    {
        id: '2',
        name: 'Woo commerce',
        url: 'https://wordpress.org/plugins/same-day-delivery',
        description: 'Join the worlds best shopping',
        imgSrc: wooCommerceIcon
    },
    {
        id: '3',
        name: 'BigCommerce',
        // url: 'https://www.bigcommerce.com/apps/categories/?query=local%20delivery',
        description: 'Join the worlds best shopping',
        imgSrc: bigCommerceIcon
    },
    {
        id: '3',
        name: 'Open API',
        url: 'https://docs.xpressrun.com',
        description: 'Join the worlds best shopping',
        imgSrc: logoIcon
    }
];

// export const teamMatesData = [
//     {
//         id: '1',
//         name: 'Kareen Allison',
//         email: 'Kareeallison@xpressrun.com',
//         role: 'Admin'
//     },
//     {
//         id: '2',
//         name: 'Kareen Allison',
//         email: 'Kareeallison@xpressrun.com',
//         role: 'Manager'
//     },
//     {
//         id: '3',
//         name: 'Kareen Allison',
//         email: 'Kareeallison@xpressrun.com',
//         role: 'Manager'
//     }
// ];

// export const addressData = [
//     {
//         id: '1',
//         contact_name: 'Jerry west',
//         email: 'Kareeallison@xpressrun.com',
//         phone: '03458909832',
//         address: '12390 W Faith Ln, Republic, MO 65738, USA'
//     },
//     {
//         id: '1',
//         contact_name: 'Jerry west',
//         email: 'Kareeallison@xpressrun.com',
//         phone: '03458909832',
//         address: '12390 W Faith Ln, Republic, MO 65738, USA'
//     },
//     {
//         id: '1',
//         contact_name: 'Jerry west',
//         email: 'Kareeallison@xpressrun.com',
//         phone: '03458909832',
//         address: '12390 W Faith Ln, Republic, MO 65738, USA'
//     }
// ];

export const notificationOptions = [
    {
        id: '1',
        header: 'Created',
        text: 'The delivery has been created',
        enabled: false,
        attribute: 'created'
    },
    {
        id: '2',
        header: 'Confirmed',
        text: 'The delivery has been confirmed',
        enabled: false,
        attribute: 'confirmed'
    },
    {
        id: '3',
        header: 'Enroute to pickup',
        text: 'The driver is enroute to the pickup location',
        enabled: false,
        attribute: 'toPickup'
    },
    {
        id: '4',
        header: 'Approaching pickup',
        text: 'The driver is approaching the pickup location',
        enabled: false,
        attribute: 'appPickup'
    },
    {
        id: '5',
        header: 'Arrived at pickup ',
        text: 'The driver has arrived at the pickup location',
        enabled: false,
        attribute: 'atPickup'
    },
    {
        id: '6',
        header: 'Picked up',
        text: 'The driver has picked up the items and is heading to the drop off',
        enabled: false,
        attribute: 'pickedUp'
    },
    {
        id: '7',
        header: 'Enroute to dropoff',
        text: 'The driver is en route to the dropoff location.',
        enabled: false,
        attribute: 'toDropoff'
    },
    {
        id: '8',
        header: 'Approaching dropoff',
        text: 'The driver is approaching the dropoff location',
        enabled: false,
        attribute: 'appDropoff'
    },
    {
        id: '9',
        header: 'Arrived at dropoff',
        text: 'The driver has arrived at the dropoff location',
        enabled: false,
        attribute: 'atDropoff'
    },
    {
        id: '10',
        header: 'Delivered',
        text: 'The delivery has been completed.',
        enabled: false,
        attribute: 'delivered'
    },
    {
        id: '11',
        header: 'Cancelled',
        text: 'The delivery has been cancelled.',
        enabled: false,
        attribute: 'canceled'
    }
];

export const fontOptionsData = [
    {
        id: '0',
        label: 'Averta',
        name: 'averta',
        value: 'averta'
    },
    {
        id: '1',
        label: 'Roboto',
        name: 'roboto',
        value: 'roboto'
    },
    {
        id: '2',
        label: 'Open sans',
        name: 'open sans',
        value: 'open sans'
    },
    {
        id: '3',
        label: 'Merriweather',
        name: 'merriweather',
        value: 'merriweather'
    },
    {
        id: '4',
        label: 'Poppins',
        name: 'poppins',
        value: 'poppins'
    },
    {
        id: '5',
        label: 'Ubuntu',
        name: 'Ubuntu',
        value: 'ubuntu'
    },
    {
        id: '6',
        label: 'Roboto slab',
        name: 'roboto slab',
        value: 'roboto slab'
    },
    {
        id: '7',
        label: 'Lato',
        name: 'lato',
        value: 'lato'
    }
];
export const storeDeliveriesOptions = [
    { value: 'FULL', name: 'Customer pays the full delivery fee', label: 'Customer pays the full delivery fee' },
    { value: 'FREE', name: 'I pay the full delivery fee', label: 'I pay the full delivery fee' },
    {
        value: 'PERCENT',
        name: 'I pay a percentage of the delivery fee, and the customer pays the rest',
        label: 'I pay a percentage of the delivery fee, and the customer pays the rest'
    },
    {
        value: 'FIXED',
        name: 'I pay a fixed amount of the delivery fee, and the customer pays the rest',
        label: 'I pay a fixed amount of the delivery fee, and the customer pays the rest'
    },
    {
        value: 'SPECIFIC',
        name: 'Customer pays a specific amount, and i pays the rest',
        label: 'Customer pays a specific amount, and i pays the rest'
    }
];

export const dashboardCardData = [
    {
        label: 'Total orders',
        value: '300',
        icon: totalOderIcon,
        key: 'ALL'
    },
    {
        label: 'Completed orders',
        value: '300',
        icon: completedOderIcon,
        key: 'DELIVERED'
    },
    {
        label: 'Pending orders',
        value: '300',
        icon: pendingOderIcon,
        key: 'PENDING'
    },
    {
        label: 'Total money spent',
        value: '$300',
        icon: moneyIcon,
        key: 'COST'
    }
];
export const orderCancellationReasonsData = [
    {
        label: 'Wait time exceeded',
        name: 'Wait time exceeded',
        value: 'Wait time exceeded'
    },
    {
        label: 'Item has already been picked up',
        name: 'Item has already been picked up',
        value: 'Item has already been picked up'
    },
    {
        label: 'Pick-up location is inaccessible',
        name: 'Pick-up location is inaccessible',
        value: 'Pick-up location is inaccessible'
    },
    {
        label: 'Item is not ready for pick-up',
        name: 'Item is not ready for pick-up',
        value: 'Item is not ready for pick-up'
    },
    {
        label: 'Duplicate delivery',
        name: 'Duplicate delivery',
        value: 'Duplicate delivery'
    },
    {
        label: 'Other',
        name: 'Other',
        value: 'Other'
    }
];

export const bulkUploadCSVData = `Order Quantity,Size,Category,Description,Pickup Street Address,Pickup Unit,Pickup City,Pickup State,Pickup Zip,Pickup Instructions,Pickup Contact,Pickup Phone Number,Pickup Time,Delivery Address,Delivery Unit,Delivery City,Delivery State,Delivery Zip,Delivery Notes,Delivery Contact,Delivery Phone Number,Delivery Time,Require Signature,Tip Amount,Order Value
1,Small,Flower,Large flower,5209 viking way,202,Louisville,KY,40272,Call for further instructions,John Doe,502-594-9140,01/06/2023 12:00 PM,704 minette circle,204,Louisville,KY,40272,Call when arrived,Jane Doe,502-224-1762,01/06/2023 9:00 PM,Yes,5.00,20.00
`;

export const bulkUploadTableData = [
    'Order quantity',
    'Order value',
    'Pick up contact name',
    'Pickup Address',
    'Phone Number',
    'Pickup Instruction',
    'Pickup time & date',
    'Drop off contact name',
    'Drop off address',
    'Phone number',
    'Drop off instructions',
    'Drop off date and time',
    'Item information',
    'Tip amount',
    'Category',
    'Size'
];

export const clientOrderTableHeader = ['Goodwill centres', 'Tracking link', 'ETA', 'Item Value', 'Delivery fee', 'Ratings', 'Status'];
export const clientOrderTableData = [
    {
        center: 'Hell’s kitchen’s advocate',
        tracking_link: 'track.5757585gf....',
        eta: '23mins',
        item_value: '$56',
        delivery_fee: '$56',
        rating: 5,
        status: 'completed'
    },
    {
        center: 'Hell’s kitchen’s advocate',
        tracking_link: 'track.5757585gf....',
        eta: '23mins',
        item_value: '$56',
        delivery_fee: '$56',
        rating: 5,
        status: 'completed'
    },
    {
        center: 'Hell’s kitchen’s advocate',
        tracking_link: 'track.5757585gf....',
        eta: '23mins',
        item_value: '$56',
        delivery_fee: '$56',
        rating: 3,
        status: 'completed'
    },
    {
        center: 'Hell’s kitchen’s advocate',
        tracking_link: 'track.5757585gf....',
        eta: '23mins',
        item_value: '$56',
        delivery_fee: '$56',
        rating: 2,
        status: 'completed'
    },
    {
        center: 'Hell’s kitchen’s advocate',
        tracking_link: 'track.5757585gf....',
        eta: '23mins',
        item_value: '$56',
        delivery_fee: '$56',
        rating: 4,
        status: 'completed'
    }
];
export const smsTemplates = [
    {
        header: 'Discount & Promo',
        text: `{{brand_name}}: 🚨 Don't miss out on this amazing deal! 🚨 Get [Discount]% off EVERYTHING at [Store URL] 🛍️🎉 This is your chance to grab all your favorite products at unbeatable prices, but you have to hurry! ⏰ Ready, set, SHOP and save big! 💥`
    },
    {
        header: 'Announcement',
        text: `{{brand_name}}: 🚚 SAME DAY DELIVERY NOW AVAILABLE! 🚚 Order from [Store URL] and get your products delivered straight to your doorstep in no time! ⏰ Save time and enjoy the convenience of same-day delivery today! 💥`
    },
    {
        header: 'Menu updates',
        text: `{{brand_name}}: Your taste buds are in for a treat! 🍴 We've just updated our menu with new delicious options including [FOOD OPTION 1], [FOOD OPTION 2], and [FOOD OPTION 3]. 😋 Order now and enjoy our fresh, healthy meals. Check out the menu: [Store URL]. Don't miss out on this scrumptious opportunity! 🤤.`
    },
    {
        header: 'Availability update',
        text: `{{brand_name}}: Hey there! Just wanted to let you know that we'll be closed [insert dates here] for [insert reason here]. Don't worry though, we'll be back and better than ever! Visit [Store URL] for updates. 😊`
    }
];

export const daysOfTheMonth = [
    {
        label: '1st',
        value: 1
    },
    {
        label: '2nd',
        value: 2
    },
    {
        label: '3rd',
        value: 3
    },
    {
        label: '4th',
        value: 4
    },
    {
        label: '5th',
        value: 5
    },
    {
        label: '6th',
        value: 6
    },
    {
        label: '7th',
        value: 7
    },
    {
        label: '8th',
        value: 8
    },
    {
        label: '9th',
        value: 9
    },
    {
        label: '10th',
        value: 10
    },
    {
        label: '11th',
        value: 11
    },
    {
        label: '12th',
        value: 12
    },
    {
        label: '13th',
        value: 13
    },
    {
        label: '14th',
        value: 14
    },
    {
        label: '15th',
        value: 15
    },
    {
        label: '16th',
        value: 16
    },
    {
        label: '17th',
        value: 17
    },
    {
        label: '18th',
        value: 18
    },
    {
        label: '19th',
        value: 19
    },
    {
        label: '20th',
        value: 20
    },
    {
        label: '21st',
        value: 21
    },
    {
        label: '22nd',
        value: 22
    },
    {
        label: '23rd',
        value: 23
    },
    {
        label: '24th',
        value: 24
    },
    {
        label: '25th',
        value: 25
    },
    {
        label: '26th',
        value: 26
    },
    {
        label: '27th',
        value: 27
    },
    {
        label: '28th',
        value: 28
    },
    {
        label: '29th',
        value: 29
    },
    {
        label: '30th',
        value: 30
    },
    {
        label: '31st',
        value: 31
    }
];

export const monthsOfTheYear = [
    {
        label: 'January',
        value: 'JANUARY'
    },
    {
        label: 'February',
        value: 'FEBRUARY'
    },
    {
        label: 'March',
        value: 'MARCH'
    },
    {
        label: 'April',
        value: 'APRIL'
    },
    {
        label: 'May',
        value: 'MAY'
    },
    {
        label: 'June',
        value: 'JUNE'
    },
    {
        label: 'July',
        value: 'JULY'
    },
    {
        label: 'August',
        value: 'AUGUST'
    },
    {
        label: 'September',
        value: 'SEPTEMBER'
    },
    {
        label: 'October',
        value: 'OCTOBER'
    },
    {
        label: 'November',
        value: 'NOVEMBER'
    },
    {
        label: 'December',
        value: 'DECEMBER'
    }
];

export const weekDays = [
    {
        label: 'S',
        key: 'Sunday',
        value: 'SUN'
    },
    {
        label: 'M',
        key: 'Monday',
        value: 'MON'
    },
    {
        label: 'T',
        key: 'Tuesday',
        value: 'TUE'
    },
    {
        label: 'W',
        key: 'Wednesday',
        value: 'WED'
    },
    {
        label: 'T',
        key: 'Thursday',
        value: 'THU'
    },
    {
        label: 'F',
        key: 'Friday',
        value: 'FRI'
    },
    {
        label: 'S',
        key: 'Saturday',
        value: 'SAT'
    }
];

export const recurringTypes = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
    CUSTOM: 'custom'
};

export const appSideBarType = {
    FULL: 'FULL',
    SLIM: 'SLIM'
};

export const newBulkData = {
    estimateWith: ['DOORDASH'],
    stops: [
        {
            package_type: '',
            quantity: null,
            category: '',
            pickup_information: {
                address: {},
                note: '',
                owner: {
                    contactName: '',
                    phone_number: ''
                }
            },
            dropoff_information: {
                address: {},
                note: ' ',
                receiver: {
                    name: '',
                    phone_number: ''
                }
            }
        },
        {
            package_type: 'SMALL',
            quantity: 1,
            category: 'Meal kits',
            pickup_information: {
                address: {
                    address_name: '175 N State St, Chicago, IL 60601, USA',
                    state: 'IL',
                    address_1: '175 N State St',
                    city: 'Chicago',
                    country: 'US',
                    zip_code: '60601'
                },
                note: 'Please call me upon arrival',
                owner: {
                    contactName: 'Paula Dunn',
                    phone_number: '(800)4'
                }
            },
            dropoff_information: {
                address: {
                    address_name: '111 S Michigan Ave, Chicago, IL 60603, USA',
                    state: 'IL',
                    address_1: '111 S Michigan Ave',
                    city: 'Chicago',
                    country: 'US',
                    zip_code: '60603'
                },
                note: 'Please hand it to the customer. ',
                receiver: {
                    name: 'Andrew Smith',
                    phone_number: '(800) 464-4646'
                }
            }
        },
        {
            package_type: 'SMALL',
            quantity: 1,
            category: 'Meal_kits',
            pickup_information: {
                address: {
                    address_name: '175 N State St, Chicago, IL 60601, USA',
                    state: 'IL',
                    address_1: '175 N State St',
                    city: 'Chicago',
                    country: 'US',
                    zip_code: '60601'
                },
                note: 'Please call me upon arrival',
                owner: {
                    contactName: 'Paula Dunn',
                    phone_number: '(800) 444-4444'
                }
            },
            dropoff_information: {
                address: {
                    address_name: '4556 S Ashland Ave, Chicago, IL 60609, USA',
                    state: 'IL',
                    address_1: '4556 S Ashland Ave',
                    city: 'Chicago',
                    country: 'US',
                    zip_code: '60609'
                },
                note: 'Please hand it to the customer. ',
                receiver: {
                    name: 'Andrew Smith',
                    phone_number: '(502) '
                }
            }
        }
    ]
};
