/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable arrow-body-style */
import React, { useEffect, useReducer } from 'react';
import './storepagelayout.scss';
import { extractErrorMessage, isObjectEmpty, omit } from 'libs';
import { Outlet, useNavigate, useParams, Link } from 'react-router-dom';
import caretLeft from 'assets/icons/caretLeft.svg';
import Button from 'components/Button';
import { ChevronLeft } from 'tabler-icons-react';
import AnimateElement from 'ui-components/AnimateElement';

const StoreLayout = (props: any) => {
    const navigate = useNavigate();
    const params = useParams();
    const initialState = {
        pageLoading: true
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { activeIndex, pages } = state;

    return (
        <div className="store-layout pb-12">
            <div className="nav-area px-4 md:px-8  flex justify-between items-center mb-6">
                <div className="pointer" onClick={() => navigate('/settings/shop-ingeration')}>
                    <AnimateElement className="flex gap-x-4 items-center pointer back-btn">
                        <ChevronLeft size={28} strokeWidth={2} color="#6E7191" />
                        <span className="font-semi-bold">Shop settings</span>
                    </AnimateElement>
                </div>
                <div />
            </div>
            {/* <div className="w-[800px]  mx-auto mb-6">
                <Link to="/settings/shop-ingeration" className="flex items-center gap-x-2 py-4 font-semibold">
                    <ChevronLeft strokeWidth={1.2} color="#6B55B9" size={23} /> <span>&nbsp;Shop settings</span>
                </Link>
            </div> */}
            <Outlet />
        </div>
    );
};
export default StoreLayout;
