import { AbstractHttpService } from './Abstract-http-service';

// eslint-disable-next-line import/prefer-default-export
class HttpService extends AbstractHttpService<any> {
    protected url: string;

    constructor(url: string) {
        super();
        this.url = url;
    }
}

export default HttpService;
